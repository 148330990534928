import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, observe, makeObservable, runInAction } from 'mobx'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import { Language, ReleaseSettings, BookingSettings } from 'shared/models-web'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { timeout } from '../../utils/utils'
import Popup from '../../components/Popup'
import _ from 'lodash'

import Select from '../../components/ui-elements/Select'
import Multiselect from '../../components/ui-elements/MultiselectV2'
import Input from '../../components/ui-elements/Input'
import { TemplateManifest } from 'shared/template'
import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'

@observer
class Release extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void } }> {
    unblocker?: () => void

    @observable
    isLoaded = 0

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    releaseSettings: ReleaseSettings = {
        templateId: '',
        languageId: '',
        languages: [],
        sync_done: false,
    }
    @observable
    bookingSettings: BookingSettings = {
        domain: '',
        name: '',
        email_from: '',
        name_from: '',
    }
    @observable
    languages: Language[] = []
    @observable
    templates: TemplateManifest[] = []

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false
    disposer?: any

    //cloud
    @observable
    cloud = {
        git: 'aaaaaa',
        version: 'bbbbb',
    }
    @observable
    updatingCloud = false

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    attachObserver = () => {
        this.disposer && this.disposer()

        this.disposer = observe(
            this.releaseSettings,
            action((change: any) => {
                this.isFormDirty = true
            }) as any,
        )
    }

    @action
    init = async () => {
        // window.addEventListener('beforeunload', this.beforeunload)

        try {
            const cloud = (await this.props.api.cloud_api().version()).data
            const languages = (await this.props.api.project_api().languages()).data
            const templates = (await this.props.api.project_api().templates()).data

            const releaseSettings = (await this.props.api.settings_api().release_settings()).data
            const bookingSettings = (await this.props.api.settings_api().booking_settings()).data

            runInAction(() => {
                this.cloud = cloud
                this.languages = languages
                this.templates = templates
                this.releaseSettings = releaseSettings
                this.bookingSettings = bookingSettings

                this.isLoaded = 1
            })
            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
        this.attachObserver()

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)
        this.disposer && this.disposer()
    }

    @action
    save = async (e?: any, redirect = false) => {
        this.saving = true

        try {
            await this.props.api.settings_api().release_settings_save(this.releaseSettings)
            await this.props.api.settings_api().booking_settings_save(this.bookingSettings)

            runInAction(() => {
                this.isFormDirty = false
                if (redirect === true) {
                    this.showSaveChangesWait = false
                    this.showSaveChanges = false
                    this.isRedirect = true
                }
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.saving = false
                this.showSaveChangesWait = false
                this.showSaveChanges = false
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onCloseChanges = () => {
        this.showSaveChanges = false
    }

    @action
    onCloseSaveChanges = async () => {
        this.showSaveChangesWait = true

        await this.save(undefined, true)
    }

    @action
    discardChanges = async () => {
        if (this.showSaveChangesWait) {
            return
        }

        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        runInAction(() => {
            this.isRedirect = true
        })
    }

    @action
    updateCloud = async () => {
        try {
            this.updatingCloud = true

            await this.props.api.cloud_api().update()
            const cloud = (await this.props.api.cloud_api().version()).data

            runInAction(() => {
                this.cloud = cloud
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.updatingCloud = false
            })
        }
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Release & Booking Settings</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <div className="form">
                                    <h4 className="header-title">Templates</h4>
                                    <p className="sub-header"></p>

                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label>Version</label>
                                                <p className="m-0">
                                                    <i className="mdi mdi-git"></i> {this.cloud.git.substr(0, 16)}
                                                </p>
                                                <p className="m-0">
                                                    <i className="mdi mdi-file-document-outline"></i> {this.cloud.version}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md text-right">
                                            <div className="btn-group mb-3">
                                                <LaddaButton className="btn width-md waves-effect waves-light btn-secondary" loading={this.updatingCloud} onClick={() => this.updateCloud()} data-color="green" data-style={ZOOM_OUT}>
                                                    Update
                                                </LaddaButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <div className="form">
                                    <h4 className="header-title">Release from configuration</h4>
                                    <p className="sub-header"> Select a release UI template, primary language and secondary langauges</p>
                                    <div className="form-row">
                                        <div className="col-lg-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'templateId'}>Template</label>
                                                <Select id={'templateId'} className="custom-select" default={true} value={this.releaseSettings.templateId} onChange={action((e) => (this.releaseSettings.templateId = e))} data={{ options: this.templates, key: 'uuid', value: 'name' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor={'languageId'}>Booth language</label>
                                            <div className="input-group mb-3">
                                                <Select id={'languageId'} className="custom-select" default={true} value={this.releaseSettings.languageId} onChange={action((e) => (this.releaseSettings.languageId = e))} data={{ options: this.languages, key: 'uuid', value: 'name' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor={'languages'}>Other available languages</label>
                                            <div className="mb-3">
                                                <Multiselect id="languages" data={_.mapValues(_.keyBy(this.languages, 'uuid'), 'name')} value={this.releaseSettings.languages} onChange={action((e) => (this.releaseSettings.languages = e))} disable={[this.releaseSettings.languageId]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <div className="form">
                                    <h4 className="header-title">Booking</h4>
                                    <p className="sub-header"> Some configuration for calander and emailing</p>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor={'domain'}>Domain</label>
                                            <div className="input-group mb-3">
                                                <Input className="form-control" type="text" id={'domain'} value={this.bookingSettings.domain} onChange={action((e) => (this.bookingSettings.domain = e))} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor={'name'}>Name</label>
                                            <div className="mb-3">
                                                <Input className="form-control" type="text" id={'name'} value={this.bookingSettings.name} onChange={action((e) => (this.bookingSettings.name = e))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor={'email_from'}>Mail from</label>
                                            <div className="input-group mb-3">
                                                <Input className="form-control" type="text" id={'email_from'} value={this.bookingSettings.email_from} onChange={action((e) => (this.bookingSettings.email_from = e))} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor={'name_from'}>Name from</label>
                                            <div className="mb-3">
                                                <Input className="form-control" type="text" id={'name_from'} value={this.bookingSettings.name_from} onChange={action((e) => (this.bookingSettings.name_from = e))} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.save(undefined)} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" disabled={this.showSaveChangesWait} onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.showSaveChangesWait} onClick={() => this.onCloseSaveChanges()} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Release)
