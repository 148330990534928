import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import { observer } from 'mobx-react'
import { Notifications } from 'shared/models-web'
import { action, makeObservable, observable, runInAction } from 'mobx'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import moment from 'moment'
import { Link } from 'react-router-dom'

@observer
export default class Notification extends React.Component<{
    api: Api
    store: Store
}> {
    @observable
    notifications: Notifications[] = []
    @observable
    notifications_count = 0

    updating = false
    timer?: NodeJS.Timeout

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.reload()
    }

    componentDidMount = () => {
        this.props.store.on('notifications.event', this.reload)

        // this.timer = setInterval(this.reload, 250)
    }

    componentWillUnmount = () => {
        this.props.store.off('notifications.event', this.reload)

        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    @action
    reload = async () => {
        if (this.updating) {
            return
        }

        try {
            this.updating = true

            const data = (await this.props.api.notification_api().get('all', false)).data

            runInAction(() => {
                this.notifications = data.events
                this.notifications_count = data.all
            })
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
            }

            console.log('here', e)
        } finally {
            this.updating = false
        }
    }

    clearAll = async () => {
        try {
            for (const notification of this.notifications) {
                await this.props.api.notification_api().read((notification as any)._id)
            }
            await this.reload()
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
            }

            console.log('here', e)
        }
    }

    icon = (notification: Notifications) => {
        switch (notification.section) {
            case 'project':
                return 'mdi-file-document'
            case 'printer':
                return 'mdi-printer'
            case 'email':
                return 'mdi-email'
            case 'cloud':
                return 'mdi-cloud-outline'
            case 'camera':
                return 'mdi-camera'
            case 'backup':
                return 'mdi-upload'
            case 'export':
                return 'mdi-usb'
        }

        return 'mdi-printer'
    }

    css_class = (notification: Notifications) => {
        switch (notification.type) {
            case 'info':
                return 'bg-success'
            case 'error':
                return 'bg-danger'
            case 'warn':
                return 'bg-warning'
        }

        return 'bg-success'
    }

    mark_read = async (notification: any) => {
        try {
            await this.props.api.notification_api().read(notification._id)
            await this.reload()
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
            }

            console.log('here', e)
        }
    }

    render() {
        const notifications = []
        for (const notification of this.notifications) {
            notifications.push(
                <a className="dropdown-item notify-item" onClick={() => this.mark_read(notification)} key={(notification as any)._id}>
                    <div className={'notify-icon ' + this.css_class(notification)}>
                        <i className={'mdi ' + this.icon(notification)}></i>
                    </div>
                    <p className="notify-details">
                        {notification.title}
                        <small className="text-muted">{moment(notification.time, 'X').from(moment())}</small>
                    </p>
                </a>,
            )
        }

        return (
            <Dropdown className={'dropdown notification-list'} as="li">
                <Dropdown.Toggle variant="success" id="user-profile" className="nav-link dropdown-toggle waves-effect waves-light" as="a">
                    <i className="fe-bell noti-icon"></i>
                    {this.notifications_count > 0 && <span className="badge badge-danger rounded-circle noti-icon-badge">{this.notifications_count}</span>}
                </Dropdown.Toggle>

                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right dropdown-lg'}>
                    <div className="dropdown-item noti-title">
                        <h5 className="m-0">
                            <span className="float-right">
                                <a onClick={this.clearAll} className="text-light">
                                    <small>Clear All</small>
                                </a>
                            </span>
                            Notifications
                        </h5>
                    </div>

                    <div className="slimscroll noti-scroll">{notifications}</div>

                    <Link to="/notifications" className="dropdown-item text-center text-light notify-item notify-all">
                        View all
                        <i className="fi-arrow-right"></i>
                    </Link>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}
