import { observer } from 'mobx-react'
import React from 'react'
import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import Api from 'shared/adminApi'
import Store from '../../../lib/store'
import ImportMode from './ImportPortraits/ImportMode'
import ImportSelectFolder from './ImportPortraits/ImportSelectFolder'

export interface Entry {
    path: string
    name: string
    children: Entry[]
    type: 'file' | 'folder'
}

export type WizardPosition = 'MODE' | 'SELECT_FOLDER' | 'SELECT_FILE' | 'CONFIRM' | 'PROGRESS'
export type Mode = 'PICTURE_ONLY' | 'PICTURES_WITH_CSV' | 'UNKNOWN'

@observer
export default class ImportPortraits extends React.Component<{ api: Api; store: Store; onClose: () => void; projectId?: string }> {
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    folder: Entry[] = []
    @observable
    mode: Mode = 'PICTURE_ONLY'
    imnportContext: {
        tagline: boolean
        grayscale: boolean
        ignoreRotate: boolean
        rotate: boolean
    } = {
        grayscale: false,
        tagline: false,
        ignoreRotate: false,
        rotate: false,
    }

    @observable
    wizardPosition: WizardPosition = 'MODE'

    @observable
    progressInfo: {
        length: number
        done: number
        type: string
    } = {
        length: 10,
        done: 1,
        type: 'waiting',
    }

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()

        this.props.store.on('backup.import-progress', this.data_update)
    }

    componentWillUnmount = () => {
        this.props.store.off('backup.import-progress', this.data_update)
    }

    @action
    data_update = async (data: any) => {
        this.progressInfo = data
    }

    @action
    init = async () => {
        try {
            console.log('init -.-')
            this.add('')
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    convert = (data: any, appendTo?: string) => {
        if (appendTo) {
            const append = (data: any, folder: Entry[], appendTo: string) => {
                for (const entry of folder) {
                    if (entry.path === appendTo) {
                        const children = []
                        for (const entry of data) {
                            console.log('appending', entry.name)
                            children.push({
                                name: entry.name,
                                path: entry.path_lower,
                                type: entry['.tag'],
                                children: [],
                            })
                        }

                        entry.children = children
                    } else if (entry.children.length > 0) {
                        console.log('go in')
                        append(data, entry.children, appendTo)
                    }
                }
            }

            console.log('before append')

            append(data, this.folder, appendTo)

            console.log('are we here', toJS(this.folder))
        } else {
            for (const entry of data) {
                this.folder.push({
                    name: entry.name,
                    path: entry.path_lower,
                    type: entry['.tag'],
                    children: [],
                })
            }
        }
    }
    @action
    add = async (path: string) => {
        try {
            const data = (await this.props.api.backup_api().list_files(path)).data

            this.convert(data, path)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @observable
    selected_folder?: Entry

    @action
    select_folder = async (entry: Entry) => {
        this.selected_folder = entry
    }

    render_tree = (folder: Entry[]) => {
        console.log('render triggered')

        const parts = []
        for (const entry of folder) {
            parts.push(
                <li>
                    <div className="custom-control custom-radio" onClick={() => this.select_folder(entry)}>
                        <input type="radio" id="PICTURE_ONLY" name="mode" className="custom-control-input" value={entry.name} checked={this.selected_folder === entry} />
                        <label className="custom-control-label" id="PICTURE_ONLY">
                            {entry.type === 'file' && entry.name}
                            {entry.type === 'folder' && (
                                <a
                                    className="clickable"
                                    onClick={async (e) => {
                                        await this.add(entry.path)
                                    }}
                                >
                                    {entry.name}
                                </a>
                            )}
                        </label>
                    </div>
                    {this.render_tree(entry.children)}
                </li>,
            )
        }

        return <ul>{parts}</ul>
    }

    @action
    startImport = async () => {
        try {
            console.log(toJS(this.selected_folder), this.mode)

            if (!this.selected_folder) {
                throw new Error('Please provide selected folder to import!')
            }

            if (!this.props.projectId) {
                throw new Error('Please provide projectId!')
            }
            const data = (
                await this.props.api.backup_api().import_folder({
                    projectId: this.props.projectId,
                    path: this.selected_folder.path,
                    ...this.imnportContext,
                })
            ).data

            console.log(data)

            this.next('PROGRESS')

            //send to backend
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    next = async (position: WizardPosition) => {
        this.wizardPosition = position
    }

    render() {
        switch (this.wizardPosition) {
            case 'MODE':
                return (
                    <ImportMode
                        position={this.wizardPosition}
                        next={this.next}
                        onClose={this.props.onClose}
                        mode={this.mode}
                        selectMode={action((mode) => (this.mode = mode))}
                        setImportContext={action((context) => {
                            this.imnportContext = Object.assign({}, context)
                        })}
                    />
                )
            case 'SELECT_FOLDER':
                return <ImportSelectFolder position={this.wizardPosition} next={this.next} onClose={this.props.onClose} folder={this.folder} render_tree={this.render_tree} />
            case 'SELECT_FILE':
                return (
                    <>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.props.onClose} disabled={false} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={() => this.next('SELECT_FILE')} disabled={false} className="btn width-md waves-effect waves-light btn-info">
                                    Back
                                </button>
                                <button type="button" onClick={() => this.next('CONFIRM')} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                                    Next
                                </button>
                            </div>
                        </div>
                    </>
                )
            case 'CONFIRM':
                return (
                    <>
                        {this.selected_folder && (
                            <>
                                Selected folder: {this.selected_folder.name} in path: {this.selected_folder.path}
                            </>
                        )}

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.props.onClose} disabled={false} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={() => this.next('CONFIRM')} disabled={false} className="btn width-md waves-effect waves-light btn-info">
                                    Back
                                </button>
                                <button type="button" onClick={this.startImport} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                                    Import
                                </button>
                            </div>
                        </div>
                    </>
                )
            case 'PROGRESS':
                return (
                    <>
                        <div className={'progress mb-3 ' + (this.progressInfo.type === 'waiting' ? 'bg-soft-info' : '')} style={{ height: '7px' }}>
                            <div
                                className={'progress-bar ' + (this.progressInfo.type === 'waiting' ? 'progress-bar-striped progress-bar-animated ' : '') + 'bg-info'}
                                role="progressbar"
                                style={{
                                    width: this.progressInfo.type !== 'waiting' ? (this.progressInfo.done / this.progressInfo.length) * 100 + '%' : '100%',
                                }}
                            />
                        </div>

                        <p className="mb-1">You can close this windows import process will continue in background and notification will be generated when finished!</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.props.onClose} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                )
        }

        return <></>
    }
}
