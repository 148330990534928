import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Booth, OpenVPNClient } from 'shared/models-web'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { timeout } from '../../utils/utils'
import { Link, NavigateFunction, Params } from 'react-router-dom'

import moment from 'moment'
import Input from '../../components/ui-elements/Input'
import { withRouter } from '../../utils/react-utils'

@observer
class BoothView extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; beforeUnload: (func: () => void) => void } }> {
    @observable
    isLoaded = 0

    @observable
    info: { booth: Booth; connection?: OpenVPNClient; socketio: boolean } = {
        booth: {
            cert: '',
            hwId: '',
            id: '',
            key: '',
            location: '',
            name: '',
            psk: '',
            removed: false,
            uuid: '',
        },
        socketio: false,
        connection: {
            date: '',
            id: '',
            ip: '',
            remote: '',
        },
    }

    //erro model controls
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        try {
            if (!this.props.router.params.uuid) {
                throw new Error('Please privice uuid')
            }

            const info = (await this.props.api.booth_api().load(this.props.router.params.uuid)).data

            runInAction(() => {
                this.info = info

                //this fixes problems with clikcing links if we use loading
                this.isLoaded = 1
            })
            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    copy = (text: string) => {
        navigator.clipboard.writeText(text)
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">{this.info.booth.name}</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <Link to={`/booths/${this.info.booth.uuid}/edit`} className="btn btn-success btn-rounded mb-3 waves-effect waves-light">
                            <i className="mdi mdi-pencil"> </i>Edit Booth
                        </Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="clearfix">
                                <p className="d-none d-sm-block float-left text-muted text-uppercase mb-2">
                                    {this.info.connection && (
                                        <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                            <i className="mdi mdi-network-outline"></i> <small>{this.info.connection.ip}</small>
                                        </span>
                                    )}
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-crosshairs-gps"></i> <small>{this.info.booth.location}</small>
                                    </span>
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-calendar"></i> <small>{moment((this.info.booth as any).createdAt).format('MMMM DD, YYYY')}</small>
                                    </span>
                                </p>

                                <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                    <span>
                                        <div className={'badge bg-soft-info text-info'}>{this.info.booth.hwId}</div>&nbsp;
                                        {this.info.connection && (
                                            <>
                                                <div className={'badge bg-soft-success text-success'}>VPN</div>&nbsp;
                                            </>
                                        )}
                                        {!this.info.connection && (
                                            <>
                                                <div className={'badge bg-soft-warning text-warning'}>VPN</div>&nbsp;
                                            </>
                                        )}
                                        {this.info.socketio && (
                                            <>
                                                <div className={'badge bg-soft-success text-success'}>Socket.IO</div>&nbsp;
                                            </>
                                        )}
                                        {!this.info.socketio && (
                                            <>
                                                <div className={'badge bg-soft-warning text-warning'}>Socket.IO</div>&nbsp;
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="d-sm-none card-top-buttons-align mb-2">
                                <span>
                                    <div className={'badge bg-soft-info text-info'}>{this.info.booth.hwId}</div>&nbsp;
                                    {this.info.connection && (
                                        <>
                                            <div className={'badge bg-soft-success text-success'}>VPN</div>&nbsp;
                                        </>
                                    )}
                                    {!this.info.connection && (
                                        <>
                                            <div className={'badge bg-soft-warning text-warning'}>VPN</div>&nbsp;
                                        </>
                                    )}
                                    {this.info.socketio && (
                                        <>
                                            <div className={'badge bg-soft-success text-success'}>Socket.IO</div>&nbsp;
                                        </>
                                    )}
                                    {!this.info.socketio && (
                                        <>
                                            <div className={'badge bg-soft-warning text-warning'}>Socket.IO</div>&nbsp;
                                        </>
                                    )}
                                </span>
                            </div>

                            <p className="d-sm-none text-muted text-uppercase mb-2">
                                {this.info.connection && (
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-network-outline"></i> <small>{this.info.connection.ip}</small>
                                    </span>
                                )}
                                <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                    <i className="mdi mdi-crosshairs-gps"></i> <small>{this.info.booth.location}</small>
                                </span>
                                <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                    <i className="mdi mdi-calendar"></i> <small>{moment((this.info.booth as any).createdAt).format('MMMM DD, YYYY')}</small>
                                </span>
                            </p>
                            <p className="text-muted text-uppercase mb-2">
                                <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                    <i className="mdi mdi-view-grid"></i> <small>{this.info.booth.uuid}</small>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className={'card-box project-box widget-inline ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="form-row">
                                <div className="col-md-12">
                                    <label htmlFor={'psk'}>PSK</label>
                                    <div className="input-group mb-3">
                                        <Input type="text" id={'psk'} className="custom-select" disabled={true} value={this.info.booth.psk} />
                                        <div className="input-group-append">
                                            <button onClick={() => this.copy(this.info.booth.psk)} className="btn btn-secondary waves-effect waves-light ladda-button" type="button">
                                                <i className="mdi mdi-content-copy"></i> Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className={'card-box project-box widget-inline ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <h5>
                                <i className="mdi mdi-key"></i> Key
                            </h5>

                            <pre className="code">{this.info.booth.key}</pre>

                            <button onClick={() => this.copy(this.info.booth.key)} className="btn btn-secondary waves-effect waves-light ladda-button" type="button">
                                <i className="mdi mdi-content-copy"></i> Copy
                            </button>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className={'card-box project-box widget-inline ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <h5>
                                <i className="mdi mdi-certificate"></i> Cert
                            </h5>

                            <pre className="code">{this.info.booth.cert}</pre>

                            <button onClick={() => this.copy(this.info.booth.cert)} className="btn btn-secondary waves-effect waves-light ladda-button" type="button">
                                <i className="mdi mdi-content-copy"></i> Copy
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(BoothView)
