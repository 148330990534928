import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'

@observer
export default class UserProfile extends React.Component<{
    onSave: (old_password: string, new_password: string) => Promise<true | string>
    onClose: () => void
}> {
    @observable
    password_old = ''
    @observable
    password = ''
    @observable
    repeat_password = ''

    @observable
    error = false
    @observable
    errorMsg = ''

    @observable
    show = false

    constructor(props: any) {
        super(props);

        makeObservable(this)
    }

    @action
    cancel = async () => {
        this.show = false
        this.props.onClose()
    }

    @action
    save = async () => {
        if (this.repeat_password !== '' && this.password !== '') {
            if (this.repeat_password !== this.password) {
                return this.present_error('Passwords should match')
            }
        }

        const result = await this.props.onSave(this.password_old, this.password)

        if (result !== true) {
            return this.present_error(result)
        }
    }

    @action
    present_error = (error: string) => {
        this.errorMsg = error
        this.error = true

        setTimeout(() => {
            this.error = false
        }, 3000)
    }

    render() {
        return (
            <div className="custom-modal-text">
                <div>
                    <div className="form-row">
                        <div className="col-md">
                            {this.error && (
                                <div className="alert alert-danger" role="alert">
                                    <i className="mdi mdi-block-helper mr-2"></i> {this.errorMsg}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md">
                            <div className="form-group mb-3">
                                <label htmlFor="user-password">Existing password</label>
                                <input type="password" id="user-password" value={this.password_old} onChange={(e) => action((this.password_old = e.target.value))} className="form-control" autoComplete="old-password" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <div className="form-group mb-3">
                                <label htmlFor="user-password">New password</label>
                                <input type="password" id="user-password" value={this.password} onChange={(e) => action((this.password = e.target.value))} className="form-control" autoComplete="new-password" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md">
                            <div className="form-group mb-3">
                                <label htmlFor="user-repeat-password">Repeat new password</label>
                                <input type="password" id="user-repeat-password" value={this.repeat_password} onChange={(e) => action((this.repeat_password = e.target.value))} className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-right mt-3">
                    <div className="btn-group">
                        <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                            Cancel
                        </button>
                        <button type="button" onClick={this.save} className="btn width-md waves-effect waves-light btn-primary">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
