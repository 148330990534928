import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import { observer } from 'mobx-react'
import { BookedSessions, ProjectBoogkingSlot, User, UserMeta } from 'shared/models-web'
import Api from 'shared/adminApi'
import Store from '../../../lib/store'
import moment from 'moment'

@observer
export default class ProjectBookingUserEntry extends React.Component<{
    openUser: (userId: string) => void
    user: User
    userMeta: UserMeta
    booked: BookedSessions
    slot: ProjectBoogkingSlot
    removeBooking: (userId: string) => void
    viewReleaseForm: (user: User) => void
    resendEmail: (bookedSessionId: string) => void
    api: Api
    store: Store
}> {
    render() {
        return (
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="gal-box">
                    <div className="gall-info">
                        <h6 className="mt-0 text-truncate" style={{ fontSize: '0.875rem' }}>
                            {this.props.user.name}
                        </h6>
                        <div className="mb-1">
                            <h4 className="p-0 m-0">{moment(`${this.props.slot.date} ${this.props.booked.hour}:00`, 'YYYY-MM-DD HH:mm').format('YYYY, MMM DD h:mm A')}</h4>
                            <p>{this.props.booked.uuid}</p>
                        </div>

                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <h4 className="p-0 m-0"></h4>

                            <Dropdown className={'d-flex align-items-center'}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop">
                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                    <Dropdown.Item onSelect={(e: any) => this.props.removeBooking(this.props.booked.uuid)}>Remove</Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onSelect={(e: any) => this.props.openUser(this.props.user.uuid)}>View user info</Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onSelect={(e: any) => this.props.viewReleaseForm(this.props.user)}>View release form</Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onSelect={(e: any) => this.props.resendEmail(this.props.booked.uuid)}>Resend booking email</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
