import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Input from '../../../components/ui-elements/Input'
import Popup from '../../../components/Popup'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectRedirects extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    @observable
    showHelpModal = false

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    onCloseHelpModal = () => {
        this.showHelpModal = false
    }

    render() {
        return (
            <>
                <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                    <div className="card-body pb-0">
                        <div className="float-right card-top-buttons-align ml-2" onClick={action(() => (this.showHelpModal = true))}>
                            <span>
                                <i className="mdi mdi-help-circle-outline m-0 text-muted h3"></i>
                            </span>
                        </div>
                        <div>
                            <h4 className="header-title">Redirects</h4>
                            <p className="sub-header"> Enter redirects after release from process </p>
                            <div className="form-row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'project-name'}>Booking redirect</label>
                                        <Input
                                            id={'project-name'}
                                            type="text"
                                            value={this.props.project.redirects ? this.props.project.redirects.booking_url : ''}
                                            onChange={action((value) => {
                                                if (!this.props.project.redirects) {
                                                    this.props.project.redirects = {}
                                                }
                                                this.props.project.redirects.booking_url = value
                                            })}
                                            className="form-control"
                                            placeholder="https://.."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'project-name'}>Release from redirect</label>
                                        <Input
                                            id={'project-name'}
                                            type="text"
                                            value={this.props.project.redirects ? this.props.project.redirects.release_url : ''}
                                            onChange={action((value) => {
                                                if (!this.props.project.redirects) {
                                                    this.props.project.redirects = {}
                                                }
                                                this.props.project.redirects.release_url = value
                                            })}
                                            className="form-control"
                                            placeholder="https://.."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseHelpModal} type="" close={true} title={'Redirect url format tokens'} show={this.showHelpModal}>
                    <div className="custom-modal-text">
                        <p>
                            Redirects should be in format <b>`{'https://insideoutproject.net/redirect/{{=it.token}}'}`</b>
                        </p>
                        <p>Posible tokens to use are:</p>
                        <ul>
                            <li>{'{{=it.token}}'} - Outside token</li>
                            <li>{'{{=it.project.uuid}}'} - Project id</li>
                        </ul>
                        <p>If you need more access/tokens check Template help</p>
                    </div>
                </Popup>
            </>
        )
    }
}
