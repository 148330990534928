import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler'

@observer
export default class Login extends React.Component<{ api: Api; store: Store }> {
    @observable
    username = ''
    @observable
    password = ''
    @observable
    error = false
    @observable
    errorMsg = ''

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    login = async () => {
        try {
            await this.props.store.login(this.username, this.password)
        } catch (eRaw) {
            const e = eRaw as any
            
            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    window.location.reload()
                } else if (e.response && e.response.data) {
                    console.log('here', e.response.data)
                    this.error = true
                    this.errorMsg = e.response.data.errMsg
                } else {
                    this.error = true
                    this.errorMsg = `We got unspecified error: ${e}`
                }
            })

            setTimeout(
                action(async () => {
                    this.error = false
                }),
                3000,
            )
        }
    }

    render() {
        return (
            <>
                <KeyboardEventHandler
                    handleKeys={['return']}
                    onKeyEvent={(key: string, e: any) => {
                        if (key === 'return') {
                            this.login()
                        }
                    }}
                />
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="text-center w-75 m-auto">
                                    <p className="text-muted mb-4 mt-3">Enter your email address and password to access admin panel.</p>
                                </div>

                                {this.error && (
                                    <div className="alert alert-danger" role="alert">
                                        <i className="mdi mdi-block-helper mr-2"></i> {this.errorMsg}
                                    </div>
                                )}
                                <div className="form">
                                    <div className="form-group mb-3">
                                        <label htmlFor="username">Username</label>
                                        <input className="form-control" type="text" id="username" placeholder="Enter your email" value={this.username} onChange={action((e) => (this.username = e.target.value))} />
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="password">Password</label>
                                        <input className="form-control" type="password" id="password" placeholder="Enter your password" value={this.password} onChange={action((e) => (this.password = e.target.value))} />
                                    </div>

                                    <div className="form-group mb-3">
                                        <div className="custom-control custom-checkbox">&nbsp;</div>
                                    </div>

                                    <div className="form-group mb-0 text-center">
                                        <button className="btn btn-primary btn-block" onClick={this.login}>
                                            Log In
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-center">
                        <p>
                            <a href="pages-recoverpw.html" className="text-white-50 ml-1">
                                Forgot your password?
                            </a>
                        </p>
                    </div>
                </div>
            </>
        )
    }
}
