import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { Link } from 'react-router-dom'
import { observable, action, makeObservable, runInAction } from 'mobx'

import Popup from '../../components/Popup'
import { Dropdown } from 'react-bootstrap'
import Input from '../../components/ui-elements/Input'
import { withRouter } from '../../utils/react-utils'

@observer
class Templates extends React.Component<{ api: Api; store: Store; history: History }> {
    @observable
    isLoaded = false
    @observable
    templates: any[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    showCopyModal = false
    @observable
    copyModalTemp: any

    @observable
    template_name = ''

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.loadTemplates()
    }

    @action
    loadTemplates = async () => {
        try {
            const templates = (await this.props.api.editor_api().templates()).data

            runInAction(() => {
                this.templates = templates
                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    copyTemplate = async () => {
        try {
            await this.props.api.editor_api().copy_template(this.copyModalTemp, this.template_name)
            await this.loadTemplates()

            runInAction(() => {
                this.showCopyModal = false
                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    openCopyModal = (uuid: string) => {
        this.showCopyModal = true
        this.copyModalTemp = uuid
    }

    @action
    onCloseCopyModal = () => {
        this.showCopyModal = false
    }

    @action
    git_pull = async () => {
        try {
            await this.props.api.git_api().update()
            await this.loadTemplates()

            runInAction(() => {
                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        const templatesList = []

        for (const t of this.templates) {
            templatesList.push(
                <div className="col-12" key={t.uuid}>
                    <div className={'card-box project-box widget-inline'}>
                        <Dropdown className={' float-right card-top-buttons-align ml-2'}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop arrow-none">
                                <i className="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                <Link to={'/cloud/templates/' + t.uuid} className="dropdown-item">
                                    View
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item onClick={(e: any) => this.openCopyModal(t.uuid)}>Copy template</Dropdown.Item>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href={`/preview?templateId=${t.uuid}`} target="_newtab">
                                    Preview
                                </a>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="clearfix">
                            <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                <span>
                                    <div className={'badge bg-soft-info text-info'}>{t.type}</div>&nbsp;
                                </span>
                            </div>

                            <h4 className="mt-0 mb-1">
                                <Link to={'/cloud/templates/' + t.uuid} className="text-light">
                                    {t.title}
                                </Link>
                            </h4>
                        </div>

                        <div className="d-sm-none card-top-buttons-align">
                            <span>
                                <div className={'badge bg-soft-info text-info'}>{t.type}</div>&nbsp;
                            </span>
                        </div>

                        <p className="text-muted mb-2">
                            <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                <i className="mdi mdi-code-string"></i> <small>{t.uuid}</small>
                            </span>
                        </p>
                    </div>
                </div>,
            )
        }

        return (
            <div className={'projects'}>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Templates</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <a onClick={() => this.git_pull()} className="btn btn-primary btn-rounded mb-3 waves-effect waves-light">
                            <i className="mdi mdi-git"> </i>Git pull
                        </a>
                    </div>
                </div>

                {this.templates.length > 0 && <div className="row">{templatesList}</div>}

                {this.isLoaded && this.templates.length === 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card-box project-box widget-inline">
                                <h4 className="mt-0">No projects :(</h4>
                                <div className="row mb-0">
                                    <div className="col-sm-12">
                                        <p className="mb-0">
                                            Looks like there is no templates avalible
                                            <Link to={'/settings/cloud'} className="text-info">
                                                {' '}
                                                update system to get them
                                            </Link>
                                            !
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Popup onClose={this.onCloseCopyModal} title={'File info'} show={this.showCopyModal}>
                    <div className="custom-modal-text">
                        <div className="form">
                            <div className="form-row">
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'template-name'}>Template name</label>
                                        <Input id={'template-name'} type="text" value={this.template_name} onChange={action((value) => (this.template_name = value))} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseCopyModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <button type="button" onClick={this.copyTemplate} className="btn width-md waves-effect waves-light btn-success">
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(Templates)
