import { Project, ProjectStats } from "shared/models-web";


export function parseQueryString(str: string) {
    var ret: { [k: string]: string[] | string } = Object.create(null);

    if (typeof str !== 'string') {
        return ret;
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
        return ret;
    }

    str.split('&').forEach(function (param) {
        var parts = param.replace(/\+/g, ' ').split('=');
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        var key = parts.shift();
        var val = parts.length > 0 ? parts.join('=') : undefined;

        if (!key || !val) {
            return;
        }

        key = decodeURIComponent(key);

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = decodeURIComponent(val);

        var retVal = ret[key];
        if (ret[key] === undefined) {
            ret[key] = val;
        } else if (Array.isArray(retVal)) {
            retVal.push(val);
        } else {
            ret[key] = [ret[key] as string, val];
        }
    });

    return ret;
}

export function mockProject(): { project: Project; stats: ProjectStats } {

    return {
        project: {
            id: 0,
            uuid: '',
            startDate: '',
            endDate: '',
            name: '',
            slug: '',
            location: {
                lat: '',
                lon: '',
                timeZone: '',
                country: '',
                country_id: '',
                state: '',
                state_id: '',
                city: '',
                city_id: '',
                isEu: false,
                gpsLock: false
            },
            tagline: {
                hashtag: '',
                url: '',
                name: '',
            },
            boothConfig: {
                timertime: 3,
                email: true,
                releaseForm: true,
                backup: false,
                website: true,
                print: true,
                print_ask: false,
                languageId: '',
                templateId: '',
                languages: [],
            },
            syncing_backup: false,
            syncing_website: false,
            about: {
                active: false,
                id: "",
                images: [],
                options: {},
                text: [],
                title: "",
                type: "",
                updatedBy: "",
                version: 0
            },
            projectIds: [],
            type: "single",
            deleted: false,
            end: 0,
            start: 0,
            slots: [],
            status: "hidden"
        }, stats: {
            emails: 0,
            images: 0,
            prints: 0,
            projects: 0,
            sync_backup: 0,
            sync_website: 0,
            space: 0
        }
    }
}

export function indexFromSearch(object: any, field: string, find: string) {
    for(const i in object) {
        if(object[i][field] === find) {
            return i;
        }
    }
}