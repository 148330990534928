import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'

@observer
export default class SelectBox extends React.Component<{
    value: any
    data: string[]
    onChange: any
    disbaled?: boolean
}> {
    @observable
    index: number | undefined = undefined

    constructor(props: any) {
        super(props);

        makeObservable(this)
    }

    componentDidUpdate = () => {
        if (this.index === undefined && this.props.value) {
            this.index = this.props.data.indexOf(this.props.value)

            if (this.index === -1) {
                this.index = 0
            }
        } else if (this.index === undefined && this.props.value === undefined) {
            this.index = 0

            this.props.onChange(this.props.data[this.index])
        }
    }

    @action
    selectNextOption = () => {
        if (this.index === undefined || this.props.disbaled) {
            return
        }

        if (this.index + 1 >= this.props.data.length) {
            return
        }

        this.index++

        this.props.onChange(this.props.data[this.index])
    }
    @action
    selectPreviousOption = () => {
        if (this.index === undefined || this.props.disbaled) {
            return
        }

        if (this.index - 1 < 0) {
            return
        }

        this.index--

        this.props.onChange(this.props.data[this.index])
    }

    render() {
        return (
            <div className="input-group mb-3">
                <input type="text" value={this.props.value} className="form-control" disabled={true} />
                <div className="input-group-append">
                    <button type="button" onClick={this.selectPreviousOption} className="btn width-xxs waves-effect waves-light btn-secondary no-af" disabled={this.props.disbaled}>
                        -
                    </button>
                    <button type="button" onClick={this.selectNextOption} className="btn width-xxs waves-effect waves-light btn-secondary no-af" disabled={this.props.disbaled}>
                        +
                    </button>
                </div>
            </div>
        )
    }
}
