import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { Link, Navigate, NavigateFunction, Params } from 'react-router-dom'
import { observable, action, toJS, makeObservable, runInAction } from 'mobx'
import Popup from '../../components/Popup'
import { TemplateFile } from 'shared/template'
import _ from 'lodash'
import Input from '../../components/ui-elements/Input'
import HoverPreview from '../../components/ui-elements/HoverPreview'
import FileDrop from '../../components/ui-elements/FileDrop'
import { timeout } from '../../utils/utils'
import { withRouter } from '../../utils/react-utils'

@observer
class TemplateView extends React.Component<{
    api: Api
    store: Store
    history: History
    router: { location: Location; navigate: NavigateFunction; params: Params<string> }
}> {
    @observable
    isLoaded = false

    @observable
    template: any = {
        name: '',
        uuid: '',
        options: {} as any,
        title: '',
        type: '',
    }
    @observable
    templateFiles: TemplateFile[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    deleteConfirmModalTemp: any
    @observable
    showDeleteConfirmModal = false

    @observable
    showUploadModal = false
    @observable
    uploadModalTemp: any
    @observable
    files: File[] = []

    @observable
    apps: JSX.Element[] = []
    @observable
    email: JSX.Element[] = []
    @observable
    pdf: JSX.Element[] = []

    @observable
    isRedirect = false
    @observable
    redirect = '/'

    @observable
    hasOverrideLanguages = false

    constructor(prop: any) {
        super(prop)

        makeObservable(this)

        this.loadTemplate()
    }

    tree = async (input: any[]): Promise<JSX.Element[]> => {
        const result = []

        if (!this.props.router.params.templateId) {
            throw new Error('Please privice templateId')
        }

        for (const entry of input) {
            let dir = null
            if (entry.isDirectory) {
                dir = await this.tree(entry.children)
            }

            let html

            if (dir) {
                html = (
                    <>
                        <span>
                            {entry.name}&nbsp;
                            <a
                                className="pointer"
                                onClick={() => {
                                    this.uploadModalTemp = entry.file
                                    this.showUploadModal = true
                                }}
                            >
                                <i className="fa fa-plus"></i>
                            </a>
                        </span>
                        <ul>{dir}</ul>
                    </>
                )
            } else if (entry.canEdit) {
                html = (
                    <span>
                        <Link to={`/cloud/templates/${this.props.router.params.templateId}/edit/${Buffer.from(entry.file).toString('base64')}`}>{entry.name}</Link>
                        &nbsp;
                        <a className="pointer" onClick={() => this.removeFile(entry.file)}>
                            <i className="fa fa-times"></i>
                        </a>
                    </span>
                )
            } else {
                html = (
                    <HoverPreview previewEnabled={entry.canPreview} previewLink={entry.previewLink as string} imgWidth="50px">
                        {entry.name}&nbsp;
                        <a className="pointer" onClick={() => this.removeFile(entry.file)}>
                            <i className="fa fa-times"></i>
                        </a>
                    </HoverPreview>
                )
            }

            result.push(<li key={entry.file}>{html}</li>)
        }

        return result
    }

    @action
    loadTemplate = async () => {
        try {
            if (!this.props.router.params.templateId) {
                throw new Error('Please privice templateId')
            }

            const template = (await this.props.api.editor_api().template(this.props.router.params.templateId)).data
            const templateFiles = (await this.props.api.editor_api().template_files(this.props.router.params.templateId)).data
            const pdf = await this.tree(_.find(templateFiles as any, { name: 'pdf' }).children)
            const apps = await this.tree(_.find(templateFiles as any, { name: 'apps' }).children)

            const email = _.find(templateFiles as any, { name: 'email' })
            const emailTree = await this.tree([
                {
                    file: email.file,
                    name: email.name,
                    isDirectory: true,
                    children: email.children,
                },
            ])

            runInAction(() => {
                this.pdf = pdf
                this.apps = apps
                this.template = template
                this.templateFiles = templateFiles
                this.email = emailTree

                console.log(toJS(this.templateFiles))

                const languages = _.find(templateFiles as any, { name: 'languages' })

                if (languages) {
                    this.hasOverrideLanguages = true
                } else {
                    this.hasOverrideLanguages = false
                }

                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    removeFile = async (file: string) => {
        this.showDeleteConfirmModal = true
        this.deleteConfirmModalTemp = file
    }

    @action
    deleteFile = async (file: string) => {
        try {
            if (!this.props.router.params.templateId) {
                throw new Error('Please privice templateId')
            }

            await this.props.api.editor_api().template_delete_file(this.props.router.params.templateId, file)

            await this.loadTemplate()

            runInAction(() => {
                this.showDeleteConfirmModal = false
                this.deleteConfirmModalTemp = undefined
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    uploadFile = async () => {
        try {
            if (!this.props.router.params.templateId) {
                throw new Error('Please privice templateId')
            }

            await this.props.api.editor_api().template_add_file(this.props.router.params.templateId, this.uploadModalTemp, this.files)

            await this.loadTemplate()

            runInAction(() => {
                this.showUploadModal = false
                this.uploadModalTemp = undefined
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseDeleteModal = () => {
        this.showDeleteConfirmModal = false
        this.deleteConfirmModalTemp = undefined
    }

    @action
    saveOptions = async () => {
        try {
            if (!this.props.router.params.templateId) {
                throw new Error('Please privice templateId')
            }

            await this.props.api.editor_api().save_options(this.props.router.params.templateId, this.template.options)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onCloseUploadModal = () => {
        this.showUploadModal = false
        this.uploadModalTemp = undefined
    }

    @action
    newFile = async () => {
        if (!this.props.router.params.templateId) {
            throw new Error('Please privice templateId')
        }

        this.showUploadModal = false

        await timeout(200)
        runInAction(() => {
            this.redirect = `/cloud/templates/${this.props.router.params.templateId}/add/${this.uploadModalTemp && Buffer.from(this.uploadModalTemp).toString('base64')}`
            this.isRedirect = true
        })
    }
    componentWillUnmount = () => {
        runInAction(() => {
            this.showUploadModal = false
        })
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }
        //START template options
        const options = []

        for (const a in this.template.options) {
            const opts = []
            for (const b in (this.template.options as any)[a]) {
                opts.push(
                    <div className="form-row" key={b}>
                        <div className="col-lg-12">
                            <div className="form-group mb-3">
                                <label htmlFor={b}>{_.startCase(b)}</label>
                                <Input id={b} type={typeof (this.template.options as any)[a][b] === 'number' ? 'number' : 'text'} value={(this.template.options as any)[a][b]} onChange={action((value) => ((this.template.options as any)[a][b] = value))} className="form-control" />
                            </div>
                        </div>
                    </div>,
                )
            }

            options.push(
                <div key={a}>
                    <h4 className="header-title">{_.startCase(a)}</h4>
                    {opts}
                </div>,
            )
        }
        //END template options

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Template: "{this.template.title}"</h4>
                            <p>{this.template.uuid}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <a href={`/preview/?templateId=${this.template.uuid}`} className="btn btn-info btn-rounded mb-3 waves-effect waves-light" target="_newtab">
                            <i className="mdi mdi-cube-scan"> </i>Preview
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h5>Apps</h5>
                            <ul className="file-explorer">{this.apps}</ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h5>Email</h5>
                            <ul className="file-explorer">{this.email}</ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h5>PDF</h5>
                            <ul className="file-explorer">{this.pdf}</ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h5>Translations</h5>

                            <Link to={`/cloud/templates/${this.props.router.params.templateId}/language`} className="btn width-md waves-effect waves-light btn-info ladda-button">
                                {this.hasOverrideLanguages ? 'Edit language overrides' : 'Add language overrides'}
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            {options}
                            <div className="text-right">
                                <div className="btn-group">
                                    <button onClick={this.saveOptions} className="btn width-md waves-effect waves-light btn-secondary ladda-button">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseDeleteModal} type="bg-danger" title={'Delete?'} show={this.showDeleteConfirmModal}>
                    <div className="custom-modal-text">
                        <p>Your are about to delete {this.deleteConfirmModalTemp}</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    No
                                </button>
                                <button type="button" onClick={() => this.deleteFile(this.deleteConfirmModalTemp)} className="btn width-md waves-effect waves-light btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseUploadModal} title={'Upload'} show={this.showUploadModal}>
                    <div className="custom-modal-text">
                        <FileDrop disable={false} featured={true} files={this.files} maxFiles={1} onFileAdded={() => {}} showFiles={false}></FileDrop>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseUploadModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={() => this.uploadFile()} className="btn width-md waves-effect waves-light btn-primary">
                                    Upload
                                </button>
                                <button type="button" onClick={() => this.newFile()} className="btn width-md waves-effect waves-light btn-info">
                                    New file
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(TemplateView)
