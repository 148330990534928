import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Link, NavigateFunction, Params } from 'react-router-dom'
import { Project, ProjectStats } from 'shared/models-web'
import ProjectListEntry from '../components/ProjectListEntry'
import ErrorBoundary from '../lib/ErrorBoundary'
import { mockProject } from '../lib/utils'
import LifetimeWidget from './dashboard/lifetime'
import { withRouter } from '../utils/react-utils'
import Popup from '../components/Popup'

@observer
class Dashboard extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    //data control
    @observable
    isLoaded = {
        current_project: false,
    }

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    //project stuff
    @observable
    project?: { project: Project; stats: ProjectStats }
    @observable
    project_stats?: ProjectStats

    @observable
    progressInfo: {
        length: number
        done: number
        type: string
    } = {
        length: 10,
        done: 1,
        type: 'waiting',
    }

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()

        this.props.store.on('backup.import-progress', this.data_update)
    }

    componentWillUnmount = () => {
        this.props.store.off('backup.import-progress', this.data_update)
    }

    @action
    data_update = async (data: any) => {
        this.progressInfo = data
    }

    @action
    init = async () => {
        try {
            const project_stats = (await this.props.api.project_api().stats()).data

            runInAction(() => {
                this.project_stats = project_stats

                this.isLoaded.current_project = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="dashboard">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Dashboard</h4>
                            </div>
                        </div>
                    </div>

                    {this.progressInfo.type !== 'waiting' && (
                        <div className="row">
                            <div className="col-12">
                                <div className={'card-box'}>
                                    <h4 className="mt-0 mb-1">Import job status</h4>

                                    <div className={'progress mb-3 ' + (this.progressInfo.type === 'waiting' ? 'bg-soft-info' : '')} style={{ height: '7px' }}>
                                        <div
                                            className={'progress-bar ' + (this.progressInfo.type === 'waiting' ? 'progress-bar-striped progress-bar-animated ' : '') + 'bg-info'}
                                            role="progressbar"
                                            style={{
                                                width: this.progressInfo.type !== 'waiting' ? (this.progressInfo.done / this.progressInfo.length) * 100 + '%' : '100%',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <LifetimeWidget
                        api={this.props.api}
                        store={this.props.store}
                        showError={(errMsg) => {
                            this.showErrorModal = true
                            this.errorModalText = errMsg
                        }}
                        project_stats={this.project_stats}
                    />
                </div>
                <Popup onClose={this.onCloseErrorModal} className={'col-md-8 col-xl-6 p-0'} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </ErrorBoundary>
        )
    }
}

export default withRouter(Dashboard)
