import React from 'react'
import {observer} from 'mobx-react'
import {action} from 'mobx'

@observer
export default class NumberInputComponent extends React.Component<{
    title: string
    value: any
    onChange: any
    displayButtons?: boolean
    min?: number
    max?: number
    disbaled?: boolean
    className?: string
}> {

    @action
    selectNextOption = () => {
        if (this.props.disbaled) {
            return;
        }

        let value = this.props.value

        value++;

        if (this.props.max && value > this.props.max) {
            value = this.props.max
        }

        this.props.onChange(value)
    }
    @action
    selectPreviousOption = () => {
        if (this.props.disbaled) {
            return;
        }

        let value = this.props.value

        value--;

        if (this.props.min && value < this.props.min) {
            value = this.props.min
        }

        this.props.onChange(value)
    }

    render() {
        return (
            <div className={"form-group " + this.props.className}>
                <label>{this.props.title}</label>
                <div className="input-group mb-3">
                    <input
                        type="text"
                        value={this.props.value}
                        onChange={(e) => (
                            this.props.onChange(e.target.value.replace(/\D/g, '')))}
                        className="form-control"
                        disabled={this.props.disbaled}
                    />
                    {this.props.displayButtons && (
                        <div className="input-group-append">
                            <button
                                type="button"
                                onClick={this.selectPreviousOption}
                                className="btn width-xxs waves-effect waves-light btn-secondary no-af"
                                disabled={this.props.disbaled}
                            >
                                -
                            </button>
                            <button
                                type="button"
                                onClick={this.selectNextOption}
                                className="btn width-xxs waves-effect waves-light btn-secondary no-af"
                                disabled={this.props.disbaled}
                            >
                                +
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
