import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import { observable, action, observe, makeObservable, runInAction } from 'mobx'

import Popup from '../../components/Popup'
import { timeout } from '../../utils/utils'
import { Page } from 'shared/models-web'
//@ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react'
//@ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import FileDrop from '../../components/ui-elements/FileDrop'
import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'

@observer
class AboutPage extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void } }> {
    @observable
    isLoaded = false
    @observable
    isSaving = false
    @observable
    redirect = false
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false

    @observable
    page: Page = {
        active: true,
        id: '',
        text: [''],
        title: '',
        type: 'aboutpage',
        version: 0,
        options: {},
        updatedBy: '',
        images: [],
    }
    @observable
    files: File[] = []
    @observable
    images: { id: string; url: string; filename: string }[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    disposer?: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    attachObserver = () => {
        this.disposer && this.disposer()

        this.disposer = observe(this.page, (change: any) => {
            this.isFormDirty = true
        })
    }

    @action
    init = async () => {
        console.log('Started loading project')

        // window.addEventListener('beforeunload', this.beforeunload)
        try {
            const result = (await this.props.api.pages_api().load('aboutpage')).data

            runInAction(() => {
                const page = result.page
                this.images = result.images

                if (page.text.length === 0) {
                    page.text.push('')
                }

                if (!page.options) {
                    page.options = {}
                }

                this.page = page

                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }

        this.attachObserver()

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)

        this.disposer && this.disposer()
    }

    @action
    discardChanges = async () => {
        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        runInAction(() => {
            this.redirect = true
        })
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    save = async () => {
        try {
            await this.props.api.pages_api().update(this.page.id, this.page, this.files, {
                featuredImage: true,
            })

            runInAction(() => {
                this.isFormDirty = false
                this.redirect = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    removeExistingFile = async (file: { id: string; url: string; filename: string }) => {
        try {
            await this.props.api.pages_api().removeFile(file.id)

            const result = (await this.props.api.pages_api().load('aboutpage')).data

            runInAction(() => {
                this.images = result.images
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.redirect = true
    }

    render() {
        if (this.redirect) {
            return <Navigate to="/pages" />
        }

        return (
            <div className={'projects'}>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">About Page</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Featured image</h3>
                            <FileDrop disable={this.isSaving} maxFiles={1} featured={true} showFiles={false} files={this.files} existing={this.images} removeExistingFile={this.removeExistingFile} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                link: {
                                    decorators: {
                                        openInNewTab: {
                                            mode: 'manual',
                                            label: 'Open in a new tab',
                                            attributes: {
                                                target: '_blank',
                                                rel: 'noopener noreferrer',
                                            },
                                        },
                                    },
                                },
                            }}
                            data={this.page.text[0]}
                            onReady={(editor: any) => {
                                console.log('Editor is ready to use!', editor)
                            }}
                            onChange={action((event: any, editor: any) => {
                                const data = editor.getData()
                                // console.log({ event, editor, data })

                                this.page.text[0] = data
                            })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Info</h3>
                            <p>
                                Last updated: {moment((this.page as any).createdAt).format('MMMM DD, YYYY')} by {this.page.updatedBy} (version: {this.page.version})
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.save} className="btn width-md waves-effect waves-light btn-success">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} className={'col-md-8 col-xl-6 p-0'} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(AboutPage)
