import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Store from './lib/store'
import Login from './pages/Login'
import Header from './components/Header'
import Dashboard from './pages/Dashboard'
import ProjectsList from './pages/projects/ProjectsList'
import ProjectEdit from './pages/projects/ProjectEdit'
import ProjectView from './pages/projects/ProjectView'
import Synchronization from './pages/settings/Synchronization'
import Booths from './pages/booths/Booths'
import BoothEdit from './pages/booths/BoothEdit'
import Users from './pages/settings/Users'
import Popup from './components/Popup'
import { observe } from 'mobx'
import Pages from './pages/pages/Pages'
import AboutPage from './pages/pages/AboutPage'
import LegalPage from './pages/pages/LegalPage'
import LandingPage from './pages/pages/LandingPage'
import Templates from './pages/cloud/Templates'
import Languages from './pages/cloud/Languages'
import Commit from './pages/cloud/Commit'
import Template from './pages/cloud/Template'
import Editor from './pages/cloud/Editor'
import ProjectLocation from './pages/projects/ProjectLocation'
import Release from './pages/settings/Release'
import Notifications from './pages/Notifications'
import UserList from './pages/users/UsersList'
import UserView from './pages/users/UserView'
import ReleaseForm from './pages/pages/ReleaseForm'
import ProjectBooking from './pages/projects/ProjectBooking'
import BoothView from './pages/booths/BoothView'
import LanguagesOverride from './pages/cloud/LanguagesOverride'

@observer
export default class App extends React.Component<{ api: Api; store: Store }> {
    constructor(props: any) {
        super(props)

        observe(this.props.store.socketStatus, () => {
            console.log('Changed', this.props.store.socketStatus.connected)
        })
    }

    render() {
        return (
            <>
                {this.props.store.isLoggedin ? (
                    <>
                        <Header api={this.props.api} store={this.props.store} />
                        <div className="wrapper">
                            <div className="container-fluid">
                                <Routes>
                                    <Route path="/" element={<Dashboard api={this.props.api} store={this.props.store} />} />

                                    <Route path="/projects/:groupId/edit/:projectId" element={<ProjectLocation api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects/:projectId/edit" element={<ProjectEdit api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects/:projectId/view" element={<ProjectView api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects/:projectId/booking" element={<ProjectBooking api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects/:groupId/new" element={<ProjectLocation api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects/new" element={<ProjectEdit api={this.props.api} store={this.props.store} />} />
                                    <Route path="/projects" element={<ProjectsList api={this.props.api} store={this.props.store} />} />

                                    <Route path="/users/:userId" element={<UserView api={this.props.api} store={this.props.store} />} />
                                    <Route path="/users" element={<UserList api={this.props.api} store={this.props.store} />} />

                                    <Route path="/pages/landing" element={<LandingPage api={this.props.api} store={this.props.store} />} />
                                    <Route path="/pages/legal" element={<LegalPage api={this.props.api} store={this.props.store} />} />
                                    <Route path="/pages/about" element={<AboutPage api={this.props.api} store={this.props.store} />} />
                                    <Route path="/pages/release_forms" element={<ReleaseForm api={this.props.api} store={this.props.store} />} />
                                    <Route path="/pages" element={<Pages api={this.props.api} store={this.props.store} />} />

                                    <Route path="/settings/sync" element={<Synchronization api={this.props.api} store={this.props.store} />} />
                                    <Route path="/settings/users" element={<Users api={this.props.api} store={this.props.store} />} />
                                    <Route path="/settings/release" element={<Release api={this.props.api} store={this.props.store} />} />

                                    <Route path="/cloud/templates/:templateId/edit/:file" element={<Editor api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/templates/:templateId/add/:file" element={<Editor add={true} api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/templates/:templateId/language" element={<LanguagesOverride api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/templates/:templateId" element={<Template api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/templates" element={<Templates api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/languages" element={<Languages api={this.props.api} store={this.props.store} />} />
                                    <Route path="/cloud/commit" element={<Commit api={this.props.api} store={this.props.store} />} />

                                    <Route path="/booths/:uuid/edit" element={<BoothEdit api={this.props.api} store={this.props.store} />} />
                                    <Route path="/booths/:uuid/view" element={<BoothView api={this.props.api} store={this.props.store} />} />
                                    <Route path="/booths/new" element={<BoothEdit api={this.props.api} store={this.props.store} />} />
                                    <Route path="/booths" element={<Booths api={this.props.api} store={this.props.store} />} />

                                    <Route path="/notifications" element={<Notifications api={this.props.api} store={this.props.store} />} />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="wrapper">
                            <div className="container-fluid">
                                <Login api={this.props.api} store={this.props.store} />
                            </div>
                        </div>
                    </>
                )}

                <Popup
                    title={'Connection to server lost'}
                    type="bg-danger"
                    show={this.props.store.isLoggedin && !this.props.store.socketStatus.connected}
                    onClose={() => {}}
                >
                    <div className="custom-modal-text">
                        <p>Don't panic. The app is trying to reconnect automatically every few milliseconds!</p>
                        <p>
                            While you're waiting, please check:
                            <ul>
                                <li>Is the booth computer turned on?</li>
                                <li>Are you connected to the booth's Wi-Fi?</li>
                                <li>Are you close enough to the booth to get a good signal?</li>
                            </ul>
                        </p>
                    </div>
                </Popup>

                <Popup
                    title={'Connection to server lost'}
                    type="bg-danger"
                    show={this.props.store.internal.hardFault}
                    onClose={() => {}}
                >
                    <div className="custom-modal-text">
                        <p>Don't panic. Couldn't connect to storage. Something is wrong, please contact support!</p>
                    </div>
                </Popup>
            </>
        )
    }
}
