import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, runInAction } from 'mobx'
import { ProjectStats } from 'shared/models-web'

import CountUp from 'react-countup'

@observer
export default class LifetimeWidget extends React.Component<{
    api: Api
    store: Store
    showError: (errMsg: string) => void
    project_stats?: ProjectStats
}> {
    @observable
    isLoaded = false
    constructor(props: any) {
        super(props)

        this.init()
    }

    @action
    init = async () => {
        try {
            this.isLoaded = true
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.response.data) {
                    console.log('here', e.response.data)
                    this.props.showError(e.response.data.errMsg)
                } else {
                    this.props.showError(`We got unspecified error: ${e}`)
                }
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className={'card-box widget-inline ' + (this.isLoaded ? 'data-loaded ' : 'data-waiting ')}>
                        <h4 className="mt-0">Lifetime stats</h4>
                        <div className="row">
                            <div className="col-md">
                                <div className="p-2 text-center">
                                    <i className="mdi mdi-briefcase-check mdi-36px"></i>
                                    <h3 className="my-0">{this.props.project_stats ? <CountUp end={this.props.project_stats.projects} /> : '-'}</h3>
                                    <p className="text-muted font-15 mb-0">Projects created</p>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="p-2 text-center">
                                    <i className="mdi mdi-camera mdi-36px"></i>
                                    <h3 className="my-0">{this.props.project_stats ? <CountUp end={this.props.project_stats.images} /> : '-'}</h3>
                                    <p className="text-muted font-15 mb-0">Photos taken</p>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="p-2 text-center">
                                    <i className="mdi mdi-printer mdi-36px"></i>
                                    <h3 className="my-0">{this.props.project_stats ? <CountUp end={this.props.project_stats.prints} /> : '-'}</h3>
                                    <p className="text-muted font-15 mb-0">Photos printed</p>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="p-2 text-center">
                                    <i className="mdi mdi-email mdi-36px"></i>
                                    <h3 className="my-0">{this.props.project_stats ? <CountUp end={this.props.project_stats.emails} /> : '-'}</h3>
                                    <p className="text-muted font-15 mb-0">Emails sent</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
