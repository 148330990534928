import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import { Link } from 'react-router-dom'
import { observable, action, makeObservable } from 'mobx'
import { Project, ProjectStats } from 'shared/models-web'
import moment from 'moment'
import Dropdown from 'react-bootstrap/Dropdown'

@observer
export default class ProjectListEntry extends React.Component<{
    api: Api
    store: Store
    data: { project: Project; stats: ProjectStats }
    reload: () => void
    deleteProject?: (projectId: string) => void
    toggle_archived?: (projectId: string) => void
    importPortraits?: (projectId: string) => void
    isLoading?: boolean
    isLoaded?: boolean
}> {
    @observable
    emailWarning = false
    @observable
    printerWarning = false

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    deleteProject = async () => {
        if (!this.props.deleteProject) {
            return
        }

        await this.props.deleteProject(this.props.data.project.uuid)
    }

    render() {
        let projectIndicators = []
        //check it sycing is enabled
        if (this.props.data.project.boothConfig.backup || this.props.data.project.boothConfig.website) {
            if (this.props.data.stats.sync_backup === 100 && this.props.data.stats.sync_website === 100) {
                projectIndicators.push(
                    <span key="synced">
                        <div className={'badge bg-soft-dark text-muted'}>Synchronized</div>&nbsp;
                    </span>,
                )
            } else {
                projectIndicators.push(
                    <span key="syncing">
                        <div className={'badge bg-soft-info text-info'}>Synchronizing</div>&nbsp;
                    </span>,
                )
            }
        }

        if (this.props.data.project.type === 'single') {
            projectIndicators.push(
                <span key="single">
                    <div className={'badge bg-soft-success text-success'}>Single</div>&nbsp;
                </span>,
            )
        } else if (this.props.data.project.type === 'multi') {
            projectIndicators.push(
                <span key="multi">
                    <div className={'badge bg-soft-warning text-warning'}>Multiple</div>&nbsp;
                </span>,
            )
        }

        if (this.props.data.project.status === 'draft') {
            projectIndicators.push(
                <span key="draft">
                    <div className={'badge bg-soft-warning text-warning'}>Draft</div>&nbsp;
                </span>,
            )
        } else if (this.props.data.project.status === 'hidden') {
            projectIndicators.push(
                <span key="hidden">
                    <div className={'badge bg-soft-info text-info'}>Non photobooth</div>&nbsp;
                </span>,
            )
        } else {
            projectIndicators.push(
                <span key="published">
                    <div className={'badge bg-soft-success text-success'}>Published</div>&nbsp;
                </span>,
            )
        }

        return (
            <div className="col-12">
                <div className={'card-box project-box widget-inline ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                    <Dropdown className={' float-right card-top-buttons-align ml-2'}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop arrow-none">
                            <i className="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                            <Link to={'/projects/' + this.props.data.project.uuid + '/view'} className="dropdown-item">
                                View photos
                            </Link>
                            {this.props.data.project.slots.length > 0 && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <Link to={'/projects/' + this.props.data.project.uuid + '/booking'} className="dropdown-item">
                                        View booking
                                    </Link>
                                </>
                            )}
                            <div className="dropdown-divider"></div>
                            <Link to={'/projects/' + this.props.data.project.uuid + '/edit'} className="dropdown-item">
                                Edit project
                            </Link>
                            {this.props.deleteProject && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onClick={(e: any) => this.deleteProject()}>Delete</Dropdown.Item>
                                </>
                            )}
                            {this.props.toggle_archived && this.props.data.project.archived && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onClick={(e: any) => this.props.toggle_archived && this.props.toggle_archived(this.props.data.project.uuid)}>Un-Archive</Dropdown.Item>
                                </>
                            )}
                            {this.props.toggle_archived && !this.props.data.project.archived && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onClick={(e: any) => this.props.toggle_archived && this.props.toggle_archived(this.props.data.project.uuid)}>Archive</Dropdown.Item>
                                </>
                            )}
                            {this.props.data.project.type === 'single' && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onClick={(e: any) => this.props.importPortraits && this.props.importPortraits(this.props.data.project.uuid)}>Import portraits</Dropdown.Item>
                                </>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="clearfix">
                        <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">{projectIndicators}</div>

                        <h4 className="mt-0 mb-1">
                            <Link to={'/projects/' + this.props.data.project.uuid + '/view'} className="text-light">
                                {this.props.data.project.name}
                            </Link>
                        </h4>
                    </div>
                    <div className="d-sm-none card-top-buttons-align">{projectIndicators}</div>
                    <div className="clearfix">
                        <div className="d-none d-sm-block float-right">
                            <p>
                                <i title="Printing enabled" className={'mdi mdi-printer mr-1 ' + (this.props.data.project.boothConfig.print ? 'text-success' : 'text-secondary')}></i>
                                <i title="Email sending enabled" className={'mdi mdi-email mr-1 ' + (this.props.data.project.boothConfig.email ? 'text-success' : 'text-secondary')}></i>
                                <i title="Release form enabled" className={'mdi mdi-file-document mr-1 ' + (this.props.data.project.boothConfig.releaseForm ? 'text-success' : 'text-secondary')}></i>
                            </p>
                        </div>

                        <p className="d-none d-sm-block text-muted text-uppercase mb-2">
                            {this.props.data.project.type === 'single' && (
                                <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                    <i className="mdi mdi-crosshairs-gps"></i>
                                    <small>
                                        {' '}
                                        {this.props.data.project.location.city}, {this.props.data.project.location.country}
                                    </small>
                                </span>
                            )}
                            <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                <i className="mdi mdi-calendar"></i> <small>{moment(this.props.data.project.startDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</small>
                            </span>
                        </p>
                    </div>
                    <div className="row">
                        {this.props.data.project.type === 'single' && (
                            <>
                                <div className="col">
                                    <Link to={'/projects/' + this.props.data.project.uuid + '/view'} className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                        <h5 className="my-0">
                                            {this.props.data.stats.images}
                                            <span className={'text-nowrap text-muted font-weight-normal pl-1'}>Photos taken</span>
                                        </h5>
                                    </Link>
                                </div>
                                <div className="col">
                                    <div className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                        <h5 className={'my-0 ' + (this.emailWarning ? 'text-warning' : '')}>
                                            {this.props.data.stats.emails}/{this.props.data.stats.images}
                                            <span className={'text-nowrap font-weight-normal pl-1 ' + (this.emailWarning ? 'text-warning ' : 'text-muted ')}>Emails sent</span>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col"></div>
                            </>
                        )}
                        {this.props.data.project.type === 'multi' && (
                            <>
                                <div className="col">
                                    <Link to={'/projects/' + this.props.data.project.uuid + '/view'} className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                        <h5 className="my-0">
                                            {this.props.data.stats.images}
                                            <span className={'text-nowrap text-muted font-weight-normal pl-1'}>Photos taken</span>
                                        </h5>
                                    </Link>
                                </div>
                                <div className="col">
                                    <div className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                        <h5 className={'my-0 ' + (this.emailWarning ? 'text-warning' : '')}>
                                            {this.props.data.stats.emails}/{this.props.data.stats.images}
                                            <span className={'text-nowrap font-weight-normal pl-1 ' + (this.emailWarning ? 'text-warning ' : 'text-muted ')}>Emails sent</span>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                        <h5 className={'my-0 '}>
                                            {this.props.data.project.projectIds.length}
                                            <span className={'text-nowrap font-weight-normal pl-1'}>Locations</span>
                                        </h5>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className="row">
                        {this.props.data.stats.sync_backup === 100 && this.props.data.stats.sync_website === 100 && (
                            <div className="col">
                                <div className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                    <h5 className="my-0">
                                        100%
                                        <span className="text-nowrap text-muted font-weight-normal pl-1">Synced to web</span>
                                    </h5>
                                </div>
                            </div>
                        )}
                        {this.props.data.stats.sync_backup === 100 && this.props.data.stats.sync_website === 100  && (
                            <div className="col">
                                <div className="p-2 inline-widget border border-secondary bg-soft-secondary d-flex align-items-center justify-content-center mt-1 mb-1">
                                    <h5 className="my-0">
                                        100%
                                        <span className="text-nowrap text-med font-weight-normal pl-1">Synced to cloud</span>
                                    </h5>
                                </div>
                            </div>
                        )}
                    </div>*/}
                    {(this.props.data.stats.sync_backup !== 100 || this.props.data.stats.sync_website !== 100) && (
                        <div className="row mt-2">
                            <div className="col-sm-6">
                                <p className="mb-2 font-weight-bold">
                                    {this.props.data.project.boothConfig.website && (
                                        <>
                                            Synchronizing to website:
                                            <span className="float-right">{Math.round(this.props.data.stats.sync_website * 100) + '%'}</span>
                                        </>
                                    )}
                                    {!this.props.data.project.boothConfig.website && <>Synchronizing to website disabled</>}
                                </p>
                                <div className={'progress mb-1 ' + (!this.props.data.project.boothConfig.website ? 'bg-soft-info' : '')} style={{ height: '7px' }}>
                                    <div
                                        className={'progress-bar ' + (Math.round(this.props.data.stats.sync_website * 100) !== 100 ? 'progress-bar-striped progress-bar-animated ' : '') + (this.props.store.isInternet ? 'bg-info ' : 'bg-warning ')}
                                        role="progressbar"
                                        style={{
                                            width: this.props.data.project.boothConfig.website ? Math.round(this.props.data.stats.sync_website * 100) + '%' : '0%',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <p className="mb-2 font-weight-bold">
                                    {this.props.data.project.boothConfig.backup && (
                                        <>
                                            Synchronizing to cloud:
                                            <span className="float-right">{Math.round(this.props.data.stats.sync_backup * 100) + '%'}</span>
                                        </>
                                    )}
                                    {!this.props.data.project.boothConfig.backup && <>Synchronizing to cloud disabled</>}
                                </p>
                                <div className={'progress mb-1 ' + (!this.props.data.project.boothConfig.backup ? 'bg-soft-info' : '')} style={{ height: '7px' }}>
                                    <div
                                        className={'progress-bar ' + (Math.round(this.props.data.stats.sync_backup * 100) !== 100 ? 'progress-bar-striped progress-bar-animated ' : '') + (this.props.store.isInternet ? 'bg-info ' : 'bg-warning ')}
                                        role="progressbar"
                                        style={{
                                            width: this.props.data.project.boothConfig.backup ? Math.round(this.props.data.stats.sync_backup * 100) + '%' : '0%',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
