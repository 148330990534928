import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Mode, WizardPosition } from '../ImportPortraits'

export default observer(function ImportMode(props: { position: WizardPosition; next: (mode: WizardPosition) => void; onClose: () => void; mode: Mode; selectMode: (mode: Mode) => void; setImportContext: (context: { tagline: boolean; grayscale: boolean; ignoreRotate: boolean; rotate: boolean }) => void }) {
    // onClick={(e: any) => props.selectMode('PICTURE_ONLY')}
    // onClick={(e: any) => props.selectMode('PICTURES_WITH_CSV')}

    const [data, setData] = useState({
        tagline: false,
        grayscale: false,
        ignoreRotate: false,
        rotate: false,
    })

    return (
        <>
            <h3>Select mode</h3>

            <div className="mb-2">
                <div className="custom-control custom-radio">
                    <input type="radio" id="PICTURE_ONLY" name="mode" className="custom-control-input" value={'PICTURE_ONLY'} checked={props.mode === 'PICTURE_ONLY'} />
                    <label className="custom-control-label" id="PICTURE_ONLY">
                        Picture only&nbsp;
                    </label>
                </div>
                <div className="custom-control custom-radio">
                    <input type="radio" id="PICTURES_WITH_CSV" name="mode" className="custom-control-input" value={'PICTURES_WITH_CSV'} checked={props.mode === 'PICTURES_WITH_CSV'} disabled={true} />
                    <label className="custom-control-label" id="PICTURES_WITH_CSV">
                        Pictures with CSV&nbsp;
                    </label>
                </div>
            </div>

            <div className="mb-2">
                <div className="form-group mb-3">
                    <div className="checkbox checkbox-primary mb-2">
                        <input
                            id={'grayscale'}
                            type="checkbox"
                            checked={data.grayscale}
                            onChange={(e) => {
                                const newData = Object.assign({}, data, {
                                    grayscale: e.target.checked,
                                })
                                setData(newData)
                                props.setImportContext(newData)
                            }}
                        />
                        <label htmlFor={'grayscale'}>Convert to grayscale</label>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <div className="checkbox checkbox-primary mb-2">
                        <input
                            id={'tagline'}
                            type="checkbox"
                            checked={data.tagline}
                            onChange={(e) => {
                                const newData = Object.assign({}, data, {
                                    tagline: e.target.checked,
                                })
                                setData(newData)
                                props.setImportContext(newData)
                            }}
                        />
                        <label htmlFor={'tagline'}>Add tagline to imported images</label>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <div className="checkbox checkbox-primary mb-2">
                        <input
                            id={'ignoreRotate'}
                            type="checkbox"
                            checked={data.ignoreRotate}
                            onChange={(e) => {
                                const newData = Object.assign({}, data, {
                                    ignoreRotate: e.target.checked,
                                })
                                setData(newData)
                                props.setImportContext(newData)
                            }}
                        />
                        <label htmlFor={'ignoreRotate'}>Ignore auto rotate</label>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <div className="checkbox checkbox-primary mb-2">
                        <input
                            id={'rotate'}
                            type="checkbox"
                            checked={data.rotate}
                            onChange={(e) => {
                                const newData = Object.assign({}, data, {
                                    rotate: e.target.checked,
                                })
                                setData(newData)
                                props.setImportContext(newData)
                            }}
                        />
                        <label htmlFor={'rotate'}>Execute rotate</label>
                    </div>
                </div>
            </div>

            <div className="text-right mt-3">
                <div className="btn-group">
                    <button type="button" onClick={props.onClose} disabled={false} className="btn width-md waves-effect waves-light btn-secondary">
                        Cancel
                    </button>
                    <button type="button" onClick={() => props.next('SELECT_FOLDER')} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                        Next
                    </button>
                </div>
            </div>
        </>
    )
})
