import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'

import { observable, action, makeObservable, runInAction } from 'mobx'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { timeout } from '../../utils/utils'
import { Navigate } from 'react-router-dom'
import { withRouter } from '../../utils/react-utils'

@observer
class Commit extends React.Component<{
    api: Api
    store: Store
    history: History
}> {
    @observable
    isLoaded = 0

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false
    disposer?: any

    //erro model controls
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    //data
    @observable
    changes: string[] = []

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        try {
            const changes = (await this.props.api.git_api().changes()).data

            runInAction(() => {
                this.changes = changes
                this.isLoaded = 1
            })

            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    @action
    commitAndPush = async () => {
        this.saving = true

        try {
            await this.props.api.git_api().commitAndPush()

            const changes = (await this.props.api.git_api().changes()).data

            runInAction(() => {
                this.isFormDirty = false
                this.changes = changes
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.saving = false
                this.showSaveChangesWait = false
                this.showSaveChanges = false
            })
        }
    }

    @action
    push = async () => {
        this.saving = true

        try {
            await this.props.api.git_api().push()

            const changes = (await this.props.api.git_api().changes()).data

            runInAction(() => {
                this.isFormDirty = false
                this.changes = changes
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            this.saving = false
            this.showSaveChangesWait = false
            this.showSaveChanges = false
        }
    }

    @action
    discard = async () => {
        try {
            await this.props.api.git_api().discard()
            
            runInAction(() => {
                this.isFormDirty = false
                this.isLoaded = 0
            })

            const changes = (await this.props.api.git_api().changes()).data

            runInAction(() => {
                this.changes = changes
                this.isLoaded = 1
            })

            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        const changes = []

        for (const change of this.changes) {
            changes.push(<li key={change}>{change}</li>)
        }

        return (
            <div className="projects">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Commit</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <ul>{changes}</ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.discard} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard changes
                                </button>
                                {this.changes.length > 0 && (
                                    <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.commitAndPush()} data-color="green" data-style={ZOOM_OUT}>
                                        Commit & Push
                                    </LaddaButton>
                                )}
                                {this.changes.length === 0 && (
                                    <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.push()} data-color="green" data-style={ZOOM_OUT}>
                                        Push
                                    </LaddaButton>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Commit)
