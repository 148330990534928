import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import { Link } from 'react-router-dom'
import { action, makeObservable, observable } from 'mobx'
import Dropdown from 'react-bootstrap/Dropdown'
import Popup from './Popup'
import UserProfile from './UserProfile'
import Notifications from './Notifications'

@observer
export default class Header extends React.Component<{ api: Api; store: Store }> {
    @observable
    userProfileModal = false

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    openUserProfile = () => {
        this.userProfileModal = true

        console.log('tets test tests')
    }

    @action
    closeUserProfile = () => {
        this.userProfileModal = false
    }

    @action
    onSave = async (old_password: string, new_password: string): Promise<true | string> => {
        try {
            await this.props.api.change_password(old_password, new_password)

            await this.closeUserProfile()

            return true
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
                return 'Not logged in'
            } else if (e.response && e.response.data) {
                console.log('here', e.response.data)
                return e.response.data.errMsg
            } else {
                return `We got unspecified error: ${e}`
            }
        }
    }

    render() {
        return (
            <header id="topnav">
                <div className="navbar-custom navbar-fix">
                    <div className="container-fluid">
                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li className="dropdown notification-list">
                                <a className="navbar-toggle nav-link" href="">
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                            </li>
                        </ul>

                        <ul className="list-unstyled topnav-menu float-right mb-0">
                            <li>
                                <Link to="/cloud/commit" className="nav-link waves-effect waves-light">
                                    <i className="fe-git-commit"></i> Save changes
                                </Link>
                            </li>
                            <Notifications api={this.props.api} store={this.props.store} />

                            <Dropdown className={'dropdown notification-list'} as="li">
                                <Dropdown.Toggle variant="success" id="user-profile" as="span" className="nav-link nav-user mr-0 waves-effect waves-light">
                                    <span className="pro-user-name ml-1 text-capitalize">
                                        {this.props.store.admin?.username} <i className="mdi mdi-chevron-down"></i>
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right profile-dropdown'}>
                                    <Dropdown.Item className="dropdown-item notify-item" onClick={(e: any) => this.openUserProfile()}>
                                        <i className="mdi mdi-square-edit-outline"></i>
                                        <span>Edit my profile</span>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item notify-item" onClick={(e: any) => this.props.store.logout()}>
                                        <i className="fe-log-out"></i>
                                        <span>Logout</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>

                        <ul className="list-unstyled navigation-menu topnav-menu topnav-menu-left m-0">
                            <Dropdown className={'has-submenu'} as="li">
                                <Link to="/" className="waves-effect waves-light">
                                    <i className="fe-airplay"></i>Dashboard
                                </Link>
                            </Dropdown>
                            <Dropdown className={'has-submenu'} as="li">
                                <Link to="/projects" className="waves-effect waves-light">
                                    <i className="fe-briefcase"></i>Projects
                                </Link>
                            </Dropdown>
                            <Dropdown className={'has-submenu'} as="li">
                                <Link to="/users" className="waves-effect waves-light">
                                    <i className="fe-users"></i>Users
                                </Link>
                            </Dropdown>
                            <Dropdown className={'has-submenu'} as="li">
                                <Link to="/pages" className="waves-effect waves-light">
                                    <i className="fe-file-text"></i>Pages
                                </Link>
                            </Dropdown>
                            <Dropdown className={'dropdown notification-list'} as="li">
                                <Dropdown.Toggle variant="success" id="user-profile" as="span" className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light">
                                    <i className="fe-cloud-lightning"></i>
                                    {'\u00A0'}
                                    {'\u00A0'}Templates & Languages <div className="arrow-down"></div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'dropdown-menu'} bsPrefix="no-class-please" as="ul">
                                    <Dropdown.Item className="dropdown-item notify-item menu-item" bsPrefix="no-class-please" as="li">
                                        <Link className="link-item" to="/cloud/templates">
                                            <span>Templates</span>
                                        </Link>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item notify-item menu-item" bsPrefix="no-class-please" as="li">
                                        <Link className="link-item" to="/cloud/languages">
                                            Languages
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className={'has-submenu'} as="li">
                                <Link to="/booths" className="waves-effect waves-light">
                                    <i className="fe-aperture"></i>Booths
                                </Link>
                            </Dropdown>
                            <Dropdown className={'dropdown notification-list'} as="li">
                                <Dropdown.Toggle variant="success" id="user-profile" as="span" className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light">
                                    <i className="fe-cpu"></i>
                                    {'\u00A0'}
                                    {'\u00A0'}Settings <div className="arrow-down"></div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'dropdown-menu'} bsPrefix="no-class-please" as="ul">
                                    <Dropdown.Item className="dropdown-item notify-item menu-item" bsPrefix="no-class-please" as="li">
                                        <Link className="link-item" to="/settings/sync">
                                            Email & Cloud
                                        </Link>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item notify-item menu-item" bsPrefix="no-class-please" as="li">
                                        <Link className="link-item" to="/settings/users">
                                            Users
                                        </Link>
                                    </Dropdown.Item>
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item className="dropdown-item notify-item menu-item" bsPrefix="no-class-please" as="li">
                                        <Link className="link-item" to="/settings/release">
                                            Release
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <Popup onClose={this.closeUserProfile} title={'Change password'} show={this.userProfileModal}>
                    <UserProfile onClose={this.closeUserProfile} onSave={this.onSave} />
                </Popup>
            </header>
        )
    }
}
