import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import $ from 'jquery'

@observer
export default class Multiselect extends React.Component<{
    id: string
    data: { [id: string]: string }
    value: string[]
    onChange: (selected: string[]) => void
    disabled?: boolean
    disable?: string[]
}> {
    @observable
    data: { [id: string]: boolean } = {}
    @observable
    isOpen = false

    isFirstTime = true

    constructor(props: any) {
        super(props)

        makeObservable(this)

        $('html').on(
            'click',
            action((e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
                if (!$(e.target).hasClass('multiselect-dropdown')) {
                    if (this.isOpen) {
                        this.isOpen = false
                    }
                }
            }),
        )
    }

    @action
    componentDidUpdate = () => {
        if (this.isFirstTime && this.props.value.length > 0) {
            for (const s of this.props.value) {
                this.data[s] = true
            }
            this.isFirstTime = false
        }

        for (const i in this.data) {
            if (this.props.disable && this.props.disable.indexOf(i) !== -1) {
                this.data[i] = false
            }
        }
    }

    onChange = (key: string) => {
        const result = []
        for (const i in this.data) {
            if (this.data[i]) {
                result.push(i)
            }
        }

        this.props.onChange(result)
    }

    @action
    onClick = () => {
        this.isOpen = this.isOpen ? false : true
    }

    render() {
        const options = []
        for (const i in this.props.data) {
            const option = this.props.data[i]

            if (this.props.disable && this.props.disable.indexOf(i) !== -1) {
                continue
            }

            options.push(
                <li key={i}>
                    <a
                        className="dropdown-item"
                        onClick={() => {
                            this.data[i] = !this.data[i]
                            this.onChange(i)
                        }}
                    >
                        <span className="text">{option}</span>
                        <span style={{ float: 'right' }} className={'check-mark mdi ' + (this.data[i] ? 'mdi-check' : '')}></span>
                    </a>
                </li>,
            )
        }

        let selected = []
        for (const i in this.data) {
            if (this.data[i]) {
                selected.push(`${this.props.data[i]}`)
            }
        }

        return (
            <div className="dropdown bootstrap-select show-tick multiselect-dropdown">
                <button type="button" className="btn dropdown-toggle bs-placeholder btn-secondary multiselect-dropdown" title="Nothing selected" onClick={this.onClick} style={{ width: '100%', textAlign: 'left' }}>
                    <div className="filter-option multiselect-dropdown">
                        <div className="filter-option-inner multiselect-dropdown">
                            <div className="filter-option-inner-inner multiselect-dropdown">{selected.length === 0 ? 'Nothing selected' : selected.join(', ')}</div>
                        </div>
                    </div>
                    <span className="bs-caret multiselect-dropdown">
                        <span className="caret multiselect-dropdown"></span>
                    </span>
                </button>
                <ul className="dropdown-menu inner" role="presentation" style={{ marginTop: '0px', marginBottom: '0px', display: this.isOpen ? 'block' : 'none', width: '100%', textAlign: 'left' }}>
                    {options}
                </ul>
            </div>
        )
    }
}
