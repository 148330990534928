import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import NumberInputComponent from '../../../components/ui-elements/NumberInputComponent'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectWorkflow extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    isNew: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '') + (this.props.isNew ? ' mb-0' : '')}>
                <div className="card-body pb-0">
                    <form>
                        <h4 className="header-title">Workflow</h4>
                        <p className="sub-header"> Every project is unique, and so are its requirements. Here you can fine tune some workflow details. </p>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id={'releaseForm'} type="checkbox" checked={this.props.project.boothConfig.releaseForm} onChange={(e) => (this.props.project.boothConfig.releaseForm = e.target.checked)} />
                                        <label htmlFor={'releaseForm'}>Collect release forms</label>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id={'email'} type="checkbox" checked={this.props.project.boothConfig.email} onChange={(e) => (this.props.project.boothConfig.email = e.target.checked)} />
                                        <label htmlFor={'email'}>Send emails to participants</label>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id={'print'} type="checkbox" checked={this.props.project.boothConfig.print} onChange={(e) => (this.props.project.boothConfig.print = e.target.checked)} />
                                        <label htmlFor={'print'}>Print photos automatically</label>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id={'print_ask'} type="checkbox" checked={this.props.project.boothConfig.print_ask} onChange={(e) => (this.props.project.boothConfig.print_ask = e.target.checked)} />
                                        <label htmlFor={'print_ask'}>Ask for print</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id="website" type="checkbox" checked={this.props.project.boothConfig.website} onChange={(e) => (this.props.project.boothConfig.website = e.target.checked)} />
                                        <label htmlFor="website">Synchronize photos to website</label>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <div className="checkbox checkbox-primary mb-2">
                                        <input id={'backup'} type="checkbox" checked={this.props.project.boothConfig.backup} onChange={(e) => (this.props.project.boothConfig.backup = e.target.checked)} />
                                        <label htmlFor={'backup'}>Synchronize photos to cloud</label>
                                    </div>
                                </div>

                                <NumberInputComponent
                                    title={'Countdown timer (seconds)'}
                                    value={this.props.project.boothConfig.timertime}
                                    onChange={action((val: any) => {
                                        this.props.project.boothConfig.timertime = parseInt(val)
                                    })}
                                    displayButtons={true}
                                    className={'mb-3'}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
