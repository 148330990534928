import React from 'react'
import { useTable, usePagination, useSortBy, useGlobalFilter, Column, useRowSelect, useFilters, Row } from 'react-table'
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons'
import GlobalFilter from './table/GlobalFilter'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'

export interface TableOptions {
    search: boolean
    filter: boolean
}

export interface FilterOptions {
    name: string
    type: 'range' | 'string'
    value: any
    valueRaw: any
    match?: (term: string) => undefined | string
}

type ColumnInternal = Column | { disableSortBy: boolean; actions?: (row: any) => React.ReactNode | React.ReactNode[]; filter: string }

export default observer(function Table<T>(props: { columns: ColumnInternal[]; data: any[]; showFooter?: boolean; defaultPageSize?: number; setUpdater?: (func: () => void) => void; tableOptions?: TableOptions; filters?: { [filterName: string]: FilterOptions } }) {
    const [data, setData] = useState('')
    const _tableOptions: TableOptions = props.tableOptions
        ? props.tableOptions
        : {
              filter: false,
              search: true,
          }

    const filterTypes = useMemo(
        () => ({
            stringRangeFilter: (rows: Array<Row<any>>, id: string, filterValue: any) => {
                const filter = props.filters && props.filters[id]

                if (!filter || !filter.match) {
                    return rows
                }
                const match = filter.match

                return rows.filter((row) => {
                    const rowValue = row.values[id]

                    if (rowValue && Array.isArray(filterValue)) {
                        const [v1r, v2r] = filterValue

                        const v1 = Number.parseInt(v1r)
                        const v2 = Number.parseInt(v2r)
                        const term = match(rowValue)

                        if (term) {
                            let termNumber = Number.parseInt(term)

                            return !isNaN(termNumber) && termNumber >= v1 && termNumber < v2 ? row : undefined
                        }

                        return undefined
                    } else {
                        //nofilter
                        return row
                    }
                })
            },
        }),
        [],
    )

    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, prepareRow, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preFilteredRows, preGlobalFilteredRows, setGlobalFilter, setFilter } = useTable(
        {
            columns: props.columns as any,
            data: props.data,
            initialState: { pageIndex: 0, pageSize: props.defaultPageSize ? props.defaultPageSize : 20 } as any,
            filterTypes,
        } as any,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    ) as any

    const { pageIndex, pageSize } = state

    if (props.setUpdater) {
        props.setUpdater(() => {
            setGlobalFilter(data)
        })
    }

    //middleware
    const setGlobalFilter2 = (text: string | undefined) => {
        if (text) {
            setData(text)
        }
        setGlobalFilter(text)
    }

    // Render the UI for your table
    return (
        <>
            <div className="table-responsive">
                <GlobalFilter globalFilter={state.globalFilter} preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter2} tableOptions={_tableOptions} preFilteredRows={preFilteredRows} setFilter={setFilter} filters={props.filters} />
                <table {...getTableProps()} className="table mb-20">
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <>
                                                        &nbsp;
                                                        <ArrowDown />
                                                    </>
                                                ) : (
                                                    <>
                                                        &nbsp;
                                                        <ArrowUp />
                                                    </>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any, i: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        if (cell.column.actions) {
                                            return <td {...cell.getCellProps()}>{cell.column.actions(row)}</td>
                                        }

                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        {footerGroups.map((group: any) => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map((column: any) => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>

                {(props.showFooter === undefined || props.showFooter) && (
                    <div className="d-md-flex align-items-left">
                        <ul className="pagination mb-0 justify-content-center">
                            <li className="page-item">
                                <button className="btn btn-secodary page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-secodary page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-secodary page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="btn btn-secodary page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </button>
                            </li>
                        </ul>
                        <div className="text-md-left text-center mr-10 ml-10 lh-35">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                            | Go to page:{' '}
                        </div>
                        <div className="text-md-left text-center mr-10">
                            <input
                                type="number"
                                className="form-control"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </div>
                        <div className="text-md-left text-center lh-35">
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[20, 50, 100, 500].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
})
