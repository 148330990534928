import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Admin } from 'shared/models-web'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { timeout } from '../../utils/utils'
import UserEditModal from '../../components/UserEditModal'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import _ from 'lodash'

import uuid from 'uuid'
import { withRouter } from '../../utils/react-utils'
import Popup from '../../components/Popup'
import type { History } from 'history'

@observer
class Users extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void } }> {
    @observable
    isLoaded = 0
    @observable
    users: Admin[] = []
    @observable
    newUsers: Admin[] = []
    @observable
    editedUsers: Admin[] = []
    @observable
    deletedUsers: string[] = []

    //user modal
    @observable
    showUserModal = false
    @observable
    selectedUser?: Admin
    @observable
    userPin?: string
    @observable
    editing = false
    @observable
    addnew = false

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false

    //erro model controls
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        // window.addEventListener('beforeunload', this.beforeunload)
        try {
            const users = (await this.props.api.admins_api().list()).data
            runInAction(() => {
                this.users = users

                //this fixes problems with clikcing links if we use loading
                this.isLoaded = 1
            })
            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
            } else if (e.response && e.response.data) {
                console.log('here', e.response.data)
                this.showErrorModal = true
                this.errorModalText = e.response.data.errMsg
            } else {
                this.showErrorModal = true
                this.errorModalText = `We got unspecified error: ${e}`
            }
        }

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onCloseChanges = () => {
        this.showSaveChanges = false
    }

    @action
    onCloseSaveChanges = async () => {
        this.showSaveChangesWait = true

        await this.save(undefined, true)
    }

    @action
    discardChanges = async () => {
        if (this.showSaveChangesWait) {
            return
        }

        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        runInAction(() => {
            this.isRedirect = true
        })
    }

    @action
    save = async (e?: any, redirect = false) => {
        this.saving = true

        try {
            //save new users
            for (const user of this.newUsers) {
                await this.props.api.admins_api().add(user)
            }

            for (const user of this.editedUsers) {
                await this.props.api.admins_api().edit(user)
            }

            for (const uuid of this.deletedUsers) {
                await this.props.api.admins_api().delete(uuid)
            }
            const users = (await this.props.api.admins_api().list()).data

            runInAction(() => {
                this.users = users

                this.editedUsers = []
                this.newUsers = []
                this.deletedUsers = []

                this.isFormDirty = false
                if (redirect === true) {
                    this.showSaveChangesWait = false
                    this.showSaveChanges = false
                    this.isRedirect = true
                }
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.saving = false
                this.showSaveChangesWait = false
                this.showSaveChanges = false
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    @action
    editUser = (user: Admin) => {
        if (this.saving) {
            return
        }

        this.showUserModal = true
        this.selectedUser = Object.assign({}, user)
        this.userPin = undefined
        this.editing = true
        this.addnew = false
    }

    @action
    editNewUser = (user: Admin) => {
        if (this.saving) {
            return
        }

        this.showUserModal = true
        this.selectedUser = Object.assign({}, user)
        this.userPin = undefined
        this.editing = true
        this.addnew = true
    }

    @action
    newUser = () => {
        if (this.saving) {
            return
        }

        this.showUserModal = true
        this.selectedUser = undefined
        this.userPin = undefined
        this.editing = false
        this.addnew = true
    }

    @action
    onClose = () => {
        this.showUserModal = false
    }

    @action
    onSave = (user: Admin) => {
        try {
            this.isFormDirty = true
            if (this.editing && !this.addnew) {
                //check user was already edited
                _.remove(this.users, { uuid: user.uuid })
                _.remove(this.editedUsers, { uuid: user.uuid })

                this.editedUsers.push(user)
            } else if (this.editing && this.addnew) {
                //we have a "new" user
                if (_.remove(this.newUsers, { uuid: user.uuid }).length === 0) {
                    user.uuid = uuid.v4()
                }

                this.newUsers.push(user)
            } else if (!this.editing && this.addnew) {
                this.newUsers.push(user)
            }
        } catch (eRaw) {
            const e = eRaw as any

            if (e.response && e.response.status === 403) {
                this.props.store.isLoggedin = false
            } else if (e.response && e.project_stats.data) {
                console.log('here', e.response.data)
                this.showErrorModal = true
                this.errorModalText = e.response.data.errMsg
            } else {
                this.showErrorModal = true
                this.errorModalText = `We got unspecified error: ${e}`
            }
        } finally {
            this.selectedUser = undefined
            this.showUserModal = false
        }
    }

    @action
    deleteUser = (uuid: string) => {
        this.deletedUsers.push(uuid)
        this.showUserModal = false

        _.remove(this.users, { uuid })
        _.remove(this.editedUsers, { uuid })

        this.isFormDirty = true
    }

    resolveFlag = (flag: number) => {
        if (flag === 0) {
            return 'Admin'
        } else if (flag === 1) {
            return 'SuperAdmin'
        }
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Users</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <form id="email-sending">
                                    <p className="sub-header">Manage users for Insideout photobooth website.</p>
                                    <div className="row mb-2">
                                        <div className="col-sm-4">
                                            <button type="button" className="btn btn-success btn-rounded waves-effect waves-light" onClick={this.newUser}>
                                                <i className="mdi mdi-plus"> </i>New User
                                            </button>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-centered" id="products-datatable">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th className="text-right">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.users.map((user) => (
                                                    <tr key={user.uuid}>
                                                        <td>{user.username}</td>
                                                        <td>{user.email}</td>
                                                        <td>{this.resolveFlag(user.flags)}</td>
                                                        <td className="text-right">
                                                            <a onClick={() => this.editUser(user)} className="action-icon">
                                                                <i className="mdi mdi-square-edit-outline"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {this.editedUsers.map((user) => (
                                                    <tr key={user.uuid}>
                                                        <td>{user.username}</td>
                                                        <td>{user.email}</td>
                                                        <td>{this.resolveFlag(user.flags)}</td>
                                                        <td className="text-right">
                                                            <a onClick={() => this.editUser(user)} className="action-icon">
                                                                <i className="mdi mdi-square-edit-outline"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {this.newUsers.map((user) => (
                                                    <tr key={user.username}>
                                                        <td>{user.username}</td>
                                                        <td>{user.email}</td>
                                                        <td>{this.resolveFlag(user.flags)}</td>
                                                        <td className="text-right">
                                                            <a onClick={() => this.editNewUser(user)} className="action-icon">
                                                                <i className="mdi mdi-square-edit-outline"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.save(undefined)} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>

                <UserEditModal show={this.showUserModal} user={this.selectedUser} newuser={this.addnew} edituser={this.editing} onClose={this.onClose} onSave={this.onSave} deleteUser={this.deleteUser} />
                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" disabled={this.showSaveChangesWait} onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.showSaveChangesWait} onClick={() => this.onCloseSaveChanges()} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Users)
