import { observer } from 'mobx-react'
import React, { useState } from 'react'

export default observer(function FloatingCheckbox(props: {id: string, checked: (id: string) => boolean; onChange: (e: any) => void }) {
    return (
        <>
            <div
                className={'checkrow checkrow-primary checkrow-floating'}
                onClick={() => {
                    props.onChange({
                        target: {
                            id: props.id, 
                            checked: !props.checked(props.id),
                        },
                    })
                }}
            >
                <div className={props.checked(props.id) ? 'checked-color' : ''}>
                    <div className={props.checked(props.id) ? 'checked' : ''}></div>
                </div>
            </div>
        </>
    )
})
