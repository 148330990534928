import React from 'react'
import { observer } from 'mobx-react'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'

import FileDrop from '../../../components/ui-elements/FileDrop'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectHighlights extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    images: { id: string; url: string; filename: string }[]
    files: File[]
    removeExistingFile: (file: { id: string; url: string; filename: string }) => Promise<any>
}> {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Highlights</h3>
                            <FileDrop disable={this.props.isSaving} maxFiles={30} featured={false} showFiles={true} files={this.props.files} existing={this.props.images} removeExistingFile={this.props.removeExistingFile} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
