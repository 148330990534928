import React from 'react'
import { observer } from 'mobx-react'
import { observable, action, makeObservable, runInAction } from 'mobx'
import Store from '../lib/store'
import Api from 'shared/adminApi'
import { Notifications } from 'shared/models-web'
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
import _ from 'lodash'
import { withRouter } from '../utils/react-utils'
import Popup from '../components/Popup'

@observer
class Notification extends React.Component<{ api: Api; store: Store; history: History }> {
    @observable
    isLoading = false
    @observable
    hasMore = false
    @observable
    filter = 'hour'
    @observable
    from = 0

    @observable
    notifications: Notifications[] = []
    @observable
    notifications_count = 0

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        await this.loadNotifications()

        // Binds our scroll event handler
        window.onscroll = _.debounce(async () => {
            // Bails early if:
            // * there's an error
            // * it's already loading
            // * there's nothing left to load
            if (this.isLoading || !this.hasMore) return

            // Checks that the page has scrolled to the bottom
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                this.from = this.notifications.length

                await this.loadNotifications()
            }
        }, 100)
    }

    componentDidMount = () => {
        this.props.store.on('notifications.event', this.reload)
    }

    componentWillUnmount = () => {
        window.onscroll = null
        this.props.store.off('notifications.event', this.reload)
    }

    reload = async () => {
        this.hasMore = true
        this.from = 0
        this.notifications = []

        await this.loadNotifications()
    }

    loadNotifications = async () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        try {
            const data = (await this.props.api.notification_api().get(this.filter, undefined, this.from, 50)).data

            runInAction(() => {
                this.notifications = this.notifications.concat(data.events)
                this.notifications_count = data.all

                this.hasMore = data.hasMore
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter
        this.hasMore = true
        this.from = 0
        this.notifications = []

        await this.loadNotifications()
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    icon = (notification: Notifications) => {
        switch (notification.section) {
            case 'project':
                return 'mdi-file-document'
            case 'printer':
                return 'mdi-printer'
            case 'email':
                return 'mdi-email'
            case 'cloud':
                return 'mdi-cloud-outline'
            case 'camera':
                return 'mdi-camera'
            case 'backup':
                return 'mdi-upload'
            case 'export':
                return 'mdi-usb'
        }

        return 'mdi-printer'
    }

    css_class = (notification: Notifications) => {
        switch (notification.type) {
            case 'info':
                return 'bg-success'
            case 'error':
                return 'bg-danger'
            case 'warn':
                return 'bg-warning'
        }

        return 'bg-success'
    }

    remove = async (id: string) => {
        try {
            await this.props.api.notification_api().remove(id)
            await this.loadNotifications()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Notifincations</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <div className="btn-group mb-3">
                            <button type="button" onClick={() => this.toggleFilter('hour')} className={'btn waves-effect waves-light ' + (this.filter === 'hour' ? 'btn-primary' : 'btn-secondary')}>
                                Last hour
                            </button>
                            <button type="button" onClick={() => this.toggleFilter('day')} className={'btn waves-effect waves-light ' + (this.filter === 'day' ? 'btn-primary' : 'btn-secondary')}>
                                Last 24h
                            </button>
                            <button type="button" onClick={() => this.toggleFilter('all')} className={'btn waves-effect waves-light ' + (this.filter === 'all' ? 'btn-primary' : 'btn-secondary')}>
                                Everything
                            </button>
                        </div>
                    </div>
                </div>

                {this.isLoading && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card-box widget-inline">
                                <div className="row mb-0">
                                    <div className="col-sm-12">
                                        <p className="mb-0">Loading ...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!this.isLoading && this.notifications.length === 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card-box widget-inline">
                                <div className="row mb-0">
                                    <div className="col-sm-12">
                                        <p className="mb-0">Nothing to show here yet.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.notifications.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card-box widget-inline">
                                <div className="col notification-list">
                                    {this.notifications.map((notification) => (
                                        <div key={(notification as any)._id} className="row notify-item">
                                            <div className={'notify-icon mr-0 ' + this.css_class(notification)}>
                                                <i className={'mdi ' + this.icon(notification)}></i>
                                            </div>
                                            <div className="col notify-details ml-0">
                                                <strong>{notification.title} &ndash; </strong>
                                                {notification.actor}
                                                <small className="text-muted">
                                                    {moment(notification.time, 'X').from(moment())}: &nbsp;
                                                    {notification.text}
                                                </small>
                                            </div>

                                            <Dropdown className={'d-flex align-items-center mr-1'}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop arrow-none">
                                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                                    <Dropdown.Item onSelect={(e: any) => this.remove((notification as any)._id)}>Remove</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Popup onClose={this.onCloseErrorModal} className={'col-md-8 col-xl-4 p-0'} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Notification)
