

export async function timeout(timeout: number) {
    return new Promise((resolve, reject) => setTimeout(resolve, timeout));
}

export function generateRange(): string[] {
    const results = [];

    for (let i = 0; i <= 9; i++) {
        results.push(Number(i).toString())
    }

    for (let i = 'a'.charCodeAt(0); i <= 'z'.charCodeAt(0); i++) {
        results.push(String.fromCharCode(i));
    }

    return results;
}

// credit: Typescript documentation, src 
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}