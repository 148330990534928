import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import _ from 'lodash'

@observer
export default class Select extends React.Component<{
    id: string
    data: { options: any; key?: string; value?: string }
    value: any
    onChange: (value: any) => void
    disabled?: boolean
    updateTime?: number
    className: any
    default?: boolean
}> {
    @observable
    value: any
    oldValue: any

    onChange: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        if (!this.onChange) {
            this.onChange = _.debounce(this.props.onChange, this.props.updateTime || 500)
        }

        if (this.oldValue !== this.props.value) {
            this.value = this.props.value
            this.oldValue = this.props.value
        }
    }

    @action
    componentDidUpdate = () => {
        if (!this.onChange) {
            this.onChange = _.debounce(this.props.onChange, this.props.updateTime || 500)
        }

        if (this.oldValue !== this.props.value) {
            this.value = this.props.value
            this.oldValue = this.props.value
        }
    }

    render() {
        const options = []
        for (const i in this.props.data.options) {
            const option = this.props.data.options[i]

            if (typeof option === 'string') {
                options.push(
                    <option key={option} value={option}>
                        {option}
                    </option>,
                )
            } else if (this.props.data.key && this.props.data.value) {
                options.push(
                    <option key={option[this.props.data.key]} value={option[this.props.data.key]}>
                        {option[this.props.data.value]}
                    </option>,
                )
            }
        }

        return (
            <select
                id={this.props.id}
                className={this.props.className}
                value={this.value}
                onChange={action((e) => {
                    this.value = e.target.value
                    this.onChange && this.onChange(this.value)
                })}
                disabled={!!this.props.disabled}
            >
                {this.props.default && <option value="default">Select ...</option>}
                {options}
            </select>
        )
    }
}
