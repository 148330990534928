import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, observe, makeObservable, runInAction } from 'mobx'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import { EmailSetting } from 'shared/models-web'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { timeout } from '../../utils/utils'
import Popup from '../../components/Popup'
import { parseQueryString } from '../../lib/utils'
import prettyBytes from 'pretty-bytes'

import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'

@observer
class Synchronization extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void } }> {
    @observable
    isLoaded = 0
    @observable
    emailServer: EmailSetting = {
        server: '',
        port: '',
        username: '',
        password: '',
        display_name: '',
        email_address: '',
        replay_to_address: '',
        encryption: '',
    }
    @observable
    emailSettingsVerified = false
    @observable
    emailSettingsVerifing = false

    @observable
    showEmailVerifyModal = false
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false
    disposer?: any

    @observable
    dbx_connection: any = {}
    @observable
    window: Window | undefined | null
    @observable
    broadcastChannel: BroadcastChannel
    @observable
    handlingBacklink = true

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.broadcastChannel = new BroadcastChannel('dbx_authdone')
        this.init()
    }

    attachObserver = () => {
        this.disposer && this.disposer()
    }

    @action
    init = async () => {
        // window.addEventListener('beforeunload', this.beforeunload)
        this.broadcastChannel.onmessage = this.handleBackLink.bind(this)

        try {
            if (window.location.search.indexOf('code') !== -1) {
                const parsed = parseQueryString(window.location.search)

                await this.props.api.backup_api().dbx_authdone(parsed)
                window.location.search = ''
                this.broadcastChannel.postMessage('done')
                return
            }
            this.handlingBacklink = false

            const dbx_connection = (await this.props.api.backup_api().dbx_connection()).data
            const emailServer = (await this.props.api.settings_api().email_settings()).data

            runInAction(() => {
                this.dbx_connection = dbx_connection
                this.emailServer = emailServer

                //this fixes problems with clikcing links if we use loading
                this.isLoaded = 1
            })
            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
        this.attachObserver()

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    // beforeunload = (event: any) => {
    //     if (this.isFormDirty) {
    //         event.returnValue = ''
    //     }
    //     return event
    // }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)
        this.disposer && this.disposer()
    }

    @action
    handleBackLink = async () => {
        console.log('cloase child')
        this.window?.close()

        this.dbx_connection = (await this.props.api.backup_api().dbx_connection()).data
    }

    @action
    save = async (e?: any, redirect = false) => {
        this.saving = true

        try {
            await this.props.api.settings_api().email_settings_check({
                settings: this.emailServer,
                email: this.emailServer.email_address,
            })

            await this.props.api.settings_api().email_settings_save(this.emailServer)

            runInAction(() => {
                this.isFormDirty = false
                if (redirect === true) {
                    this.showSaveChangesWait = false
                    this.showSaveChanges = false
                    this.isRedirect = true
                }
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.saving = false
                this.showSaveChangesWait = false
                this.showSaveChanges = false
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    @action
    openModal = () => {
        this.showEmailVerifyModal = true
    }

    @action
    closeModal = () => {
        this.showEmailVerifyModal = false
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    checkSettings = async (showModal = false) => {
        this.emailSettingsVerifing = true
        try {
            await this.props.api.settings_api().email_settings_check({
                settings: this.emailServer,
                email: this.emailServer.email_address,
            })

            runInAction(() => {
                this.emailSettingsVerified = true
                this.showEmailVerifyModal = showModal
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.emailSettingsVerifing = false
            })
        }
    }

    @action
    sendTestEmail = async () => {
        try {
            this.closeModal()

            await this.props.api.settings_api().email_settings_test({
                settings: this.emailServer,
                email: this.emailServer.email_address,
                subject: 'Very test email',
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onDisconnect = async () => {
        await this.props.api.backup_api().dbx_disconnect()
        const dbx_connection = (await this.props.api.backup_api().dbx_connection()).data

        runInAction(() => {
            this.dbx_connection = dbx_connection
        })
    }

    @action
    onConnect = async () => {
        this.window = window.open(this.dbx_connection.oauth_url, '_tab')
        if (this.window) this.window.location = this.dbx_connection.oauth_url
    }

    @action
    onCloseChanges = () => {
        this.showSaveChanges = false
    }

    @action
    onCloseSaveChanges = async () => {
        this.showSaveChangesWait = true

        await this.save(undefined, true)
    }

    @action
    discardChanges = async () => {
        if (this.showSaveChangesWait) {
            return
        }

        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        runInAction(() => {
            this.isRedirect = true
        })
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        if (this.handlingBacklink) {
            return (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Email & Cloud</h4>
                            </div>
                        </div>
                    </div>
                    <div className="text-warning">
                        <p>Please wait for dropbox to finish connecting.</p>
                        <p>If this window doesn't close on its own, please close it and try again</p>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Email & Cloud</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <div id="email-sending">
                                    <h4 className="header-title">Email sending</h4>
                                    <p className="sub-header">
                                        Projects that have <strong>Send emails to participants</strong> option enabled, will use these settings for SMTP server.
                                    </p>
                                    <div className="form-row">
                                        <div className="col-md-8">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-mail-server'}>Server</label>
                                                <input id={'sync-mail-server'} type="text" value={this.emailServer.server} onChange={(e) => action((this.emailServer.server = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-mail-port'}>Port</label>
                                                <input id={'sync-mail-port'} type="text" value={this.emailServer.port} onChange={(e) => action((this.emailServer.port = e.target.value.replace(/\D/g, '')))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-email-username'}>Username</label>
                                                <input id={'sync-mail-username'} type="text" value={this.emailServer.username} onChange={(e) => action((this.emailServer.username = e.target.value))} className="form-control" autoComplete="off" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-email-password'}>Password</label>
                                                <input id={'sync-email-password'} value={this.emailServer.password} onChange={(e) => action((this.emailServer.password = e.target.value))} type="password" className="form-control" autoComplete="new-password" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label>Encryption</label>
                                            <div className="input-group mb-3">
                                                <select className="custom-select" disabled={this.saving} value={this.emailServer.encryption} onChange={action((e) => (this.emailServer.encryption = e.target.value))}>
                                                    <option value="none">None</option>
                                                    <option value="SSL/TLS">SSL/TLS</option>
                                                    <option value="STARTTLS">STARTTLS</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-email-sender-name'}>Sender display name</label>
                                                <input id={'sync-email-sender-name'} type="text" value={this.emailServer.display_name} onChange={(e) => action((this.emailServer.display_name = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-email-sender-email'}>Sender email address</label>
                                                <input id={'sync-email-sender-email'} value={this.emailServer.email_address} onChange={(e) => action((this.emailServer.email_address = e.target.value))} type="text" className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'sync-email-sender-replyto'}>Reply-to address</label>
                                                <input id={'sync-email-sender-replyto'} value={this.emailServer.replay_to_address} onChange={(e) => action((this.emailServer.replay_to_address = e.target.value))} type="text" className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                    </div>

                                    <h4 className="header-title">Sending addresses</h4>
                                    <p className="sub-header">Enteter non-default email from addresses</p>
                                    <div className="form-row">
                                        <div className="col-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'email_address_release'}>Release email address</label>
                                                <input id={'email_address_release'} type="text" value={this.emailServer.email_address_release} onChange={(e) => action((this.emailServer.email_address_release = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'name_address_release'}>Release display name</label>
                                                <input id={'name_address_release'} type="text" value={this.emailServer.name_address_release} onChange={(e) => action((this.emailServer.name_address_release = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'email_address_booking'}>Booking email address</label>
                                                <input id={'email_address_booking'} type="text" value={this.emailServer.email_address_booking} onChange={(e) => action((this.emailServer.email_address_booking = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'name_address_booking'}>Booking display name</label>
                                                <input id={'name_address_booking'} type="text" value={this.emailServer.name_address_booking} onChange={(e) => action((this.emailServer.name_address_booking = e.target.value))} className="form-control" disabled={this.saving} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-right">
                                        <div className="btn-group mb-3">
                                            <LaddaButton className="btn width-md waves-effect waves-light btn-secondary ladda-button" loading={this.emailSettingsVerifing} onClick={() => this.checkSettings(true)} data-color="green" data-style={ZOOM_OUT} disabled={this.saving}>
                                                Verify settings
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <h4 className="header-title">Cloud sync</h4>
                                {!this.dbx_connection.userAccountInfo && <p className="sub-header">Connect Photobooth to Dropbox, to enable the option of automatically backing up your release forms to the cloud.</p>}
                                {this.dbx_connection.userAccountInfo && (
                                    <>
                                        <p className="sub-header">This Photobooth is connected to Dropbox. All release forms, will be automatically backed up to this account.</p>

                                        <p className="font-weight-bold">
                                            Connected account: {this.dbx_connection.userAccountInfo.name.given_name} {this.dbx_connection.userAccountInfo.name.surname} ({this.dbx_connection.userAccountInfo.email})
                                        </p>
                                        <div className="row mb-3">
                                            <div className="col-md">
                                                <p className="mb-2 font-weight-bold">
                                                    Space used:{' '}
                                                    <span className="float-right">
                                                        {prettyBytes(this.dbx_connection.userSpaceInfo.used)} / {prettyBytes(this.dbx_connection.userSpaceInfo.allocation.allocated)}
                                                    </span>
                                                </p>
                                                <div className="progress mb-1" style={{ height: '7px' }}>
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: Math.floor((this.dbx_connection.userSpaceInfo.used / this.dbx_connection.userSpaceInfo.allocation.allocated) * 100) + '%',
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="text-right">
                                    {!this.dbx_connection.userAccountInfo && (
                                        <div className="btn-group mb-3">
                                            <button onClick={this.onConnect} className="btn width-md waves-effect waves-light btn-secondary ladda-button">
                                                Connect
                                            </button>
                                        </div>
                                    )}

                                    {this.dbx_connection.userAccountInfo && (
                                        <div className="btn-group mb-3">
                                            <button onClick={this.onDisconnect} type="button" className="btn width-md waves-effect waves-light btn-secondary ladda-button" data-style="zoom-out">
                                                Disconnect
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.save(undefined)} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>
                <Popup onClose={this.closeModal} title={'It works!'} show={this.showEmailVerifyModal}>
                    <div className="custom-modal-text">
                        <p>
                            Great success, email settings appear to be working. Would you like to send a test email to <strong>{this.emailServer.email_address}</strong>?
                        </p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.closeModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    No
                                </button>
                                <button type="button" onClick={this.sendTestEmail} className="btn width-md waves-effect waves-light btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" disabled={this.showSaveChangesWait} onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.showSaveChangesWait} onClick={() => this.onCloseSaveChanges()} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Synchronization)
