import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { TemplateManifest } from 'shared/template'
import Popup from '../../components/Popup'
import _ from 'lodash'
import MonacoEditor from 'react-monaco-editor'
import path from 'path'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import { timeout } from '../../utils/utils'
import Input from '../../components/ui-elements/Input'
import Select from '../../components/ui-elements/Select'
import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'
@observer
class Editor extends React.Component<{
    api: Api
    store: Store
    add?: boolean
    router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void }
}> {
    @observable
    isLoaded = false

    @observable
    template: TemplateManifest = {
        name: '',
        uuid: '',
        options: {} as any,
        title: '',
    }
    @observable
    file: string = ''
    @observable
    file_contants: string = ''
    @observable
    language: string = 'html'

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    showFileInfoModal = false

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false
    ext2lang = {
        '.css': 'css',
        '.scss': 'scss',
        '.js': 'javascript',
        '.html': 'html',
    }

    @observable
    filename = ''
    @observable
    fileext = '.html'
    @observable
    file_info: {
        section: string
        type: string
        file: string
    } = {
        section: '',
        file: '',
        type: '',
    }

    constructor(prop: any) {
        super(prop)
        window.document.body.className = ''

        makeObservable(this)

        this.load()
    }

    @action
    load = async () => {
        try {
            if (!this.props.router.params.templateId || !this.props.router.params.file) {
                throw new Error('Please privice templateId and file')
            }

            const template = (await this.props.api.editor_api().template(this.props.router.params.templateId)).data
            const file = Buffer.from(this.props.router.params.file, 'base64').toString()

            runInAction(() => {
                this.template = template
                this.file = file
            })

            if (this.props.add) {
                runInAction(() => {
                    this.showFileInfoModal = true
                })
            } else {
                runInAction(() => {
                    const ext = path.extname(this.file)
                    this.language = _.reduce(
                        this.ext2lang,
                        (res, v, k) => {
                            return ext === k ? v : res
                        },
                        this.language,
                    )
                })

                const file_data = await this.props.api.editor_api().template_file(this.props.router.params.templateId, this.file)

                runInAction(() => {
                    this.file_info = file_data.file
                    this.file_contants = Buffer.from(file_data.data).toString()
                })
            }

            runInAction(() => {
                this.isLoaded = true
                this.redirect = `/cloud/templates/${this.props.router.params.templateId}`
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)
        action(() => (this.showFileInfoModal = false))()
        window.document.body.className = 'boxed-layout'
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @observable
    onCloseFileInfoModal = async () => {
        this.showFileInfoModal = false

        await timeout(200)

        this.isRedirect = true
    }

    editorDidMount = (editor: any, monaco: any) => {
        console.log('editorDidMount', editor)
        editor.focus()
    }

    @action
    onChange = (newValue: any, e: any) => {
        // console.log('onChange', newValue, e)
        this.file_contants = newValue

        this.isFormDirty = true
    }

    @action
    save = async (e?: any, redirect = false) => {
        this.saving = true

        try {
            if (!this.props.router.params.templateId || !this.props.router.params.file) {
                throw new Error('Please privice templateId and file')
            }

            if (this.props.add) {
                await this.props.api.editor_api().template_new_file(this.props.router.params.templateId, this.file, this.filename + this.fileext, this.file_contants)
            } else {
                await this.props.api.editor_api().template_save_file(this.props.router.params.templateId, this.file, this.file_contants)
            }

            this.isFormDirty = false
            this.showSaveChanges = false
            this.isRedirect = true
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            this.saving = false
            this.showSaveChanges = false
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    @action
    onCloseChanges = () => {
        this.showSaveChanges = false
    }

    @action
    onCloseSaveChanges = async () => {
        this.showSaveChangesWait = true

        await this.save(undefined, true)
    }

    @action
    discardChanges = async () => {
        if (this.showSaveChangesWait) {
            return
        }

        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        this.isRedirect = true
    }

    @action
    useFileInfo = () => {
        if (this.filename !== '') {
            this.language = _.reduce(
                this.ext2lang,
                (res, v, k) => {
                    return this.fileext === k ? v : res
                },
                this.language,
            )
            this.showFileInfoModal = false
        }
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        const options = {
            selectOnLineNumbers: true,
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Editor: "{this.file}"</h4>
                            <p>{this.template.uuid}</p>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <a href={`/preview/?templateId=${this.template.uuid}&file=${this.file_info.section};${this.file_info.type};${this.file_info.file}`} className="btn btn-info btn-rounded mb-3 waves-effect waves-light" target="_newtab">
                            <i className="mdi mdi-cube-scan"> </i>Preview
                        </a>
                    </div>
                </div>

                <MonacoEditor language={this.language} theme="vs-light" value={this.file_contants} options={options} onChange={this.onChange} editorDidMount={this.editorDidMount} width="100%" height="900px" />

                <div className="row mb-0 mt-20">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.save(undefined)} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseFileInfoModal} title={'File info'} show={this.showFileInfoModal}>
                    <div className="custom-modal-text">
                        <div className="form">
                            <div className="form-row">
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'fileext'}></label>
                                        <Select
                                            id={'fileext'}
                                            value={this.fileext}
                                            onChange={action((value) => (this.fileext = value))}
                                            className="form-control"
                                            data={{
                                                options: _.map(this.ext2lang, (i, v) => {
                                                    return v
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'filename'}>Filename</label>
                                        <div className="input-group">
                                            <Input id={'filename'} type="text" value={this.filename} onChange={action((value) => (this.filename = value))} className="form-control" />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon1">
                                                    {this.fileext}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseFileInfoModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <button type="button" onClick={this.useFileInfo} className="btn width-md waves-effect waves-light btn-success">
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" disabled={this.showSaveChangesWait} onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.showSaveChangesWait} onClick={() => this.onCloseSaveChanges()} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Editor)
