import React, { useState } from 'react'
import { observer } from 'mobx-react'

export default observer(function HoverPreview(props: { previewEnabled: boolean; previewLink: string; imgWidth: string; children: any }) {
    const [data, setData] = useState({
        show: false,
    })

    return (
        <span
            onMouseEnter={() =>
                setData({
                    show: true,
                })
            }
            onMouseLeave={() =>
                setData({
                    show: false,
                })
            }
        >
            {props.children}
            {data.show && props.previewEnabled && (
                <div className="preview">
                    <img width={props.imgWidth} alt="" src={props.previewLink} />
                </div>
            )}
        </span>
    )
})
