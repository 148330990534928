import React from 'react'
import { useLocation, useNavigate, useParams, UNSAFE_NavigationContext, useBeforeUnload } from 'react-router-dom'

export function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation()
        let navigate = useNavigate()
        let params = useParams()
        let navigator = React.useContext(UNSAFE_NavigationContext).navigator

        let func: () => void | undefined
        useBeforeUnload(() => {
            func && func()
        })

        function beforeUnload(funcNew: () => void) {
            func = funcNew
        }

        return <Component {...props} router={{ location, navigate, params, navigator, beforeUnload }} />
    }

    return ComponentWithRouterProp
}
