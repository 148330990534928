import React from 'react'
import { observer } from 'mobx-react'
import { Language, Project } from 'shared/models-web'

import csc from 'country-state-city'
import _ from 'lodash'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { TemplateManifest } from 'shared/template'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import Api from 'shared/adminApi'
import { timeout } from '../../../utils/utils'
import Popup from '../../../components/Popup'
@observer
export default class ProjectMaps extends React.Component<{
    api: Api
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    page_options: any
}> {
    private mapStyles = [
        {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.locality',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#b3b3b1',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#93938f',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#b3b3b1',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            stylers: [
                {
                    color: '#a4a4a1',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
    ]
    @observable
    private mapTypeId = 'iop-map-style'
    @observable
    private markers: {
        [id: string]: {
            position: {
                lat: number
                lng: number
            }
            draggable: boolean
            enabled: boolean
        }
    } = {}
    @observable
    private map: any
    @observable
    isDraggingPin = false
    @observable
    temp_position?: { lat: number; lng: number }

    @observable
    show_location_picker = false
    @observable
    locations_to_select: { name: string; location: { lat: number; lng: number } }[] = []

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    onLoad = async (map: any) => {
        this.map = map
        const google = (window as any).google

        console.log('location', toJS(this.props.project.location))

        await timeout(200)

        map.mapTypes.set(
            this.mapTypeId,
            new google.maps.StyledMapType(this.mapStyles, {
                name: 'IOPMapStyles',
            }),
        )

        runInAction(() => {
            this.markers['location'] = {
                draggable: false,
                enabled: true,
                position: {
                    lat: Number.parseFloat(this.props.project.location.lat),
                    lng: Number.parseFloat(this.props.project.location.lon),
                },
            }

            this.markers['dragger'] = {
                draggable: true,
                enabled: false,
                position: {
                    lat: Number.parseFloat(this.props.project.location.lat),
                    lng: Number.parseFloat(this.props.project.location.lon),
                },
            }
        })
    }

    @action
    onScriptLoad = () => {}

    @action
    onUnmount = async (map: any) => {}

    @action
    onTryAddress = async () => {
        if (this.markers['dragger'].enabled) {
            return
        }

        const country = csc.getCountryById(this.props.project.location.country_id)

        let search = []

        if (this.props.project.location.address) {
            search.push(this.props.project.location.address)
        }

        if (this.props.project.location.city) {
            search.push(this.props.project.location.city)
        }

        if (country) {
            search.push(country.sortname)
        }

        const data = (await this.props.api.project_api().geocoding(search.join(', '))).data

        console.log(data)

        if (data.length === 1) {
            this.markers['location'].position = data[0].geometry.location
            this.props.project.location.lat = `${data[0].geometry.location.lat}`
            this.props.project.location.lon = `${data[0].geometry.location.lng}`
            this.props.project.location.gpsLock = true
        } else if (data.length > 1) {
            this.show_location_picker = true
            //show selector popup

            this.locations_to_select = []

            for (const location of data) {
                this.locations_to_select.push({
                    name: location.formatted_address,
                    location: location.geometry.location,
                })
            }
        } else {
            this.props.error('No location found')
        }
    }

    @action
    onUseAddress = (entry: { lat: number; lng: number }) => {
        this.markers['location'].position = entry
        this.props.project.location.lat = `${entry.lat}`
        this.props.project.location.lon = `${entry.lng}`
        this.props.project.location.gpsLock = true
        this.show_location_picker = false

        console.log('afdfsdfsdfsdfsdfsdf', this.show_location_picker)
    }

    @action
    onDropMarker = async () => {
        if (!this.map) {
            return
        }
        this.isDraggingPin = true

        this.markers['dragger'].enabled = true
        this.markers['location'].enabled = false
    }

    @action
    onSaveLocation = () => {
        this.isDraggingPin = false

        this.markers['dragger'].enabled = false
        this.markers['location'].enabled = true

        if (this.temp_position) {
            this.markers['location'].position = {
                lat: this.temp_position.lat,
                lng: this.temp_position.lng,
            }
            this.props.project.location.lat = `${this.temp_position.lat}`
            this.props.project.location.lon = `${this.temp_position.lng}`
            this.props.project.location.gpsLock = true
        }
    }

    @action
    onDragEnd = (e: any) => {
        this.temp_position = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        }
    }

    render() {
        if (!this.props.page_options || !this.props.page_options.options) {
            return <></>
        }

        const gOption = this.props.page_options.options['google_maps_api']

        if (!gOption) {
            return <></>
        }

        let center
        const markers = []

        for (const i in this.markers) {
            const marker = this.markers[i]

            if (!marker.enabled) {
                continue
            }
            // console.log(i, marker.position.lat, marker.position.lng, typeof marker.position.lat)
            markers.push(<Marker key={i} position={marker.position} draggable={marker.draggable} onDragEnd={this.onDragEnd}></Marker>)
            center = {
                lat: marker.position.lat,
                lng: marker.position.lng,
            }
        }

        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Google Maps</h3>

                            <div className="btn-group" style={{ marginBottom: '10px' }}>
                                <button className="btn btn-small btn-primary" onClick={this.onTryAddress}>
                                    Locate city
                                </button>
                                <button className="btn btn-small btn-info" onClick={this.onDropMarker}>
                                    Enable draggable marker
                                </button>
                                {this.isDraggingPin && (
                                    <button className="btn btn-small btn-secondary" onClick={this.onSaveLocation}>
                                        Save location
                                    </button>
                                )}
                            </div>

                            <LoadScript googleMapsApiKey={gOption.value} mapIds={[]} onLoad={this.onScriptLoad}>
                                <GoogleMap
                                    onLoad={this.onLoad}
                                    onUnmount={this.onUnmount}
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '400px',
                                    }}
                                    center={center}
                                    zoom={14}
                                    mapTypeId={this.mapTypeId}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControlOptions: {
                                            mapTypeIds: [],
                                        },
                                        mapTypeId: this.mapTypeId,
                                    }}
                                >
                                    {markers}
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                </div>

                <Popup
                    title={'Location picker'}
                    show={this.show_location_picker}
                    onClose={action(() => {
                        this.show_location_picker = false
                    })}
                    close={true}
                >
                    <div className="custom-modal-text">
                        <p>Select closer location from the list</p>

                        <ul>
                            {_.map(this.locations_to_select, (item) => {
                                return (
                                    <li key={item.name}>
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                console.log('here')

                                                this.onUseAddress(item.location)
                                            }}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Popup>
            </>
        )
    }
}
