import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { Language, Project } from 'shared/models-web'
import moment from 'moment'
import ct, { Timezone } from 'countries-and-timezones'
import csc from 'country-state-city'
import { Typeahead } from 'react-bootstrap-typeahead'
import _ from 'lodash'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Multiselect from '../../../components/ui-elements/MultiselectV2'
import Input from '../../../components/ui-elements/Input'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectGeneralSingle extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        const countries = []
        let country = null
        const temp = csc.getAllCountries()
        for (const i in temp) {
            const country_ = temp[i]

            if (country_.id === this.props.project.location.country_id) {
                country = temp[i]
            }

            countries.push({
                id: country_.id,
                label: country_.name,
            })
        }

        const states = []
        const states_temp = csc.getStatesOfCountry(this.props.project.location.country_id)
        for (const i in states_temp) {
            const t = states_temp[i]

            states.push({
                id: t.id,
                label: t.name,
            })
        }

        const cities = []
        const cities_temp = csc.getCitiesOfState(this.props.project.location.state_id)
        for (const i in cities_temp) {
            const t = cities_temp[i]

            cities.push({
                id: t.id,
                label: t.name,
            })
        }

        const timezones = country ? ct.getTimezonesForCountry(country.sortname) : ct.getAllTimezones()

        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <div>
                        <h4 className="header-title">General</h4>
                        <p className="sub-header">Let's start with some basic info about the project.</p>
                        <div className="form-row">
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label htmlFor={'project-name'}>Project title</label>
                                    <Input id={'project-name'} type="text" value={this.props.project.name} onChange={action((value) => (this.props.project.name = value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor={'startDate'}>Start date</label>
                                <div className="input-group mb-3">
                                    <Input updateTime={30} id={'startDate'} className="form-control" value={this.props.project.startDate} onChange={action((e) => (this.props.project.startDate = e))} type="date" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.startDate = moment().format('YYYY-MM-DD')))}>
                                            Today
                                        </button>
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.startDate = moment().add(1, 'day').format('YYYY-MM-DD')))}>
                                            Tomorrow
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor={'endDate'}>End date</label>
                                <div className="input-group mb-3">
                                    <Input updateTime={30} id={'endDate'} className="form-control" value={this.props.project.endDate} onChange={action((e) => (this.props.project.endDate = e))} type="date" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.endDate = moment().format('YYYY-MM-DD')))}>
                                            Today
                                        </button>
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.endDate = moment().add(1, 'day').format('YYYY-MM-DD')))}>
                                            Tomorrow
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                    <label htmlFor={'country'}>Country</label>
                                    <Typeahead
                                        id="country"
                                        options={countries}
                                        onChange={
                                            action((e: string[]) => {
                                                this.props.project.location.country_id = e[0] ? (e[0] as any).id : ''
                                                this.props.project.location.country = e[0] ? (e[0] as any).label : ''
                                            }) as any
                                        }
                                        selected={[
                                            _.find(countries, { id: this.props.project.location.country_id }) || {
                                                id: '',
                                                label: '',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="state">State</label>
                                    <Typeahead
                                        disabled={states.length === 0}
                                        id="state"
                                        selected={[
                                            _.find(states, { id: this.props.project.location.state_id }) || {
                                                id: '',
                                                label: '',
                                            },
                                        ]}
                                        options={states}
                                        onChange={
                                            action((e: string[]) => {
                                                this.props.project.location.state_id = e[0] ? (e[0] as any).id : ''
                                                this.props.project.location.state = e[0] ? (e[0] as any).label : ''
                                            }) as any
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group mb-3">
                                    <label htmlFor={'city'}>City</label>
                                    <Typeahead
                                        disabled={cities.length === 0}
                                        id="city"
                                        selected={[
                                            _.find(cities, { id: this.props.project.location.city_id }) || {
                                                id: '',
                                                label: '',
                                            },
                                        ]}
                                        options={cities}
                                        onChange={
                                            action((e: string[]) => {
                                                this.props.project.location.city_id = e[0] ? (e[0] as any).id : ''
                                                this.props.project.location.city = e[0] ? (e[0] as any).label : ''
                                            }) as any
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor={'timeZone'}>Timezone</label>
                                <div className="input-group mb-3">
                                    <Select
                                        default={true}
                                        id={'timeZone'}
                                        className="custom-select"
                                        value={this.props.project.location.timeZone}
                                        onChange={action((e) => (this.props.project.location.timeZone = e))}
                                        data={{
                                            options: _.map(timezones, (item: Timezone) => {
                                                return {
                                                    key: item.name,
                                                    value: `(${item.utcOffsetStr}) ${item.name}`,
                                                }
                                            }),
                                            key: 'key',
                                            value: 'value',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label htmlFor={'address'}>Address <span className="small">(optional)</span></label>
                                    <Input id={'address'} type="text" value={this.props.project.location.address} onChange={action((value) => (this.props.project.location.address = value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor={'languageId'}>Primary language</label>
                                <div className="input-group mb-3">
                                    <Select default={true} id={'languageId'} className="custom-select" value={this.props.project.boothConfig.languageId} onChange={action((e) => (this.props.project.boothConfig.languageId = e))} data={{ options: this.props.languages, key: 'uuid', value: 'name' }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor={'languages'}>Other available languages</label>
                                <div className="mb-3">
                                    <Multiselect id="languages" data={_.mapValues(_.keyBy(this.props.languages, 'uuid'), 'name')} value={this.props.project.boothConfig.languages} onChange={action((e) => console.log((this.props.project.boothConfig.languages = e)))} disable={[this.props.project.boothConfig.languageId]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
