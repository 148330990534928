import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, observe, makeObservable, runInAction } from 'mobx'
import { Booth, OpenVPNClient } from 'shared/models-web'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { timeout, generateRange } from '../../utils/utils'
//@ts-ignore
import LaddaButton, { ZOOM_OUT } from 'react-ladda'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'

import Popup from '../../components/Popup'
import slugify from 'slugify'
import SelectBox from '../../components/ui-elements/SelectBox'
import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'

@observer
class BoothEdit extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void } }> {
    emailRegEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)

    @observable
    isLoaded = 0

    @observable
    boothInfo: { booth: Booth; connection?: OpenVPNClient; socketio: boolean } = {
        booth: {
            cert: '',
            hwId: '',
            id: '',
            key: '',
            location: '',
            name: '',
            psk: '',
            removed: false,
            uuid: '',
        },
        socketio: false,
        connection: {
            date: '',
            id: '',
            ip: '',
            remote: '',
        },
    }

    //save state and redirect control
    @observable
    isRedirect = false
    @observable
    redirect = '/booths'
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    @observable
    showSaveChangesWait = false
    @observable
    saving = false

    //erro model controls
    @observable
    showErrorModal = false
    @observable
    errorModalText: any
    disposer?: any

    @observable
    boothIdData: string[] = []

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    attachObserver = () => {
        this.disposer && this.disposer()

        this.disposer = observe(
            this.boothInfo,
            action((change: any) => {
                this.isFormDirty = true
            }) as any,
        )
    }

    @action
    init = async () => {
        this.boothIdData = generateRange()

        // window.addEventListener('beforeunload', this.beforeunload)

        try {
            if (this.props.router.params.uuid) {
                const boothInfo = (await this.props.api.booth_api().load(this.props.router.params.uuid)).data

                runInAction(() => {
                    this.boothInfo = boothInfo
                })
            }
            runInAction(() => {
                //this fixes problems with clikcing links if we use loading
                this.isLoaded = 1
            })
            await timeout(300)
            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }

        this.attachObserver()

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)
        this.disposer && this.disposer()
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onCloseChanges = () => {
        this.showSaveChanges = false
    }

    @action
    onCloseSaveChanges = async () => {
        this.showSaveChangesWait = true

        await this.save('/booths')
    }

    @action
    discardChanges = async () => {
        if (this.showSaveChangesWait) {
            return
        }

        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        runInAction(() => {
            this.isRedirect = true
        })
    }

    @action
    save = async (redirect?: string) => {
        this.saving = true

        try {
            if (this.props.router.params.boothId) {
                await this.props.api.booth_api().edit(this.boothInfo.booth)
            } else {
                await this.props.api.booth_api().add(this.boothInfo.booth)
            }

            runInAction(() => {
                this.isFormDirty = false
                if (redirect) {
                    this.redirect = redirect
                    this.isRedirect = true
                }
                this.isLoaded = 0;
            });

            if(!this.isRedirect) {
                await this.init();
            }
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.saving = false
                this.showSaveChangesWait = false
                this.showSaveChanges = false
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.isRedirect = true
    }

    render() {
        if (this.isRedirect) {
            return <Navigate to={this.redirect} />
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Booth Settings</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card ' + (this.isLoaded === 0 ? 'data-waiting ' : this.isLoaded === 1 ? 'data-loaded ' : '')}>
                            <div className="card-body pb-0">
                                <form id="email-sending">
                                    <h4 className="header-title">Booth</h4>
                                    <p className="sub-header">Set the booth's name here. It will be used internally to identify which booth the photos came from.</p>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'booth-name'}>Name</label>
                                                <input
                                                    id={'booth-name'}
                                                    disabled={this.saving}
                                                    type="text"
                                                    value={this.boothInfo.booth.name}
                                                    onChange={action((e) => {
                                                        this.boothInfo.booth.name = e.target.value
                                                        this.boothInfo.booth.hwId = slugify(e.target.value)
                                                    })}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'booth-name'}>HWId</label>
                                                <input id={'booth-name'} type="text" value={this.boothInfo.booth.hwId} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'booth-location'}>Location</label>
                                                <input id={'booth-location'} disabled={this.saving} type="text" value={this.boothInfo.booth.location} onChange={action((e) => (this.boothInfo.booth.location = e.target.value))} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group mb-3">
                                                <label htmlFor={'booth-name'}>Booth ID</label>
                                                <SelectBox value={this.boothInfo.booth.id} data={this.boothIdData} onChange={action((value: string) => (this.boothInfo.booth.id = value))} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-0">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.saving} onClick={() => this.save(`/booths/${this.boothInfo.booth.uuid}/view`)} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" disabled={this.showSaveChangesWait} onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <LaddaButton className="btn width-md waves-effect waves-light btn-success" loading={this.showSaveChangesWait} onClick={() => this.onCloseSaveChanges()} data-color="green" data-style={ZOOM_OUT}>
                                    Save
                                </LaddaButton>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(BoothEdit)
