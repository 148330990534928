import React from 'react'
import { observer } from 'mobx-react'
import { action, toJS } from 'mobx'
import { Language, Project, Booth } from 'shared/models-web'
import _ from 'lodash'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectBooth extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    booths: { booth: Booth }[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        const boohts = [
            {
                key: '-1',
                value: 'Select .. ',
            },
        ].concat(
            _.map(this.props.booths, (item) => {
                console.log(toJS(item))
                return {
                    key: item.booth.uuid,
                    value: item.booth.name,
                }
            }),
        )

        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <div>
                        <h4 className="header-title">Photobooth</h4>
                        <p className="sub-header"> Select booth that will be performing the project (this selection effects sync settings) </p>
                        <div className="form-row">
                            <label htmlFor={'type'}>Booth</label>
                            <div className="input-group mb-3">
                                <Select
                                    id={'type'}
                                    className="custom-select"
                                    value={this.props.project.boothId}
                                    onChange={action((e) => (this.props.project.boothId = e))}
                                    data={{
                                        options: boohts,
                                        key: 'key',
                                        value: 'value',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
