import React from 'react'
import { observer } from 'mobx-react'
import { Booth, OpenVPNClient } from 'shared/models-web'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'

@observer
export default class BoothEntry extends React.Component<{
    booth: Booth
    connection?: OpenVPNClient
    socketio: boolean
    isLoaded: number
}> {
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className={'card-box project-box widget-inline ' + (this.props.isLoaded === 0 ? 'data-waiting ' : this.props.isLoaded === 1 ? 'data-loaded ' : '')}>
                        <Dropdown className={' float-right card-top-buttons-align ml-2'}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop arrow-none">
                                <i className="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                <Link to={'/booths/' + this.props.booth.uuid + '/view'} className="dropdown-item">
                                    View booth
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link to={'/booths/' + this.props.booth.uuid + '/edit'} className="dropdown-item">
                                    Edit booth
                                </Link>
                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item" onClick={() => console.log('SSH')}>
                                    SSH
                                </button>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="clearfix">
                            <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                <span>
                                    <div className={'badge bg-soft-info text-info'}>{this.props.booth.hwId}</div>&nbsp;
                                    {this.props.connection && (
                                        <>
                                            <div className={'badge bg-soft-success text-success'}>VPN</div>&nbsp;
                                        </>
                                    )}
                                    {!this.props.connection && (
                                        <>
                                            <div className={'badge bg-soft-warning text-warning'}>VPN</div>&nbsp;
                                        </>
                                    )}
                                    {this.props.socketio && (
                                        <>
                                            <div className={'badge bg-soft-success text-success'}>Socket.IO</div>&nbsp;
                                        </>
                                    )}
                                    {!this.props.socketio && (
                                        <>
                                            <div className={'badge bg-soft-warning text-warning'}>Socket.IO</div>&nbsp;
                                        </>
                                    )}
                                </span>
                            </div>

                            <h4 className="mt-0 mb-1">
                                <Link to={`/booths/${this.props.booth.uuid}/view`} className="text-light">
                                    {this.props.booth.name}
                                </Link>
                            </h4>
                        </div>

                        <div className="d-sm-none card-top-buttons-align">
                            <span>
                                <div className={'badge bg-soft-info text-info'}>{this.props.booth.hwId}</div>&nbsp;
                                {this.props.connection && (
                                    <>
                                        <div className={'badge bg-soft-success text-success'}>VPN</div>&nbsp;
                                    </>
                                )}
                                {!this.props.connection && (
                                    <>
                                        <div className={'badge bg-soft-warning text-warning'}>VPN</div>&nbsp;
                                    </>
                                )}
                                {this.props.socketio && (
                                    <>
                                        <div className={'badge bg-soft-success text-success'}>Socket.IO</div>&nbsp;
                                    </>
                                )}
                                {!this.props.socketio && (
                                    <>
                                        <div className={'badge bg-soft-warning text-warning'}>Socket.IO</div>&nbsp;
                                    </>
                                )}
                            </span>
                        </div>

                        <p className="text-muted text-uppercase mb-2">
                            {this.props.connection && (
                                <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                    <i className="mdi mdi-network-outline"></i> <small>{this.props.connection.ip}</small>
                                </span>
                            )}
                            <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                <i className="mdi mdi-crosshairs-gps"></i> <small>{this.props.booth.location}</small>
                            </span>
                            <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                <i className="mdi mdi-calendar"></i> <small>{moment((this.props.booth as any).createdAt).format('MMMM DD, YYYY')}</small>
                            </span>
                        </p>
                        <p className="text-muted text-uppercase mb-2">
                            <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                <i className="mdi mdi-view-grid"></i> <small>{this.props.booth.uuid}</small>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
