import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'

import { action, makeObservable, observable, runInAction } from 'mobx'
import { BookedSessions, Project, ProjectBoogkingSlot, User, UserMeta } from 'shared/models-web'
import Popup from '../../components/Popup'
import _ from 'lodash'
import moment from 'moment'
import { Dropdown } from 'react-bootstrap'
import { withRouter } from '../../utils/react-utils'
import { NavigateFunction, Params } from 'react-router-dom'

@observer
class UserView extends React.Component<{ api: Api; store: Store;  router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    userInfo: { user: User; userMeta: { [i: string]: UserMeta }; projects: Project[]; numbers: UserMeta; bookedSessions: (BookedSessions & ProjectBoogkingSlot & Project)[] } = {
        user: {} as any,
        userMeta: {},
        projects: [],
        numbers: {},
        bookedSessions: [],
    }

    @observable
    pictureModal = false
    @observable
    pictureUrl: string = ''

    @observable
    confirmUserDeleteShow = false
    @observable
    userToDelete?: string

    @observable
    confirmBookingDeleteShow = false
    @observable
    bookedSessionToDelete?: string

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.loadUser()
    }

    @action
    loadUser = async () => {
        try {
            if(!this.props.router.params.userId) {
                throw new Error("Please provice userId");
            }

            const userInfo = (await this.props.api.users_api().user(this.props.router.params.userId)).data

            runInAction(() => {
                this.userInfo = userInfo;
            });
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    viewReleaseForm = () => {
        window.open(`/images/${this.userInfo.user.uuid}-releaseform.pdf`)
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    openPictureModal = (url: string) => {
        this.pictureUrl = url
        this.pictureModal = true
    }

    @action
    closePictureModal = () => {
        this.pictureModal = false
    }

    @action
    deleteUser = () => {
        this.userToDelete = this.userInfo.user.uuid
        this.confirmUserDeleteShow = true
    }

    @action
    confirmDelete = async () => {
        this.confirmUserDeleteShow = false
        if (!this.userToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        try {
            // delete user
            this.props.api.project_api().delete_user(this.userToDelete)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseConfirmUserDeleteModal = () => {
        this.confirmUserDeleteShow = false
    }

    @action
    onCloseConfirmBookingDeleteModal = () => {
        this.confirmBookingDeleteShow = false
    }

    @action
    resendEmail = async (bookedSessionId: string) => {
        try {
            await this.props.api.booking_api().resend_email(bookedSessionId)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    removeBooking = (bookingSessionId: string) => {
        this.bookedSessionToDelete = bookingSessionId
        this.confirmBookingDeleteShow = true
    }

    @action
    removeBookingConfirm = async () => {
        this.confirmBookingDeleteShow = false
        if (!this.bookedSessionToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        try {
            // delete user
            this.props.api.booking_api().remove_session(this.bookedSessionToDelete)

            await this.loadUser()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">User</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="card-box text-center">
                            <h4 className="mb-0">
                                {this.userInfo.user.first_name} {this.userInfo.user.last_name}
                            </h4>
                            <p className="text-muted">{this.userInfo.user.email}</p>

                            <p className="mb-1">
                                <span className="pr-2 text-nowrap mb-2 d-inline-block">
                                    <i className="mdi mdi-camera text-muted"></i>
                                    <b>{this.userInfo.numbers.images}</b> Photos
                                </span>
                                <span className="text-nowrap mb-2 d-inline-block">
                                    <i className="mdi mdi-email text-muted"></i>
                                    <b>{this.userInfo.numbers.emails}</b> Emails
                                </span>
                            </p>

                            <button type="button" className="btn btn-primary btn-xs waves-effect mb-2 waves-light mr-1" onClick={this.viewReleaseForm}>
                                View Release form
                            </button>
                            <button type="button" className="btn btn-danger btn-xs waves-effect mb-2 waves-light" onClick={this.deleteUser}>
                                Delete user
                            </button>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="card-box">
                            <h2>Images</h2>
                            {this.userInfo.projects &&
                                _.map(this.userInfo.projects, (project, index) => (
                                    <div className={'border border-secondary p-2 ' + (index === this.userInfo.projects.length - 1 ? '' : 'mb-3')} key={project.uuid}>
                                        <div className="media">
                                            <div className="media-body">
                                                {/* <h5 className="m-0 cursor-pointer" onClick={() => this.this.props.router.navigate(`/projects/${project.uuid}/view`)}>
                                                    {project.name}
                                                </h5> */}
                                                <p className="text-muted">
                                                    <i className="mdi mdi-calendar"></i> <small>{moment(project.startDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</small>
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            className="d-block waves-effect waves-light col-md-4 offset-4 mt-3"
                                            onClick={() => {
                                                const userMeta = this.userInfo.userMeta[project.uuid]
                                                const url = userMeta.preview_url === undefined ? '' : userMeta.preview_url

                                                this.openPictureModal(url)
                                            }}
                                        >
                                            <img alt="preview" className="img-fluid" width={200} src={this.userInfo.userMeta[project.uuid].image_url} />
                                        </div>
                                    </div>
                                ))}
                        </div>

                        <div className="card-box">
                            <h2>Booked sessions</h2>
                            {this.userInfo.bookedSessions &&
                                _.map(this.userInfo.bookedSessions, (session, index) => (
                                    <div className={'border border-secondary p-2 ' + (index === this.userInfo.bookedSessions.length - 1 ? '' : 'mb-3')} key={session.uuid}>
                                        <div className="media">
                                            <div className="media-body">
                                                <h5 className="m-0">{session.name}</h5>
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <h4 className="p-0 m-0">{moment(`${session.date} ${session.hour}:00`, 'YYYY-MM-DD HH:mm').format('YYYY, MMM DD HH:mm')}</h4>
                                            <p>{session.uuid}</p>
                                        </div>

                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h4 className="p-0 m-0"></h4>

                                            <Dropdown className={'d-flex align-items-center'}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop">
                                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                                    <Dropdown.Item onSelect={(e: any) => this.removeBooking(session.uuid)}>Remove</Dropdown.Item>
                                                    <div className="dropdown-divider"></div>
                                                    <Dropdown.Item onSelect={(e: any) => this.resendEmail(session.uuid)}>Resend booking email</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                <Popup
                    onClose={this.closePictureModal}
                    className={'modal-photo p-0'}
                    show={this.pictureModal}
                >
                    <div className="modal-body">
                        <img alt="preview" src={this.pictureUrl} />
                    </div>
                </Popup>
                <Popup onClose={this.onCloseErrorModal} className={'col-md-8 col-xl-6 p-0'} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseConfirmUserDeleteModal} className={'col-md-8 col-xl-6 p-0'} type="" title={'Delete user?'} show={this.confirmUserDeleteShow}>
                    <div className="custom-modal-text">
                        <p>You are about to delete user's profile and portraits!</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmUserDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    No
                                </button>
                                <button type="button" onClick={this.confirmDelete} className="btn width-md waves-effect waves-light btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseConfirmBookingDeleteModal} className={'col-md-8 col-xl-6 p-0'} type="" title={'Remove booked session'} show={this.confirmBookingDeleteShow}>
                    <div className="custom-modal-text">
                        <p>You are about to remove booked session. Person will be notifyed that booked session was cancled.</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmBookingDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.removeBookingConfirm} className="btn width-md waves-effect waves-light btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(UserView);