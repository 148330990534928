import React from 'react'
import { observer } from 'mobx-react'
import { Language, Project, ProjectStats } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { Link, NavigateFunction } from 'react-router-dom'
import { TemplateManifest } from 'shared/template'
import { Button } from 'react-bootstrap'

@observer
export default class ProjectLocations extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    subProjects: { project: Project; stats: ProjectStats }[]
    navigate: NavigateFunction
    import: (uuid: string) => void
}> {
    addSubProject = () => {
        console.log('addSubProject')

        this.props.navigate(`/projects/${this.props.project.uuid}/new`)
    }

    render() {
        const subProjects = []

        for (const subPro of this.props.subProjects) {
            subProjects.push(
                <div key={subPro.project.uuid} className="pb-10 row">
                    <div className="col bold">
                        {subPro.project.name} - {subPro.project.location.city}, {subPro.project.location.country}
                    </div>
                    <div className="col text-center">
                        {subPro.project.status}
                    </div>
                    <div className="col text-center">
                        <span className="badge badge-pink">Images: {subPro.stats.images}</span>&nbsp;
                        <span className="badge badge-blue">Prints: {subPro.stats.prints}</span>&nbsp;
                        <span className="badge badge-dark">Emails: {subPro.stats.emails}</span>
                    </div>
                    <div className="col-md-4 text-right">
                        <div className="btn-group">
                            <Link to={`/projects/${this.props.project.uuid}/edit/${subPro.project.uuid}`} className="btn btn-small btn-success">
                                Edit
                            </Link>
                            <Link to={`/projects/${subPro.project.uuid}/booking`} className="btn btn-small btn-info">
                                View bookings
                            </Link>
                            <Link to={`/projects/${subPro.project.uuid}/view`} className="btn btn-small btn-primary">
                                View users
                            </Link>
                            <Button size='sm' variant='warning' onClick={() => this.props.import(subPro.project.uuid)}>Import</Button>
                        </div>
                    </div>
                </div>,
            )
        }

        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <h4 className="header-title">Project locations</h4>
                    <p className="sub-header"> List of project locations </p>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="pb-10 row">
                                <div className="col text-center">Project</div>
                                <div className="col text-center">Status</div>
                                <div className="col text-center">Stats</div>
                                <div className="col-md-4 text-center">Actions</div>
                            </div>
                            {subProjects}
                        </div>
                    </div>

                    <div className="text-right">
                        <div className="btn-group mb-3">
                            <button type="button" onClick={this.addSubProject} disabled={this.props.isFormDirty} className={'btn width-md waves-effect waves-light btn-primary '} style={{ cursor: this.props.isFormDirty ? 'not-allowed' : 'pointer' }}>
                                Add location
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
