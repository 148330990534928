import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { Link } from 'react-router-dom'
import { observable, action, toJS, makeObservable, runInAction } from 'mobx'
import { Page, PagesOptions } from 'shared/models-web'

import Popup from '../../components/Popup'
import moment from 'moment'
import Input from '../../components/ui-elements/Input'
import _ from 'lodash'
import { PagesOptionsModelEntry } from 'shared/template'
import { withRouter } from '../../utils/react-utils'

@observer
class Pages extends React.Component<{ api: Api; store: Store; history: History }> {
    @observable
    isLoading = false
    @observable
    isLoaded = false

    @observable
    pages: { [id: string]: Page } = {}
    @observable
    options: { [id: string]: PagesOptions } = {}
    optionsModel: { [id: string]: PagesOptionsModelEntry } = {}

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.loadPages()
    }

    @action
    loadPages = async () => {
        try {
            this.isLoading = true

            const data = (await this.props.api.pages_api().list()).data

            runInAction(() => {
                const list = data.pages
                for (const page of list) {
                    this.pages[page.type] = page
                }
                this.options = data.options
                this.optionsModel = data.optionsModel

                console.log(toJS(this.options), toJS(this.optionsModel))

                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    saveOptions = async () => {
        try {
            await this.props.api.pages_api().optionsSave(this.options)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        //START template options
        const options = []

        for (const b in this.optionsModel) {
            options.push(
                <div className="form-row" key={b}>
                    <div className="col-lg-12">
                        <div className="form-group mb-3">
                            <label htmlFor={b}>{_.startCase(this.optionsModel[b].name)}</label>
                            <Input
                                id={b}
                                type="text"
                                value={this.options[b] ? this.options[b].value : ''}
                                onChange={action((value) => {
                                    if (!this.options[b]) {
                                        this.options[b] = {
                                            id: undefined as any,
                                            name: b,
                                            value,
                                        }
                                        return
                                    }

                                    this.options[b].value = value
                                })}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>,
            )
        }
        //END template options

        return (
            <div className={'projects'}>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Pages</h4>
                        </div>
                    </div>
                </div>

                {this.pages['landingpage'] && (
                    <div className="row">
                        <div className="col-12">
                            <div className={'card-box project-box widget-inline ' + (this.isLoaded === false ? (this.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                                <div className="clearfix">
                                    <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                        <span>
                                            <div className={'badge bg-soft-info text-info'}>Landing page</div>&nbsp;
                                        </span>
                                    </div>

                                    <h4 className="mt-0 mb-1">
                                        <Link to={'/pages/landing'} className="text-light">
                                            {this.pages['landingpage'].title}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="d-sm-none card-top-buttons-align">
                                    <span>
                                        <div className={'badge bg-soft-info text-info'}>Landing page</div>&nbsp;
                                    </span>
                                </div>

                                <p className="text-muted text-uppercase mb-2">
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-calendar"></i> <small>{moment((this.pages['landingpage'] as any).createdAt).format('MMMM DD, YYYY')}</small>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {this.pages['legalpage'] && (
                    <div className="row">
                        <div className="col-12">
                            <div className={'card-box project-box widget-inline ' + (this.isLoaded === false ? (this.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                                <div className="clearfix">
                                    <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                        <span>
                                            <div className={'badge bg-soft-info text-info'}>Legal page</div>&nbsp;
                                        </span>
                                    </div>

                                    <h4 className="mt-0 mb-1">
                                        <Link to={'/pages/legal'} className="text-light">
                                            {this.pages['legalpage'].title}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="d-sm-none card-top-buttons-align">
                                    <span>
                                        <div className={'badge bg-soft-info text-info'}>Legal page</div>&nbsp;
                                    </span>
                                </div>

                                <p className="text-muted text-uppercase mb-2">
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-calendar"></i> <small>{moment((this.pages['legalpage'] as any).createdAt).format('MMMM DD, YYYY')}</small>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {this.pages['aboutpage'] && (
                    <div className="row">
                        <div className="col-12">
                            <div className={'card-box project-box widget-inline ' + (this.isLoaded === false ? (this.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                                <div className="clearfix">
                                    <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                        <span>
                                            <div className={'badge bg-soft-info text-info'}>About page</div>&nbsp;
                                        </span>
                                    </div>

                                    <h4 className="mt-0 mb-1">
                                        <Link to={'/pages/about'} className="text-light">
                                            {this.pages['aboutpage'].title}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="d-sm-none card-top-buttons-align">
                                    <span>
                                        <div className={'badge bg-soft-info text-info'}>About page</div>&nbsp;
                                    </span>
                                </div>

                                <p className="text-muted text-uppercase mb-2">
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-calendar"></i> <small>{moment((this.pages['aboutpage'] as any).createdAt).format('MMMM DD, YYYY')}</small>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                {this.pages['release_forms'] && (
                    <div className="row">
                        <div className="col-12">
                            <div className={'card-box project-box widget-inline ' + (this.isLoaded === false ? (this.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                                <div className="clearfix">
                                    <div className="d-none d-sm-block float-right card-top-buttons-align ml-2">
                                        <span>
                                            <div className={'badge bg-soft-info text-info'}>Release form</div>&nbsp;
                                        </span>
                                    </div>

                                    <h4 className="mt-0 mb-1">
                                        <Link to={'/pages/release_forms'} className="text-light">
                                            {this.pages['release_forms'].title}
                                        </Link>
                                    </h4>
                                </div>

                                <div className="d-sm-none card-top-buttons-align">
                                    <span>
                                        <div className={'badge bg-soft-info text-info'}>Release form</div>&nbsp;
                                    </span>
                                </div>

                                <p className="text-muted text-uppercase mb-2">
                                    <span className="pr-2 text-nowrap mb-0 d-inline-block">
                                        <i className="mdi mdi-calendar"></i> <small>{moment((this.pages['release_forms'] as any).createdAt).format('MMMM DD, YYYY')}</small>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Options</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={'card-box project-box widget-inline'}>
                            {options}
                            <div className="text-right">
                                <div className="btn-group">
                                    <button onClick={this.saveOptions} className="btn width-md waves-effect waves-light btn-secondary ladda-button">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} className={'col-md-8 col-xl-6 p-0'} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(Pages)
