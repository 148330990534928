import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function Popup(props: { show: boolean; title?: string; onClose: () => void; children?: React.ReactNode; className?: string; disabled?: boolean; type?: string; close?: boolean; size?: 'sm' | 'lg' | 'xl' }) {
    return (
        <>
            <Modal show={props.show} className={'modal-demo ' + props.className} onHide={props.onClose} size={props.size} backdrop={props.close ? true : false}>
                {props.close && (
                    <button type="button" className="close" onClick={props.onClose}>
                        <span>&times;</span>
                        <span className="sr-only">Close</span>
                    </button>
                )}
                {props.title && <h4 className={'custom-modal-title ' + props.type}>{props.title}</h4>}
                {props.children}
            </Modal>
        </>
    )
}
