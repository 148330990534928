import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import { observer } from 'mobx-react'
import { User, UserMeta } from 'shared/models-web'
import Api from 'shared/adminApi'
import Store from '../lib/store'
import imagePlaceholder from '../assets/images/image-placeholder.png'
import _ from 'lodash'
import FloatingCheckbox from './table/FloatingCheckbox'

@observer
export default class PicturePreview extends React.Component<{
    openModalPhoto?: (url: string) => void
    user: User
    userMeta: UserMeta
    deleteUser: (userId: string) => void
    viewReleaseForm: (userId: string) => void
    resendEmail: (userId: string) => void
    toggleHidden: (userId: string) => void
    openUser: (userId: string) => void
    checked: (id: string) => boolean
    onChecked: (e: any) => void
}> {
    render() {
        return (
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div className="gal-box">
                    <div className="d-block waves-effect waves-light">
                        <FloatingCheckbox id={this.props.user.uuid} checked={this.props.checked} onChange={this.props.onChecked} />
                        {this.props.userMeta.image_url && <img alt="preview" className="img-fluid" onClick={() => this.props.openModalPhoto && this.props.userMeta.preview_url && this.props.openModalPhoto(this.props.userMeta.preview_url)} src={this.props.userMeta.image_url} />}
                        {!this.props.userMeta.image_url && <img alt="preview" className="img-fluid" onClick={() => this.props.openModalPhoto && this.props.userMeta.preview_url && this.props.openModalPhoto(this.props.userMeta.preview_url)} src={imagePlaceholder} />}
                    </div>
                    <div className="gall-info">
                        <h6 className="mt-0 text-truncate" style={{ fontSize: '0.875rem' }}>
                            {this.props.user.name}
                        </h6>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <h4 className="p-0 m-0">
                                <i className={'mdi mdi-printer mr-1 ' + (this.props.user.printed ? 'text-success' : 'text-secondary')} />
                                <i className={'mdi mdi-email mr-1 ' + (this.props.user.emails.length > 0 ? 'text-success' : 'text-secondary')} />
                                <i className={'mdi mdi-web mr-1 ' + (!this.props.userMeta.hidden ? 'text-success' : 'text-secondary')} />
                                <i className={'mdi mdi-cloud-upload mr-1 ' + (this.props.user.synced_backup ? 'text-success' : 'text-secondary')} />
                            </h4>

                            <Dropdown className={'d-flex align-items-center'}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop">
                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                    <Dropdown.Item onClick={(e: any) => this.props.openUser(this.props.user.uuid)}>View user info</Dropdown.Item>
                                    <Dropdown.Item onClick={(e: any) => this.props.viewReleaseForm(this.props.user.uuid)}>View release form</Dropdown.Item>
                                    <Dropdown.Item onClick={(e: any) => this.props.resendEmail(this.props.user.uuid)}>Resend email</Dropdown.Item>
                                    {!this.props.userMeta.hidden && <Dropdown.Item onClick={(e: any) => this.props.toggleHidden(this.props.user.uuid)}>Hide image</Dropdown.Item>}
                                    {this.props.userMeta.hidden && <Dropdown.Item onClick={(e: any) => this.props.toggleHidden(this.props.user.uuid)}>Show image</Dropdown.Item>}
                                    <div className="dropdown-divider"></div>
                                    <Dropdown.Item onClick={(e: any) => this.props.deleteUser(this.props.user.uuid)}>Delete photo</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
