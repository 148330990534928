import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Language } from 'shared/models-web'

import Popup from '../../components/Popup'
import Multiselect from '../../components/ui-elements/MultiselectV2'
import _ from 'lodash'
import countiresList from 'countries-list'
import { Typeahead } from 'react-bootstrap-typeahead'
import { indexFromSearch } from '../../lib/utils'
import { withRouter } from '../../utils/react-utils'

@observer
class Languages extends React.Component<{ api: Api; store: Store; history: History }> {
    @observable
    isLoaded = false
    @observable
    filter = 'active'
    @observable
    data: {
        list: Language[]
        options: {
            language_keys: string[]
            email_keys: string[]
        }
        languages: { [id: string]: { [id: string]: string } }
    } = {
        list: [],
        options: {
            email_keys: [],
            language_keys: [],
        },
        languages: {},
    }
    @observable
    selected_languges: string[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    showNewLanguage = false
    @observable
    newLanguageError = false
    @observable
    newLanguageErrorMsg = ''
    @observable
    newLangauge: Language = {
        uuid: '',
        flag: '',
        name: '',
        short: '',
    }

    @observable
    showEditTranslation = false
    @observable
    translation: { key: string; value: string; languageId: string; language: string; type: 'email' | 'language' } = {
        languageId: '',
        language: '',
        type: 'email',
        key: '',
        value: '',
    }

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.loadLanguages()
    }

    @action
    loadLanguages = async () => {
        try {
            const data = (await this.props.api.editor_api().languages()).data

            runInAction(() => {
                this.data = data
                this.selected_languges = _.map(this.data.list, 'uuid')

                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onNewLangauge = () => {
        this.showNewLanguage = true
    }

    @action
    onNewLangaugeClose = () => {
        this.showNewLanguage = false
    }

    @action
    onNewLangaugeSave = async () => {
        try {
            //save new langauge
            await this.props.api.editor_api().add_language(this.newLangauge)

            await this.loadLanguages()
            runInAction(() => {
                this.showNewLanguage = false
            })
        } catch (eRaw) {
            const e = eRaw as any
            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.response.data) {
                    console.log('here', e.response.data)
                    this.newLanguageError = true
                    this.newLanguageErrorMsg = e.response.data.errMsg
                } else {
                    this.newLanguageError = true
                    this.newLanguageErrorMsg = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onEditTranslation = (lang: Language, key: string, value: string, type: 'email' | 'language') => {
        console.log('edit', type, lang.uuid, key, value)

        if (!value) {
            value = ''
        }

        this.translation = {
            key,
            languageId: lang.uuid,
            language: lang.name,
            type,
            value,
        }

        this.showEditTranslation = true
    }

    @action
    onEditTranslationClose = () => {
        this.showEditTranslation = false
    }

    @action
    onUpdateTranslation = async () => {
        try {
            //save new langauge
            await this.props.api.editor_api().update_translation(this.translation)
            await this.loadLanguages()
            runInAction(() => {
                this.showEditTranslation = false
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.response.data) {
                    console.log('here', e.response.data)
                    this.newLanguageError = true
                    this.newLanguageErrorMsg = e.response.data.errMsg
                } else {
                    this.newLanguageError = true
                    this.newLanguageErrorMsg = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @observable
    git_pull = async () => {
        try {
            await this.props.api.git_api().update()
            await this.loadLanguages()

            runInAction(() => {
                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        const languageList = []
        const translations = []

        for (const lang of this.data.list) {
            if (this.selected_languges.indexOf(lang.uuid) === -1) {
                continue
            }

            languageList.push(<th key={lang.uuid}>{lang.name}</th>)
        }

        for (const key of this.data.options.email_keys) {
            const tds = []
            for (const lang of this.data.list) {
                if (this.selected_languges.indexOf(lang.uuid) === -1) {
                    continue
                }

                tds.push(
                    <td key={lang.uuid + key} className="entry-col cursor-pointer" onClick={() => this.onEditTranslation(lang, key, this.data.languages[lang.uuid][key], 'email')}>
                        {this.data.languages[lang.uuid][key]}
                    </td>,
                )
            }

            translations.push(
                <tr key={'email_' + key}>
                    <td className="sticky-col first-col">{key}</td>
                    {tds}
                </tr>,
            )
        }

        for (const key of this.data.options.language_keys) {
            const tds = []
            for (const lang of this.data.list) {
                if (this.selected_languges.indexOf(lang.uuid) === -1) {
                    continue
                }

                tds.push(
                    <td key={lang.uuid + key} className="entry-col cursor-pointer" onClick={() => this.onEditTranslation(lang, key, this.data.languages[lang.uuid][key], 'language')}>
                        {this.data.languages[lang.uuid][key]}
                    </td>,
                )
            }

            translations.push(
                <tr key={'lang_' + key}>
                    <td className="sticky-col first-col">{key}</td>
                    {tds}
                </tr>,
            )
        }

        //newLangauge popup
        const languagesList = []
        for (const language of _.values(countiresList.languages)) {
            languagesList.push({
                id: language.name,
                label: language.name,
            })
        }

        return (
            <div className="projects languages">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Languages</h4>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-between">
                    <div className="col-sm-4">
                        <button className="btn btn-success btn-rounded mb-3 waves-effect waves-light" onClick={this.onNewLangauge}>
                            <i className="mdi mdi-plus"> </i>New language
                        </button>
                        &nbsp;
                        <a onClick={() => this.git_pull()} className="btn btn-primary btn-rounded mb-3 waves-effect waves-light">
                            <i className="mdi mdi-git"> </i>Git pull
                        </a>
                    </div>
                    <div className="col-sm-4 col-">
                        <div className="mb-3">
                            <Multiselect id="languages" data={_.mapValues(_.keyBy(this.data.list, 'uuid'), 'name')} value={this.selected_languges} onChange={action((e) => console.log((this.selected_languges = e)))} />
                        </div>
                    </div>
                </div>

                <div className="row view">
                    <div className="lang-wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="sticky-col first-col">Key</th>
                                    {languageList}
                                </tr>
                            </thead>
                            <tbody>{translations}</tbody>
                        </table>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onNewLangaugeClose} type="bg-success" title={'New language'} show={this.showNewLanguage}>
                    <div className="custom-modal-text">
                        <div>
                            <div className="form-row">
                                <div className="col-md">
                                    {this.newLanguageError && (
                                        <div className="alert alert-danger" role="alert">
                                            <i className="mdi mdi-block-helper mr-2"></i> {this.newLanguageErrorMsg}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label htmlFor="user-name">Name</label>
                                        <Typeahead
                                            id="country"
                                            options={languagesList}
                                            onChange={
                                                action((e: string[]) => {
                                                    this.newLangauge.flag = (e[0] ? (e[0] as any).id : '').toLocaleLowerCase()

                                                    const short = indexFromSearch(countiresList.languages, 'name', e[0] ? (e[0] as any).id : '')

                                                    if (short) {
                                                        this.newLangauge.short = short
                                                        this.newLangauge.name = (countiresList.languages as any)[this.newLangauge.short] ? (countiresList.languages as any)[this.newLangauge.short].native : ''
                                                    }
                                                }) as any
                                            }
                                            selected={[
                                                _.find(languagesList, { id: this.newLangauge.flag }) || {
                                                    id: '',
                                                    label: '',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label htmlFor="user-email">Native name</label>
                                        <input type="text" id="user-email" value={this.newLangauge.name} disabled={true} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <div className="form-group mb-3">
                                        <label htmlFor="user-email">Short name</label>
                                        <input type="text" id="user-email" value={this.newLangauge.short} disabled={true} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md">
                                    <label htmlFor="user-email">Flag</label>
                                    <input type="text" id="user-email" value={this.newLangauge.flag} disabled={true} className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onNewLangaugeClose} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.onNewLangaugeSave} className="btn width-md waves-effect waves-light btn-primary">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={this.onEditTranslationClose} type="bg-success" title={'Edit translation'} show={this.showEditTranslation}>
                    <div className="custom-modal-text">
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-email">Language</label>
                                    <input type="text" id="user-email" value={this.translation.language} disabled={true} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-email">Translation key</label>
                                    <input type="text" id="user-email" value={this.translation.key} disabled={true} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-email">Value</label>
                                    <textarea value={this.translation.value} onChange={action((e) => (this.translation.value = e.target.value))} className="form-control"></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onEditTranslationClose} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.onUpdateTranslation} className="btn width-md waves-effect waves-light btn-primary">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(Languages)
