import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Booth, OpenVPNClient } from 'shared/models-web'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { timeout } from '../../utils/utils'
import { Link } from 'react-router-dom'

import Popup from '../../components/Popup'
import BoothEntry from './BoothEntry'
import { withRouter } from '../../utils/react-utils'

@observer
class Booths extends React.Component<{ api: Api; store: Store }> {
    @observable
    isLoaded = 0

    @observable
    booths: { booth: Booth; connection?: OpenVPNClient; socketio: boolean }[] = []

    //erro model controls
    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    boothIdData: string[] = []

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        try {
            const booths = (await this.props.api.booth_api().list()).data

            runInAction(() => {
                this.booths = booths

                //this fixes problems with clikcing links if we use loading
                this.isLoaded = 1
            })

            await timeout(300)

            runInAction(() => {
                this.isLoaded = 2
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    render() {
        const booths = []

        for (const entry of this.booths) {
            booths.push(<BoothEntry key={entry.booth.uuid} booth={entry.booth} socketio={entry.socketio} connection={entry.connection} isLoaded={this.isLoaded} />)
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Booth Settings</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <Link to="/booths/new" className="btn btn-success btn-rounded mb-3 waves-effect waves-light">
                            <i className="mdi mdi-plus"> </i>New Booth
                        </Link>
                    </div>
                </div>

                {booths}

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(Booths)
