import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import Popup from './Popup'
import DropdownComponent from './ui-elements/DropdownComponent'
import { Admin } from 'shared/models-web'

@observer
export default class UserEditModal extends React.Component<{
    show: boolean
    newuser: boolean
    edituser: boolean
    deleteUser?: (uuid: string) => void
    onSave: (user: Admin) => void
    onClose: () => void
    user?: Admin
}> {
    emailRegEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)

    @observable
    user: Admin = {
        email: '',
        password: '',
        username: '',
        uuid: '-1',
        flags: 0, //admin
        deleted: 0,
    }
    @observable
    repeat_password = ''
    @observable
    error = false
    @observable
    errorMsg = ''

    @observable
    show = false

    constructor(props: any) {
        super(props);

        makeObservable(this)
    }

    @action
    closeModal = () => {
        this.show = false
        this.props.onClose()
    }

    @action
    componentDidUpdate(prevProps: any) {
        if (this.props.show !== prevProps.show) {
            this.show = this.props.show

            if (this.show === false) {
                this.user = {
                    email: '',
                    password: '',
                    username: '',
                    uuid: '',
                    flags: 0, //admin
                    deleted: 0,
                }
            }
        }

        if (this.props.user !== undefined && this.props.user !== prevProps.user) {
            this.user = this.props.user
        }
    }

    @action
    cancel = async () => {
        this.show = false
        this.props.onClose()
    }

    @action
    save = async () => {
        if (!this.user) {
            return
        }

        if (this.props.newuser) {
            if (this.repeat_password !== this.user.password) {
                return this.present_error('Passwords should match')
            }
        } else if (this.props.edituser) {
            if (this.repeat_password !== '' && this.user.password !== '') {
                if (this.repeat_password !== this.user.password) {
                    return this.present_error('Passwords should match')
                }
            }
        }

        if (!this.emailRegEx.test(this.user.email)) {
            return this.present_error('Invalid email')
        }

        if (this.user.username.length < 2) {
            return this.present_error('Username must be at least 2 letters long')
        }

        this.props.onSave(this.user)
    }

    @action
    present_error = (error: string) => {
        this.errorMsg = error
        this.error = true

        setTimeout(() => {
            this.error = false
        }, 3000)
    }

    @action
    deleteUser = () => {
        this.props.deleteUser && this.props.deleteUser(this.user.uuid)
    }

    render() {
        return (
            <Popup onClose={this.cancel} title={'New User'} show={this.props.show}>
                <div className="custom-modal-text">
                    <form>
                        <div className="form-row">
                            <div className="col-md">
                                {this.error && (
                                    <div className="alert alert-danger" role="alert">
                                        <i className="mdi mdi-block-helper mr-2"></i> {this.errorMsg}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-name">Name</label>
                                    <input type="text" id="user-name" value={this.user.username} onChange={(e) => action((this.user.username = e.target.value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-email">Email</label>
                                    <input type="text" id="user-email" value={this.user.email} onChange={(e) => action((this.user.email = e.target.value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-password">Password</label>
                                    <input type="password" id="user-password" value={this.user.password} onChange={(e) => action((this.user.password = e.target.value))} className="form-control" autoComplete="new-password" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-repeat-password">Repeat password</label>
                                    <input type="password" id="user-repeat-password" value={this.repeat_password} onChange={(e) => action((this.repeat_password = e.target.value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <DropdownComponent
                                    title={'Privilege'}
                                    data={{
                                        options: [
                                            { key: 0, value: 'Admin' },
                                            { key: 1, value: 'Superadmin' },
                                        ],
                                        key: 'key',
                                        value: 'value',
                                    }}
                                    value={this.user.flags}
                                    disabled={true}
                                    onChange={action((val: any) => {
                                        if (!this.props.user) {
                                            this.user.flags = 0
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    </form>

                    {!this.props.user && (
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.save} className="btn width-md waves-effect waves-light btn-primary">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    )}

                    {this.props.user && (
                        <div className="row">
                            <div className="col-sm-4 mt-3">
                                <button type="button" className="btn waves-effect waves-light btn-danger" onClick={this.deleteUser}>
                                    <i className="mdi mdi-delete"> </i>Delete
                                </button>
                            </div>
                            <div className="col-sm-8 text-sm-right mt-3">
                                <div className="btn-group">
                                    <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                        Cancel
                                    </button>
                                    <button type="button" onClick={this.save} className="btn width-md waves-effect waves-light btn-primary">
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Popup>
        )
    }
}
