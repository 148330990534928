import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { Language, Project } from 'shared/models-web'
import moment from 'moment'
import _ from 'lodash'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Multiselect from '../../../components/ui-elements/MultiselectV2'
import Input from '../../../components/ui-elements/Input'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectGeneralMulti extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <div>
                        <h4 className="header-title">General</h4>
                        <p className="sub-header">Let's start with some basic info about the project.</p>
                        <div className="form-row">
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label htmlFor={'project-name'}>Project title</label>
                                    <Input id={'project-name'} type="text" value={this.props.project.name} onChange={action((value) => (this.props.project.name = value))} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor={'startDate'}>Start date</label>
                                <div className="input-group mb-3">
                                    <Input updateTime={30} id={'startDate'} className="form-control" value={this.props.project.startDate} onChange={action((e) => (this.props.project.startDate = e))} type="date" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.startDate = moment().format('YYYY-MM-DD')))}>
                                            Today
                                        </button>
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.startDate = moment().add(1, 'day').format('YYYY-MM-DD')))}>
                                            Tomorrow
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor={'endDate'}>End date</label>
                                <div className="input-group mb-3">
                                    <Input updateTime={30} id={'endDate'} className="form-control" value={this.props.project.endDate} onChange={action((e) => (this.props.project.endDate = e))} type="date" />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.endDate = moment().format('YYYY-MM-DD')))}>
                                            Today
                                        </button>
                                        <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.project.endDate = moment().add(1, 'day').format('YYYY-MM-DD')))}>
                                            Tomorrow
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-md-6">
                                <label htmlFor={'languageId'}>Primary language</label>
                                <div className="input-group mb-3">
                                    <Select default={true} id={'languageId'} className="custom-select" value={this.props.project.boothConfig.languageId} onChange={action((e) => (this.props.project.boothConfig.languageId = e))} data={{ options: this.props.languages, key: 'uuid', value: 'name' }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor={'languages'}>Other available languages</label>
                                <div className="mb-3">
                                    <Multiselect id="languages" data={_.mapValues(_.keyBy(this.props.languages, 'uuid'), 'name')} value={this.props.project.boothConfig.languages} onChange={action((e) => console.log((this.props.project.boothConfig.languages = e)))} disable={[this.props.project.boothConfig.languageId]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
