import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { observable, action, observe, makeObservable, runInAction } from 'mobx'
import { Navigate, NavigateFunction, Params } from 'react-router-dom'
import { Language, Project, Booth, ProjectBoogkingSlot, ProjectStats } from 'shared/models-web'

import moment from 'moment'
import csc from 'country-state-city'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import { timeout } from '../../utils/utils'
import Popup from '../../components/Popup'
import ProjectType from './components/ProjectType'
import ProjectGeneralSingle from './components/ProjectGeneralSingle'
import ProjectGeneralMulti from './components/ProjectGeneralMulti'
import ProjectWorkflow from './components/ProjectWorkflow'
import ProjectPages from './components/ProjectPages'
import ProjectDesign from './components/ProjectDesign'
import ProjectLocations from './components/ProjectLocations'
import ProjectBooth from './components/ProjectBooth'
import ProjectBooking from './components/ProjectBooking'
import ProjectRedirects from './components/ProjectRedirects'
import { TemplateManifest, WebsiteColor } from 'shared/template'
import ProjectHighlights from './components/ProjectHighlights'
import ProjectMaps from './components/ProjectMap'
import { withRouter } from '../../utils/react-utils'
import type { History } from 'history'
import ImportPortraits from './components/ImportPortraits'

@observer
class ProjectEdit extends React.Component<{
    api: Api
    store: Store
    router: { location: Location; navigate: NavigateFunction; params: Params<string>; navigator: History; beforeUnload: (func: () => void) => void }
}> {
    @observable
    isNew = true
    @observable
    project: Project = {
        uuid: '',
        id: 0,
        startDate: '',
        start: 0,
        endDate: '',
        end: 0,
        name: '',
        slug: '',
        about: {
            active: true,
            id: '',
            text: [''],
            title: '',
            type: 'project',
            version: 0,
            options: {},
            updatedBy: '',
            images: [],
        },
        main: {
            active: true,
            id: '',
            text: [''],
            title: '',
            type: 'project',
            version: 0,
            options: {},
            updatedBy: '',
            images: [],
        },
        location: {
            lat: '',
            lon: '',
            timeZone: '',
            country: '',
            country_id: '',
            state: '',
            state_id: '',
            city: '',
            city_id: '',
            isEu: false,
            gpsLock: false,
        },
        tagline: {
            hashtag: '',
            url: '',
            name: '',
        },
        boothConfig: {
            timertime: 3,
            email: true,
            releaseForm: true,
            backup: false,
            website: true,
            print: true,
            print_ask: false,
            languageId: '',
            templateId: '',
            languages: [],
        },
        syncing_backup: false,
        syncing_website: false,
        deleted: false,
        projectIds: [],
        type: '-1',
        status: 'draft',
        slots: [],
    }
    @observable
    languages: Language[] = []
    @observable
    templates: TemplateManifest[] = []
    @observable
    booths: { booth: Booth }[] = []
    @observable
    projectName = ''
    @observable
    files: File[] = []
    @observable
    highlights: File[] = []
    @observable
    images: { id: string; url: string; filename: string }[] = []
    @observable
    highlights_images: { id: string; url: string; filename: string }[] = []
    @observable
    subProjects: { project: Project; stats: ProjectStats }[] = []
    @observable
    website_options: { colors: WebsiteColor[] } = {
        colors: [],
    }
    @observable
    page_options: any

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    isLoaded = false
    @observable
    isLoading = false
    @observable
    isSaving = false
    @observable
    redirect = false
    @observable
    isFormDirty = false
    @observable
    showSaveChanges = false
    disposer1?: any
    disposer2?: any
    disposer3?: any
    disposer4?: any

    @observable
    slot: ProjectBoogkingSlot = {
        date: '',
        end: '',
        personsPerHour: 30,
        start: '',
        uuid: '',
        deleted: false,
    }
    @observable
    release_url: string = ''

    @observable
    importUsersShow = false
    @observable
    importUsersProjectId?: string

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    attachObserver = () => {
        this.disposer1 && this.disposer1()
        this.disposer2 && this.disposer2()
        this.disposer3 && this.disposer3()
        this.disposer4 && this.disposer4()

        this.disposer1 = observe(
            this.project,
            action((change: any) => {
                this.isFormDirty = true
            }),
        )
        this.disposer2 = observe(
            this.project.boothConfig,
            action((change: any) => {
                this.isFormDirty = true
            }),
        )
        this.disposer3 = observe(
            this.project.location,
            action((change: any) => {
                this.isFormDirty = true
            }),
        )
        this.disposer3 = observe(
            this.project.tagline,
            action((change: any) => {
                this.isFormDirty = true
            }),
        )
    }

    @action
    markDrty = () => {
        this.isFormDirty = true
    }

    @action
    init = async () => {
        console.log('Started loading project')

        if (this.props.router.params.projectId) {
            this.isNew = false
        }

        await this.loadProject()

        this.attachObserver()

        this.props.router.beforeUnload(
            action(() => {
                if (this.isFormDirty) {
                    this.showSaveChanges = true
                    return true
                }
            }),
        )
    }

    @action
    loadProject = async () => {
        try {
            this.isLoading = true

            if (this.props.router.params.projectId) {
                const ret = (await this.props.api.project_api().open_project(this.props.router.params.projectId)).data

                runInAction(() => {
                    this.project = ret.project
                    this.projectName = this.project.name
                    this.subProjects = ret.projects
                    this.release_url = ret.release_url

                    this.images = ret.imagesMain
                    this.highlights_images = ret.highlights
                    this.page_options = ret.page_options
                    this.importUsersProjectId = this.props.router.params.projectId
                })
            } else {
                const project_template: { [id: string]: any } = (await this.props.api.project_api().project_template()).data

                for (const entry in project_template) {
                    ;(this.project as any)[entry] = project_template[entry]
                }
            }

            const languages = (await this.props.api.project_api().languages()).data
            const templates = (await this.props.api.project_api().templates()).data
            const booths = (await this.props.api.booth_api().list()).data
            const website_options = (await this.props.api.project_api().website_options()).data

            runInAction(() => {
                this.languages = languages
                this.templates = templates
                this.booths = booths
                this.website_options = website_options

                this.isLoaded = true
                this.isLoading = false
                console.log(`Project loaded as new: ${this.isNew} type: ${this.project.type}`)
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    beforeunload = (event: any) => {
        if (this.isFormDirty) {
            event.returnValue = ''
        }
        return event
    }

    componentWillUnmount = () => {
        // window.removeEventListener('beforeunload', this.beforeunload)

        this.disposer1 && this.disposer1()
        this.disposer2 && this.disposer2()
        this.disposer3 && this.disposer3()
        this.disposer4 && this.disposer4()
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    onCloseSaveChanges = () => {
        this.showSaveChanges = false
    }

    @action
    error = (err: string) => {
        this.showErrorModal = true
        this.errorModalText = err
    }

    @action
    discardChanges = async () => {
        this.showSaveChanges = false
        this.isFormDirty = false

        await timeout(100)
        this.redirect = true
    }

    @action
    save = async () => {
        try {
            if (this.project.location.country === 'United States') {
                this.project.location.isEu = false
            } else {
                this.project.location.isEu = true
            }

            if (this.project.redirects) {
                if (this.project.redirects.booking_url && !/(http|https):\/\/(.*)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(this.project.redirects.booking_url)) {
                    this.showErrorModal = true
                    this.errorModalText = `Booking url is not valid`
                    return
                }

                if (this.project.redirects.release_url && !/(http|https):\/\/(.*)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(this.project.redirects.release_url)) {
                    this.showErrorModal = true
                    this.errorModalText = `Release url is not valid`
                    return
                }
            }

            const country = csc.getCountryById(this.project.location.country_id)

            if (country) {
                const data = (await this.props.api.project_api().cities_by_country(country.sortname, this.project.location.city)).data

                if (data && !this.project.location.gpsLock) {
                    // how we know that this is drty????

                    this.project.location.lat = data.latitude
                    this.project.location.lon = data.longitude

                    console.log(country.sortname, this.project.location.city, { lat: this.project.location.lat, lon: this.project.location.lon })
                }
            }

            if (this.isNew) {
                await this.props.api.project_api().add_project(this.project, this.files, this.highlights)
            } else if (this.props.router.params.projectId) {
                await this.props.api.project_api().edit_project(this.props.router.params.projectId, this.project, this.files, this.highlights)
            }

            this.isFormDirty = false
            this.redirect = true
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    cancel = async () => {
        if (this.isFormDirty) {
            this.showSaveChanges = true
            return
        }
        this.redirect = true
    }

    @action
    removeExistingFile = async (file: { id: string; url: string; filename: string }) => {
        try {
            await this.props.api.pages_api().removeFile(file.id)

            await this.loadProject()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onEditSlot = (slot: ProjectBoogkingSlot) => {
        this.slot = slot

        //If missing!
        if (!this.slot.deleted) {
            this.slot.deleted = false
        }
    }

    @action
    onDeleteSlot = async (slot: ProjectBoogkingSlot) => {
        try {
            if (this.isNew) {
                return
            }

            await this.props.api.booking_api().delete(this.project.uuid, slot.uuid)

            await this.loadProject()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onToggleDisableSlot = async (slot: ProjectBoogkingSlot) => {
        try {
            if (this.isNew) {
                return
            }

            await this.props.api.booking_api().toggle_disable(this.project.uuid, slot.uuid)

            await this.loadProject()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onSave = async () => {
        try {
            if (this.isNew) {
                return
            }

            const start = Number.parseInt(this.slot.start.substring(this.slot.start.indexOf('.')))
            const end = Number.parseInt(this.slot.end.substring(this.slot.end.indexOf('.')))

            if (Number.isNaN(end) || Number.isNaN(start)) {
                throw new Error('Start or end not a number')
            }

            if (end < start) {
                throw new Error("End can't be before start")
            }

            if (!moment(this.slot.date, 'YYYY-MM-DD').isValid()) {
                throw new Error('Invalid date')
            }

            if (this.slot.uuid === '') {
                await this.props.api.booking_api().add(this.project.uuid, this.slot.date, this.slot.start, this.slot.end, this.slot.personsPerHour)
            } else {
                await this.props.api.booking_api().edit(this.project.uuid, this.slot.uuid, this.slot.date, this.slot.start, this.slot.end, this.slot.personsPerHour)
            }

            await this.loadProject()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    render() {
        if (this.redirect) {
            return <Navigate to="/projects" />
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">{this.isNew ? 'New Project' : 'Edit "' + this.projectName + '"'}</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <ProjectType drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} />
                </div>

                <div className="row">
                    <div className="col-12">
                        {this.project.type === 'single' && <ProjectGeneralSingle drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} />}
                        {this.project.type === 'multi' && <ProjectGeneralMulti drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} />}
                    </div>

                    {this.project.type === 'single' && (
                        <div className="col-12">
                            <ProjectMaps api={this.props.api} drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} page_options={this.page_options} />
                        </div>
                    )}

                    <div className="col-12">
                        <ProjectDesign drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} />
                    </div>

                    {this.project.type === 'multi' && (
                        <div className="col-12">
                            <ProjectLocations
                                navigate={this.props.router.navigate}
                                drty={this.markDrty}
                                error={this.error}
                                isFormDirty={this.isFormDirty}
                                isLoaded={this.isLoaded}
                                isLoading={this.isLoading}
                                isSaving={this.isSaving}
                                languages={this.languages}
                                templates={this.templates}
                                project={this.project}
                                subProjects={this.subProjects}
                                import={(uuid) => {
                                    this.importUsersShow = true
                                    this.importUsersProjectId = uuid
                                }}
                            />
                        </div>
                    )}

                    {this.project.type === 'single' && (
                        <div className="col-12">
                            <ProjectBooth drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} booths={this.booths} />
                        </div>
                    )}

                    <div className="col-12">
                        <ProjectWorkflow drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} isNew={this.isNew} />
                    </div>

                    {this.project.type === 'single' && (
                        <div className="col-12">
                            <ProjectRedirects drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} />
                        </div>
                    )}

                    {!this.isNew && this.project.type === 'single' && (
                        <div className="col-12">
                            <ProjectBooking drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} slot={this.slot} releaseUrl={this.release_url} onSave={this.onSave} onEditSlot={this.onEditSlot} onDeleteSlot={this.onDeleteSlot} onToggleDisableSlot={this.onToggleDisableSlot} />
                        </div>
                    )}
                </div>

                <ProjectPages drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} files={this.files} images={this.images} removeExistingFile={this.removeExistingFile} website_options={this.website_options} />

                <ProjectHighlights drty={this.markDrty} error={this.error} isFormDirty={this.isFormDirty} isLoaded={this.isLoaded} isLoading={this.isLoading} isSaving={this.isSaving} languages={this.languages} templates={this.templates} project={this.project} files={this.highlights} images={this.highlights_images} removeExistingFile={this.removeExistingFile} />

                <div className="row mb-0 mt-20">
                    <div className="col-sm-12">
                        <div className="text-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={this.cancel} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.save} className="btn width-md waves-effect waves-light btn-success">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>
                <Popup onClose={this.onCloseSaveChanges} title={'Unsaved changes'} show={this.showSaveChanges}>
                    <div className="custom-modal-text">
                        <p>You've made some changes. What would you like to do with them?</p>
                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.discardChanges} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <button type="button" onClick={this.onCloseSaveChanges} className="btn width-md waves-effect waves-light btn-success">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={action(() => (this.importUsersShow = false))} type="" title={'Import portraits wizard'} show={this.importUsersShow} size="xl">
                    <div className="custom-modal-text">
                        <ImportPortraits api={this.props.api} store={this.props.store} onClose={action(() => (this.importUsersShow = false))} projectId={this.importUsersProjectId} />
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(ProjectEdit)
