"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectStatus = exports.PageType = void 0;
exports.PageType = {
    aboutpage: "About page",
    legalpage: "Legal page",
    landingpage: "Landing page",
    project: "Project",
    projectgroup: "ProjectGroup",
    release_forms: "release_forms",
};
exports.ProjectStatus = {
    published: "Published",
    hidden: "Hidden",
    draft: "Draft",
};
