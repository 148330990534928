import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'

import { action, makeObservable, observable, runInAction } from 'mobx'
import Popup from '../../components/Popup'
import { BookedSessions, Project, ProjectBoogkingSlot, User, UserMeta } from 'shared/models-web'
import _ from 'lodash'
import ProjectBookingUserEntry from './components/ProjectBookingUserEntry'
import { mockProject } from '../../lib/utils'
import slugify from 'slugify'
import moment from 'moment'
import Input from '../../components/ui-elements/Input'
import { Dropdown } from 'react-bootstrap'
import { withRouter } from '../../utils/react-utils'
import { NavigateFunction, Params } from 'react-router-dom'

@observer
class ProjectBooking extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    isLoading = false
    @observable
    filter = 'active'
    @observable
    from = 0
    @observable
    hasMore = false
    @observable
    search = ''

    @observable
    users: { [id: string]: { user: User; userMeta: UserMeta; slot: ProjectBoogkingSlot; booked: BookedSessions }[] } = {}
    @observable
    project: Project = mockProject().project
    @observable
    release_url: string = ''
    @observable
    slots: ProjectBoogkingSlot[] = []
    @observable
    resultLenght: number = 0

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    confirmDeleteShow = false
    @observable
    bookedSessionToDelete?: string

    loadUsersDebounce = _.debounce(async () => {
        await this.loadUsers()
    })

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    init = async () => {
        await this.loadUsers()

        // Binds our scroll event handler
        window.onscroll = _.debounce(async () => {
            // Bails early if:
            // * there's an error
            // * it's already loading
            // * there's nothing left to load
            if (this.isLoading || !this.hasMore) return

            // Checks that the page has scrolled to the bottom
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                this.from += this.resultLenght

                await this.loadUsers()
            }
        }, 100)

        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            this.from += this.resultLenght

            await this.loadUsers()
        }
    }

    @action
    loadUsers = async () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true
        try {
            if (!this.props.router.params.projectId) {
                throw new Error("Can't load project")
            }

            const res = (await this.props.api.booking_api().users(this.props.router.params.projectId, this.filter, this.search, this.from)).data

            runInAction(() => {
                for (const slotId in res.users) {
                    if (Array.isArray(this.users[slotId])) {
                        this.users[slotId] = this.users[slotId].concat(res.users[slotId])
                    } else {
                        this.users[slotId] = res.users[slotId]
                    }
                }

                this.hasMore = res.hasMore
                this.project = res.project
                this.release_url = res.release_url
                this.resultLenght = res.resultLenght
                this.slots = res.slots
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        } finally {
            runInAction(() => {
                this.isLoading = false
            })
        }
    }

    componentWillUnmount = () => {
        window.onscroll = null
    }

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter
        this.hasMore = true
        this.from = 0
        this.users = {}

        await this.loadUsers()
    }

    @action
    removeBooking = (bookingSessionId: string) => {
        this.bookedSessionToDelete = bookingSessionId
        this.confirmDeleteShow = true
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    confirmDelete = async () => {
        this.confirmDeleteShow = false
        if (!this.bookedSessionToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        try {
            // delete user
            this.props.api.booking_api().remove_session(this.bookedSessionToDelete)

            runInAction(() => {
                this.hasMore = true
                this.from = 0
                this.users = {}
            })

            await this.loadUsers()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onCloseConfirmDeleteModal = () => {
        this.confirmDeleteShow = false
    }

    @action
    viewReleaseForm = (user: User) => {
        window.open(`/images/${user.uuid}-releaseform.pdf`)
    }

    @action
    resendEmail = async (bookedSessionId: string) => {
        try {
            await this.props.api.booking_api().resend_email(bookedSessionId)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    onSearch = async (e: any) => {
        this.search = e.target.value

        this.hasMore = true
        this.from = 0
        this.users = {}

        await this.loadUsersDebounce()
    }

    openUser = (userId: string) => {
        this.props.router.navigate(`/users/${userId}`)
    }

    users_csv = async (bookingSlotId?: string) => {
        console.log('wtf', bookingSlotId)

        try {
            if (!this.props.router.params.projectId) {
                throw new Error("Can't load project")
            }

            const csv = await this.props.api.booking_api().users_csv(bookingSlotId ? undefined : this.props.router.params.projectId, bookingSlotId)

            var link = window.document.createElement('a')
            var data = new Blob([csv], { type: 'text/csv' })

            link.href = window.URL.createObjectURL(data)
            link.download = `${slugify(this.project.name)}-${moment().format('YYYY-MM-DD-HHmmss')}.csv`

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    copy = (text: string) => {
        navigator.clipboard.writeText(text)
    }

    render() {
        const sections = []

        for (const slotId in this.users) {
            const users = []
            for (const user of this.users[slotId]) {
                users.push(<ProjectBookingUserEntry key={user.booked.uuid} api={this.props.api} store={this.props.store} user={user.user} userMeta={user.userMeta} booked={user.booked} slot={user.slot} viewReleaseForm={this.viewReleaseForm} removeBooking={this.removeBooking} resendEmail={this.resendEmail} openUser={this.openUser} />)
            }

            const slot = _.find(this.slots, (entry) => entry.uuid === slotId)

            if (!slot) {
                continue
            }

            const start = Number.parseInt(slot.start.substring(slot.start.indexOf('.')))
            const end = Number.parseInt(slot.end.substring(slot.end.indexOf('.')))

            sections.push(
                <>
                    <div className="row" key={'section-' + slot.date + start + end}>
                        <div className="col-8">
                            <h3>
                                {moment(slot.date, 'YYYY-MM-DD').format('DD MMM, YYYY')} {start > 12 ? `${start - 12}:00 PM` : `${start}:00 AM`} - {end > 12 ? `${end - 12}:00 PM` : `${end}:00 AM`}
                            </h3>
                        </div>
                        <div className="col-4">
                            <div className="d-flex flex-row align-items-center justify-content-between">
                                <h4 className="p-0 m-0"></h4>

                                <Dropdown className={'d-flex align-items-center'}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop">
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                        <Dropdown.Item onSelect={(e: any) => this.users_csv(slotId)}>CSV</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="row filterable-content" key={'users-' + slot.date + start + end}>
                        {users}
                    </div>
                </>,
            )
        }

        return (
            <div className="projects">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Booked Sessions for project {this.project.name}</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card-box">
                            <div className="row">
                                <div className="col-lg-8">
                                    <form className="form-inline">
                                        <div className="form-group">
                                            <label html-for="url" className="sr-only">
                                                Urls
                                            </label>
                                            <div className="input-group">
                                                <Input id={'url'} type="text" value={`${this.release_url}/${this.project.slug}`} disabled={true} className="form-control" />
                                                <div className="input-group-append">
                                                    <button onClick={() => this.copy(`${this.release_url}/${this.project.slug}`)} className="btn btn-secondary waves-effect waves-light ladda-button" type="button">
                                                        <i className="mdi mdi-content-copy"></i> Copy URL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-lg-right mt-3 mt-lg-0">
                                        <button type="button" onClick={() => this.users_csv()} className={'btn waves-effect waves-light btn-primary'}>
                                            CSV All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {sections}
                <div className="row">
                    <div className="col-12">
                        <div className="text-center my-4">
                            {this.isLoading && (
                                <p className="pb-3">
                                    <i className="mdi mdi-spin mdi-loading mr-2"></i>Loading more...
                                </p>
                            )}

                            {/*// <!-- Če je konec seznama pa skrij zgornje in prikaži tole: -->*/}
                            {!this.hasMore && <p className="pb-3">No more entries to show.</p>}
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal} close={true}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseConfirmDeleteModal} type="" title={'Remove booked session'} show={this.confirmDeleteShow}>
                    <div className="custom-modal-text">
                        <p>You are about to remove booked session. Person will be notifyed that booked session was cancled.</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.confirmDelete} className="btn width-md waves-effect waves-light btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(ProjectBooking)
