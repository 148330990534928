import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import _ from 'lodash'

@observer
export default class Input extends React.Component<{
    id: string
    value: any
    type: string
    placeholder?: string
    className: any
    onChange?: (value: string) => void
    updateTime?: number
    disabled?: boolean
}> {
    @observable
    value: any
    oldValue: any

    onChange: any

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.value = props.value
    }

    @action
    componentDidUpdate = () => {
        if (this.oldValue !== this.props.value) {
            this.value = this.props.value
            this.oldValue = this.props.value
        }

        if (!this.onChange && this.props.onChange) {
            this.onChange = _.debounce(this.props.onChange, this.props.updateTime || 500)
        }
    }

    render() {
        return (
            <input
                id={this.props.id}
                type={this.props.type}
                value={this.value}
                disabled={this.props.disabled}
                onChange={action((e) => {
                    this.value = e.target.value
                    this.onChange && this.onChange(this.value)
                })}
                placeholder={this.props.placeholder}
                className={this.props.className}
                autoComplete="false"
            />
        )
    }
}
