import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Input from '../../../components/ui-elements/Input'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectDesign extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <div>
                        <h4 className="header-title">Design</h4>
                        <p className="sub-header"> Select a booth UI template, and enter tagline texts. The list of templates is automatically updated when the booth has internet connectivity. </p>
                        <div className="form-row">
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label htmlFor={'templateId'}>Template</label>
                                    <Select id={'templateId'} className="custom-select" value={this.props.project.boothConfig.templateId} onChange={action((e) => (this.props.project.boothConfig.templateId = e))} data={{ options: this.props.templates, key: 'uuid', value: 'name' }} default={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-4">
                                <div className="form-group mb-3">
                                    <label htmlFor={'url'}>URL</label>
                                    <Input id={'url'} type="text" value={this.props.project.tagline.url} onChange={action((e) => (this.props.project.tagline.url = e))} className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group mb-3">
                                    <label htmlFor={'name'}>Text</label>
                                    <Input id={'name'} type="text" value={this.props.project.tagline.name} onChange={action((e) => (this.props.project.tagline.name = e))} className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group mb-3">
                                    <label htmlFor={'hashtag'}>Hashtag</label>
                                    <Input id={'hashtag'} type="text" value={this.props.project.tagline.hashtag} onChange={action((e) => (this.props.project.tagline.hashtag = e))} className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
