import { observer } from 'mobx-react'
import React from 'react'
import { Entry, WizardPosition } from '../ImportPortraits'

export default observer(function ImportSelectFolder(props: { position: WizardPosition; next: (mode: WizardPosition) => void; onClose: () => void; render_tree: (folder: Entry[]) => void; folder: Entry[] }) {
    return (
        <>
            <h3>Select folder to import users</h3>
            {props.render_tree(props.folder)}
            <div className="text-right mt-3">
                <div className="btn-group">
                    <button type="button" onClick={props.onClose} disabled={false} className="btn width-md waves-effect waves-light btn-secondary">
                        Cancel
                    </button>
                    <button type="button" onClick={() => props.next('MODE')} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                        Back
                    </button>
                    <button type="button" onClick={() => props.next('CONFIRM')} disabled={false} className="btn width-md waves-effect waves-light btn-primary">
                        Next
                    </button>
                </div>
            </div>
        </>
    )
})
