import React from 'react'
import { observer } from 'mobx-react'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'

import { TemplateManifest, WebsiteColor } from 'shared/template'
import { action } from 'mobx'
import { SketchPicker } from 'react-color'

@observer
export default class ProjectOptions extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    images: { id: string; url: string; filename: string }[]
    files: File[]
    removeExistingFile: (file: { id: string; url: string; filename: string }) => Promise<any>
    website_options: { colors: WebsiteColor[] }
}> {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Options</h3>

                            <div className="form-row">
                                <div className="col-md-3">
                                    <label htmlFor={'backgroundColor'}>Background color</label>
                                    <div className="input-group mb-3">
                                        <SketchPicker color={this.props.project.backgroundColor} onChange={action((e: any) => (this.props.project.backgroundColor = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, 0.975)`))} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor={'color'}>Color</label>
                                    <div className="input-group mb-3">
                                        <SketchPicker color={this.props.project.textColor} onChange={action((e: any) => (this.props.project.textColor = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`))} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor={'color'}>Link color</label>
                                    <div className="input-group mb-3">
                                        <SketchPicker color={this.props.project.linkColor} onChange={action((e: any) => (this.props.project.linkColor = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
