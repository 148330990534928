import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'

@observer
export default class FileDrop extends React.Component<{
    disable: boolean
    featured: boolean
    showFiles: boolean
    maxFiles: number
    files: File[]
    existing?: { id: string; url: string; filename: string }[]
    onMaxFiles?: () => void
    onFileAdded?: (file: File) => void
    removeExistingFile?: (file: { id: string; url: string; filename: string }) => void
}> {
    @observable
    isActive = false
    @observable
    showDropTarget = true

    constructor(props: any) {
        super(props);

        makeObservable(this)
    }

    componentDidUpdate = () => {
        this.updateComponent()
    }

    @action
    updateComponent = () => {
        let existing = 0
        if (this.props.existing) {
            existing = this.props.existing?.length
        }

        if (this.props.files.length + existing === this.props.maxFiles) {
            this.showDropTarget = false
        } else if (this.props.files.length + existing === 0) {
            this.showDropTarget = true
        }
    }

    @action
    dragOver = (e: any) => {
        e.preventDefault()

        this.isActive = true
    }

    @action
    dragEnter = (e: any) => {
        e.preventDefault()

        this.isActive = true
    }

    @action
    dragLeave = (e: any) => {
        e.preventDefault()

        this.isActive = false
    }

    @action
    onChange = async (e: any) => {
        e.preventDefault()

        //reset isActive to show form is ready for another file
        this.isActive = false

        if (this.props.disable) {
            //when component is disabled we do not expact to add file to files array
            return
        }

        const files = e.target.files

        await this.addFiles(files)
    }

    @action
    fileDrop = async (e: any) => {
        e.preventDefault()

        //reset isActive to show form is ready for another file
        this.isActive = false

        if (this.props.disable) {
            //when component is disabled we do not expact to add file to files array
            return
        }

        const files = e.dataTransfer.files

        await this.addFiles(files)
    }

    @action
    addFiles = async (files: FileList) => {
        if (this.props.files.length + this.props.files.length > this.props.maxFiles) {
            this.props.onMaxFiles && this.props.onMaxFiles()
            return
        }

        for (const f in files) {
            if (files[f].name && typeof files[f] === 'object') {
                console.log(f, files[f])

                this.props.files.push(files[f])

                this.props.onFileAdded && this.props.onFileAdded(files[f])
            }
        }

        this.updateComponent()
    }

    @action
    removeFile = async (file: File) => {
        const index = this.props.files.indexOf(file)

        this.props.files.splice(index, 1)

        this.updateComponent()
    }

    @action
    removeExistingFile = async (file: { id: string; url: string; filename: string }) => {
        this.props.removeExistingFile && (await this.props.removeExistingFile(file))

        this.updateComponent()
    }

    render() {
        return (
            <>
                {this.showDropTarget && (
                    <div className={'dd-container'} onDragOver={this.dragOver} onDragEnter={this.dragEnter} onDragLeave={this.dragLeave} onDrop={this.fileDrop}>
                        <div className={'dd-drop-container' + (this.props.disable ? ' disabled' : '') + (this.isActive ? ' active' : '')}>
                            <input type="file" name="files[]" accept="image/*" multiple className="dd-hide-file cursor-pointer" onChange={this.onChange} />
                            <div className="dd-drop-message cursor-pointer">
                                <div className="dd-upload-icon mdi mdi-upload"></div>
                                Drag & Drop files here or click to upload
                            </div>
                        </div>
                    </div>
                )}

                {this.props.featured && !this.showDropTarget && this.props.files.length > 0 && (
                    <div>
                        <p>
                            <img className="dd-image-preview" alt="" src={URL.createObjectURL(this.props.files[0])} />
                        </p>
                        <a className="cursor-pointer" onClick={() => this.removeFile(this.props.files[0])}>
                            Remove
                        </a>
                    </div>
                )}

                {this.props.featured && !this.showDropTarget && this.props.existing && this.props.existing.length > 0 && (
                    <div>
                        <p>
                            <img className="dd-image-preview" alt="" src={this.props.existing[0].url} />
                        </p>
                        <a className="cursor-pointer" onClick={() => this.props.existing && this.removeExistingFile(this.props.existing[0])}>
                            Remove
                        </a>
                    </div>
                )}

                {this.props.showFiles && (
                    <div>
                        <ul>
                            {this.props.files.map((file) => {
                                return (
                                    <li key={file.name}>
                                        {file.name}
                                        <a className="cursor-pointer" onClick={() => this.removeFile(file)}>
                                            Remove
                                        </a>
                                    </li>
                                )
                            })}
                            {this.props.existing && this.props.existing.map((file) => {
                                return (
                                    <li key={file.filename}>
                                        {file.filename}
                                        <a className="cursor-pointer" onClick={() => this.removeExistingFile(file)}>
                                            Remove
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
            </>
        )
    }
}
