import React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Language, Project, ProjectBoogkingSlot } from 'shared/models-web'

import moment from 'moment'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import NumberInputComponent from '../../../components/ui-elements/NumberInputComponent'
import Input from '../../../components/ui-elements/Input'
import Popup from '../../../components/Popup'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectBooking extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    onSave: () => void
    onEditSlot: (slot: ProjectBoogkingSlot) => void
    onDeleteSlot: (slot: ProjectBoogkingSlot) => void
    onToggleDisableSlot: (slot: ProjectBoogkingSlot) => void
    slot: ProjectBoogkingSlot
    releaseUrl: string
}> {
    @observable
    showBookingAdd = false

    constructor(props: any) {
        super(props)

        makeObservable(this)
    }

    @action
    onCloseBookingAdd = () => {
        this.showBookingAdd = false
    }

    @action
    onSave = () => {
        this.showBookingAdd = false

        this.props.onSave()
    }

    render() {
        const slots = []
        for (const slot of this.props.project.slots) {
            if (slot.deleted) {
                continue
            }

            const start = Number.parseInt(slot.start.substring(slot.start.indexOf('.')))
            const end = Number.parseInt(slot.end.substring(slot.end.indexOf('.')))

            slots.push(
                <li className="inbox-item" key={slot.uuid}>
                    <p className="inbox-item-author">{moment(slot.date, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</p>
                    <p className="inbox-item-text">
                        {moment(`${start}:00`, 'HH:mm').format('h:mm A')} - {moment(`${end}:00`, 'HH:mm').format('h:mm A')}
                    </p>
                    <p className="inbox-item-date">
                        <button
                            className="btn btn-small btn-info"
                            onClick={action(() => {
                                this.props.onEditSlot(slot)
                                this.showBookingAdd = true
                            })}
                        >
                            Edit
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-small btn-danger"
                            onClick={action(() => {
                                this.props.onDeleteSlot(slot)
                            })}
                        >
                            Delete
                        </button>
                        <button
                            className="btn btn-small btn-primary"
                            onClick={action(() => {
                                this.props.onToggleDisableSlot(slot)
                            })}
                        >
                            {slot.disabled ? 'Enable' : 'Disable'}
                        </button>
                    </p>
                </li>,
            )
        }

        const hours: { key: string; value: string }[] = []

        for (let i = 1; i <= 24; i++) {
            hours.push({
                key: `${i}:00`,
                value: moment(`${i}:00`, 'HH:mm').format('h:mm A'),
            })
        }

        return (
            <>
                <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '') + ' mb-0'}>
                    <div className="card-body pb-0">
                        <div>
                            <h4 className="header-title">Booking configuration</h4>
                            <p className="sub-header"> Configure booking for project </p>

                            <div className="row">
                                <div className="col-md-12">
                                    <Input id={'url'} type="text" value={`${this.props.releaseUrl}/${this.props.project.slug}`} disabled={true} className="form-control" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <h5>Slots</h5>

                                    <ul className="inbox-widget pl-0">{slots}</ul>
                                </div>
                            </div>

                            <div className="text-right">
                                <div className="btn-group mb-3">
                                    <button
                                        onClick={action(() => {
                                            //reset the slot
                                            this.props.onEditSlot({
                                                date: '',
                                                end: '',
                                                personsPerHour: 30,
                                                start: '',
                                                uuid: '',
                                                deleted: false,
                                            })
                                            this.showBookingAdd = true
                                        })}
                                        className="btn width-md waves-effect waves-light btn-primary ladda-button"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup onClose={this.onCloseBookingAdd} title={'Booking slot'} show={this.showBookingAdd}>
                    <div className="custom-modal-text">
                        <p>Enter slot information</p>

                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-password">Date</label>
                                    <div className="input-group mb-3">
                                        <Input updateTime={30} id={'endDate'} className="form-control" value={this.props.slot.date} onChange={action((value) => (this.props.slot.date = value))} type="date" />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.slot.date = moment().format('YYYY-MM-DD')))}>
                                                Today
                                            </button>
                                            <button className="btn btn-secondary waves-effect waves-light" type="button" onClick={action((e) => (this.props.slot.date = moment().add(1, 'day').format('YYYY-MM-DD')))}>
                                                Tomorrow
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-password">Start</label>
                                    <Select className="form-control" data={{ options: hours, key: 'key', value: 'value' }} id="start-hour" onChange={action((value) => (this.props.slot.start = value))} value={this.props.slot.start} default={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md">
                                <div className="form-group mb-3">
                                    <label htmlFor="user-password">End</label>
                                    <Select className="form-control" data={{ options: hours, key: 'key', value: 'value' }} id="start-hour" onChange={action((value) => (this.props.slot.end = value))} value={this.props.slot.end} default={true} />
                                </div>
                            </div>
                        </div>

                        <NumberInputComponent value={this.props.slot.personsPerHour} title="Persons per hour" onChange={action((value: number) => (this.props.slot.personsPerHour = value))} min={5} max={35} displayButtons={true} />

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseBookingAdd} className="btn width-md waves-effect waves-light btn-secondary">
                                    Discard
                                </button>
                                <button type="button" onClick={this.onSave} className="btn width-md waves-effect waves-light btn-success">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}
