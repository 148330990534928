import React from 'react'
import { observer } from 'mobx-react'
import { action } from 'mobx'
import { Language, Project, ProjectStatus as ProjectStatusEnmu } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import Select from '../../../components/ui-elements/Select'
import { TemplateManifest } from 'shared/template'

@observer
export default class ProjectStatus extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
}> {
    render() {
        return (
            <div className={'card ' + (this.props.isLoaded === false ? (this.props.isLoading === true ? 'data-loaded ' : 'data-waiting ') : '')}>
                <div className="card-body pb-0">
                    <div>
                        <h4 className="header-title">Status</h4>
                        <p className="sub-header">Project status will control if project is published on web, draft or hidden for actions that you only collect release forms.</p>
                        <div className="form-row">
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label htmlFor={'asdsadasdasd'}>Project status</label>
                                    <Select
                                        id={'asdsadasdasd'}
                                        className="custom-select"
                                        value={this.props.project.status}
                                        onChange={action((e) => (this.props.project.status = e))}
                                        data={{
                                            options: [
                                                {
                                                    key: '-1',
                                                    value: 'Select ...',
                                                },
                                                {
                                                    key: ProjectStatusEnmu.draft.toLowerCase(),
                                                    value: ProjectStatusEnmu.draft,
                                                },
                                                {
                                                    key: ProjectStatusEnmu.hidden.toLowerCase(),
                                                    value: ProjectStatusEnmu.hidden,
                                                },
                                                {
                                                    key: ProjectStatusEnmu.published.toLowerCase(),
                                                    value: ProjectStatusEnmu.published,
                                                },
                                            ],
                                            key: 'key',
                                            value: 'value',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
