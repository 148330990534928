import Api from "shared/adminApi";
import { EventEmitter } from "events";
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Admin } from "shared/models-web";
import socketio, { Socket } from "socket.io-client";
import config from "../config";

export default class Store extends EventEmitter {
    api: Api;
    io: Socket;

    @observable
    isInternet = false;
    @observable
    isLoggedin = false;
    @observable
    admin?: Admin;
    @observable
    socketStatus = {
        connected: false
    };
    @observable
    internal = {
        hardFault: false
    };
    @observable
    storage = {
        mounted: false
    };
    @observable
    cloud = {
        has_changes: false
    };

    config = config;

    constructor(url: string) {
        super();

        makeObservable(this);

        this.api = new Api(url);
        this.io = socketio.io(url, { transports: ['websocket'], });
    }

    initSockets = async () => {
        const token = window.localStorage.getItem('token');

        if (!token) {
            console.log("Invalid token");
            return;
        }

        this.io = socketio.io(config.url, {
            query: {
                Token: token
            },
            transports: ['websocket'],
        });

        this.io.on('reset', () => {
            window.location.reload();
        });

        this.io.on('internal', action((data: any) => {
            this.internal.hardFault = !data.hasMongo;
        }));

        this.io.on('git.update', action((data: any) => {
            this.cloud.has_changes = data.has_changes;
        }));

        this.io.on("sync.image", (data: any) => this.emit("sync.image", data));
        this.io.on("notifications.event", (data: any) => this.emit("notifications.event", data));
        this.io.on("backup.import-progress", (data: any) => this.emit("backup.import-progress", data));

        this.io.on('connect_error', () => {
            this.ioConnectionStatus(false);
        });

        this.io.on('disconnect', () => {
            this.ioConnectionStatus(false);
        });

        this.io.on('connect', () => {
            console.log('SocketIO connected!');

            this.ioConnectionStatus(true);
        });

        this.io.on("ssh.data", (data: any) => this.emit("ssh.data", data));
        this.io.on("ssh.close", (data: any) => this.emit("ssh.close", data));

        this.on('ssh.data', (data) => this.io.emit('ssh.data', data));
        this.on('ssh.resize', (data) => this.io.emit('ssh.resize', data));
        this.on('ssh.close', (data) => this.io.emit('ssh.close', data));
    }

    @action
    private ioConnectionStatus = (connected: boolean) => {
        this.socketStatus.connected = connected;
        console.log("ioConnectionStatus", connected);
    }

    @action
    checkLogin = async (token: string) => {
        const result = (await this.api.check(token)).data;

        runInAction(() => {
            if (result === false) {
                this.isLoggedin = false;
                this.admin = undefined;
                return;
            }

            this.io && this.io.once(token, action((data: any) => {
                this.api.updateToken(undefined);
                this.admin = undefined;
                this.isLoggedin = false;

                window.localStorage.removeItem("token");
                console.log("Loggingout");
            }));

            this.isLoggedin = true;
            this.admin = result;
        });
    }

    @action
    login = async (username: string, password: string) => {
        const result = (await this.api.login(username, password)).data;

        runInAction(() => {
            window.localStorage.setItem("token", result.token);

            this.io && this.io.once(result.token, action((data: any) => {
                this.api.updateToken(undefined);
                this.admin = undefined;
                this.isLoggedin = false;

                window.localStorage.removeItem("token");
                console.log("Loggingout");
            }));

            this.api.updateToken(result.token);
            this.admin = result.admin;

            this.isLoggedin = true;
        });

        await this.initSockets();
    }

    @action
    logout = async () => {
        await this.api.logout();

        runInAction(() => {
            this.api.updateToken(undefined);
            this.admin = undefined;
            this.isLoggedin = false;

            window.localStorage.removeItem("token");
        });
    }
}
