import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'

import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import { Project, User, UserMeta } from 'shared/models-web'
import Popup from '../../components/Popup'
import _ from 'lodash'
import { withRouter } from '../../utils/react-utils'
import { NavigateFunction, Params } from 'react-router-dom'
import UserTailsTable from '../../components/UserTailsTable'

@observer
class ProjectView extends React.Component<{
    api: Api
    store: Store
    history: History
    router: { location: Location; navigate: NavigateFunction; params: Params<string>; beforeUnload: (func: () => void) => void }
}> {
    @observable
    project?: Project
    @observable
    users: {
        user: User
        userMeta: UserMeta
    }[] = []
    @observable
    filter: string = 'all'
    @observable
    isLoading = false

    @observable
    pictureModal = false
    @observable
    pictureUrl: string = ''

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    confirmDeleteShow = false
    @observable
    userToDelete?: string

    @observable
    selected: {
        [id: string]:
            | {
                  user: User
                  userMeta: UserMeta
              }
            | undefined
    } = {}

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    @action
    init = async () => {
        await this.loadUsers()
        this.props.store.on('sync.image', this.newImage)
    }

    @action
    newImage = async () => {
        console.log('why we no here')
        await this.loadUsers()
    }

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter

        await this.loadUsers()
    }

    @action
    loadUsers = async () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        let hasMore = true
        this.users = []
        while (hasMore) {
            hasMore = await action(async () => {
                try {
                    if (!this.props.router.params.projectId) {
                        throw new Error("Can't load project")
                    }

                    const res = (await this.props.api.project_api().users(this.props.router.params.projectId, this.filter, this.users.length)).data

                    runInAction(() => {
                        this.project = res.project
                        this.users = this.users.concat(res.users)
                    })

                    return res.hasMore
                } catch (eRaw) {
                    const e = eRaw as any

                    runInAction(() => {
                        if (e.response && e.response.status === 403) {
                            this.props.store.isLoggedin = false
                        } else if (e.response && e.project_stats.data) {
                            console.log('here', e.response.data)
                            this.showErrorModal = true
                            this.errorModalText = e.response.data.errMsg
                        } else {
                            this.showErrorModal = true
                            this.errorModalText = `We got unspecified error: ${e}`
                        }
                    })
                }
            })()
        }

        this.isLoading = false
    }

    @action
    deleteUser = (userId: string) => {
        this.userToDelete = userId
        this.confirmDeleteShow = true
    }

    @action
    viewReleaseForm = (userId: string) => {
        window.open(`/images/${userId}-releaseform.pdf`)
    }

    @action
    openPictureModal = (url: string) => {
        this.pictureUrl = url
        this.pictureModal = true
    }

    @action
    closePictureModal = () => {
        this.pictureModal = false
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    confirmDelete = async () => {
        this.confirmDeleteShow = false
        if (!this.userToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        try {
            // delete user
            this.props.api.project_api().delete_user(this.userToDelete)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    resendEmail = async (userId: string) => {
        try {
            await this.props.api.users_api().resend_email(userId)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    toggleHidden = async (userId: string) => {
        try {
            if (!this.props.router.params.projectId) {
                throw new Error("Can't load project")
            }

            await this.props.api.users_api().toggle_hidden(this.props.router.params.projectId, userId)
            await this.loadUsers()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    setUsersImageHidden = async () => {
        try {
            if (!this.props.router.params.projectId) {
                throw new Error("Can't load project")
            }

            const userIds = []

            for (const i in this.selected) {
                const entry = this.selected[i]

                if (entry !== undefined) {
                    userIds.push(entry.user.uuid)
                }
            }

            await this.props.api.users_api().batch_set_hidden(this.props.router.params.projectId, userIds)
            await this.loadUsers()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    setUsersImageVisible = async () => {
        try {
            if (!this.props.router.params.projectId) {
                throw new Error("Can't load project")
            }

            const userIds = []

            for (const i in this.selected) {
                const entry = this.selected[i]

                if (entry !== undefined) {
                    userIds.push(entry.user.uuid)
                }
            }

            await this.props.api.users_api().batch_set_visible(this.props.router.params.projectId, userIds)
            await this.loadUsers()
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    openUser = (userId: string) => {
        this.props.router.navigate(`/users/${userId}`)
    }

    @action
    onCloseConfirmDeleteModal = () => {
        this.confirmDeleteShow = false
    }

    render() {
        if (!this.project) {
            return <></>
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">{'Photos of "' + this.project.name + '"'}</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col">
                        <div className="button-list mb-3">
                            <button type="button" onClick={this.setUsersImageHidden} className={'btn btn-secondary waves-effect waves-light'}>
                                Hide images
                            </button>
                            <button type="button" onClick={this.setUsersImageVisible} className={'btn btn-secondary waves-effect waves-light'}>
                                Show images
                            </button>
                            <div>{this.isLoading ? 'Loading ... ' : 'Ready'}</div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-lg-right mt-3 mt-lg-0">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={() => this.toggleFilter('all')} className={'btn waves-effect waves-light ' + (this.filter === 'all' ? 'btn-primary' : 'btn-secondary')}>
                                    All
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('synced')} className={'btn waves-effect waves-light ' + (this.filter === 'synced' ? 'btn-primary' : 'btn-secondary')}>
                                    Synced
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('not_synced')} className={'btn waves-effect waves-light ' + (this.filter === 'not_synced' ? 'btn-primary' : 'btn-secondary')}>
                                    Not synced
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('printed')} className={'btn waves-effect waves-light ' + (this.filter === 'printed' ? 'btn-primary' : 'btn-secondary')}>
                                    Printed
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('not_printed')} className={'btn waves-effect waves-light ' + (this.filter === 'not_printed' ? 'btn-primary' : 'btn-secondary')}>
                                    Not printed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <UserTailsTable
                    usersControls={{
                        deleteUser: this.deleteUser,
                        openUser: this.openUser,
                        resendEmail: this.resendEmail,
                        toggleHidden: this.toggleHidden,
                        viewReleaseForm: this.viewReleaseForm,
                        openModalPhoto: this.openPictureModal,
                        onChecked: action((e: any) => {
                            console.log(e)
                            if (e.target.checked) {
                                this.selected[e.target.id] = _.find(this.users, (entry) => entry.user.uuid === e.target.id)
                            } else {
                                this.selected[e.target.id] = undefined
                            }
                        }),
                        checked: (id) => this.selected[id] !== undefined,
                    }}
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'user.name',
                        },
                        {
                            Header: 'Email',
                            accessor: 'user.email',
                        },
                        {
                            Header: 'UUID',
                            accessor: 'user.uuid',
                        },
                    ]}
                    data={this.users}
                />

                <Popup onClose={this.closePictureModal} className={'modal-photo'} show={this.pictureModal} close={true}>
                    <img alt="preview" src={this.pictureUrl} />
                </Popup>
                <Popup onClose={this.onCloseConfirmDeleteModal} type="" title={'Delete project?'} show={this.confirmDeleteShow}>
                    <div className="custom-modal-text">
                        <p>You are about to delete user's profile and portraits!</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    No
                                </button>
                                <button type="button" onClick={this.confirmDelete} className="btn width-md waves-effect waves-light btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}

export default withRouter(ProjectView)
