"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ProjectApi = /** @class */ (function () {
    function ProjectApi(axios) {
        this.axios = axios;
    }
    ProjectApi.prototype.stats = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/stats")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.list_projects = function (filter) {
        if (filter === void 0) { filter = "all"; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/?filter=" + filter)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.project_template = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/template")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.website_options = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/website")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.cities_by_country = function (country, state) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/cities-by-country?country=" + country + "&state=" + state)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.geocoding = function (search) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/geocoding?search=" + search)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.open_project = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/projects/view/" + projectId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.trigger_webhook = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/projects/trigger/" + projectId + "/webhook/sync")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.add_project = function (data, files, highlights) {
        if (highlights === void 0) { highlights = []; }
        return __awaiter(this, void 0, void 0, function () {
            var formData, _i, files_1, file, _a, highlights_1, file;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("project", JSON.stringify(data));
                        for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                            file = files_1[_i];
                            formData.append("files[]", file);
                        }
                        for (_a = 0, highlights_1 = highlights; _a < highlights_1.length; _a++) {
                            file = highlights_1[_a];
                            formData.append("highlights[]", file);
                        }
                        return [4 /*yield*/, this.axios.post("/api/v1/projects/add", formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })];
                    case 1: return [2 /*return*/, (_b.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.edit_project = function (projectId, data, files, highlights) {
        if (highlights === void 0) { highlights = []; }
        return __awaiter(this, void 0, void 0, function () {
            var formData, _i, files_2, file, _a, highlights_2, file;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("project", JSON.stringify(data));
                        for (_i = 0, files_2 = files; _i < files_2.length; _i++) {
                            file = files_2[_i];
                            formData.append("files[]", file);
                        }
                        for (_a = 0, highlights_2 = highlights; _a < highlights_2.length; _a++) {
                            file = highlights_2[_a];
                            formData.append("highlights[]", file);
                        }
                        return [4 /*yield*/, this.axios.post("/api/v1/projects/edit/" + projectId, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })];
                    case 1: return [2 /*return*/, (_b.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.add_project_location = function (groupId, project, files) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, _i, files_3, file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("project", JSON.stringify(project));
                        for (_i = 0, files_3 = files; _i < files_3.length; _i++) {
                            file = files_3[_i];
                            formData.append("files[]", file);
                        }
                        return [4 /*yield*/, this.axios.post("/api/v1/projects/location/" + groupId + "/new", formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.edit_project_location = function (groupId, projectId, project, files) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, _i, files_4, file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("project", JSON.stringify(project));
                        for (_i = 0, files_4 = files; _i < files_4.length; _i++) {
                            file = files_4[_i];
                            formData.append("files[]", file);
                        }
                        return [4 /*yield*/, this.axios.post("/api/v1/projects/location/" + groupId + "/edit/" + projectId, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.delete_project = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/projects/delete/" + projectId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.toggle_archived = function (projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/projects/toggle_archived/" + projectId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.users = function (projectId, filter, from) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/projects/users/" + projectId, {
                            filter: filter,
                            from: from
                        })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.delete_user = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.delete("/api/v1/projects/users/" + userId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.languages = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/languages/")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    ProjectApi.prototype.templates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/templates/")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    return ProjectApi;
}());
exports.default = ProjectApi;
