import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'

import { action, makeObservable, observable, runInAction } from 'mobx'
import Popup from '../../components/Popup'
import { User, UserMeta } from 'shared/models-web'
import _ from 'lodash'
import { withRouter } from '../../utils/react-utils'
import { NavigateFunction, Params } from 'react-router-dom'
import Table from '../../components/Table'
import { Dropdown } from 'react-bootstrap'

@observer
class UserList extends React.Component<{ api: Api; store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    @observable
    isLoading = false
    @observable
    filter = 'active'

    @observable
    users: { user: User; userMeta: UserMeta }[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    confirmDeleteShow = false
    @observable
    userToDelete?: string

    @observable
    selectedAll: boolean = false
    @observable
    selected: {
        [id: string]:
            | {
                  user: User
                  userMeta: UserMeta
              }
            | undefined
    } = {}

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.init()
    }

    init = async () => {
        await this.loadUsers()
    }

    @action
    loadUsers = async () => {
        if (this.isLoading) {
            return
        }

        this.isLoading = true

        let hasMore = true
        this.users = []
        while (hasMore) {
            hasMore = await action(async () => {
                try {
                    const res = (await this.props.api.users_api().users(this.filter, this.users.length)).data
                    runInAction(() => {
                        this.users = this.users.concat(res.users)
                    })

                    return res.hasMore
                } catch (eRaw) {
                    const e = eRaw as any

                    runInAction(() => {
                        if (e.response && e.response.status === 403) {
                            this.props.store.isLoggedin = false
                        } else if (e.response && e.project_stats.data) {
                            console.log('here', e.response.data)
                            this.showErrorModal = true
                            this.errorModalText = e.response.data.errMsg
                        } else {
                            this.showErrorModal = true
                            this.errorModalText = `We got unspecified error: ${e}`
                        }
                    })
                }
            })()
        }

        runInAction(() => {
            this.isLoading = false
        })
    }

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter

        await this.loadUsers()
    }

    @action
    deleteUser = (userId: string) => {
        this.userToDelete = userId
        this.confirmDeleteShow = true
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    confirmDelete = async () => {
        this.confirmDeleteShow = false
        if (!this.userToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        try {
            // delete user
            this.props.api.project_api().delete_user(this.userToDelete)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }
    @action
    onCloseConfirmDeleteModal = () => {
        this.confirmDeleteShow = false
    }

    @action
    viewReleaseForm = (user: User) => {
        window.open(`/images/${user.uuid}-releaseform.pdf`)
    }

    @action
    resendEmail = async (userId: string) => {
        try {
            await this.props.api.users_api().resend_email(userId)
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    openUser = (userId: string) => {
        this.props.router.navigate(`/users/${userId}`)
    }

    render() {
        return (
            <div className="projects">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Users</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card-box">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div>{this.isLoading ? 'Loading ... ' : 'Ready'}</div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-lg-right mt-3 mt-lg-0">
                                        <div className="btn-group">
                                            <button type="button" onClick={() => this.toggleFilter('all')} className={'btn waves-effect waves-light ' + (this.filter === 'all' ? 'btn-primary' : 'btn-secondary')}>
                                                All
                                            </button>
                                            <button type="button" onClick={() => this.toggleFilter('active')} className={'btn waves-effect waves-light ' + (this.filter === 'active' ? 'btn-primary' : 'btn-secondary')}>
                                                Active
                                            </button>
                                            <button type="button" onClick={() => this.toggleFilter('syncing')} className={'btn waves-effect waves-light ' + (this.filter === 'syncing' ? 'btn-primary' : 'btn-secondary')}>
                                                Syncing
                                            </button>
                                            <button type="button" onClick={() => this.toggleFilter('inactive')} className={'btn waves-effect waves-light ' + (this.filter === 'inactive' ? 'btn-primary' : 'btn-secondary')}>
                                                Inactive
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Table
                    columns={[
                        {
                            Header: 'Name',
                            accessor: 'user.name',
                            disableSortBy: false,
                        },
                        {
                            Header: 'Email',
                            accessor: 'user.email',
                            disableSortBy: false,
                        },
                        {
                            Header: 'Photos',
                            disableSortBy: true,
                            actions: (row: any) => {
                                return (
                                    <p className="mb-0">
                                        <span className="text-nowrap d-inline-block">
                                            <i className="mdi mdi-camera text-muted"></i>
                                            <b>{row.original.userMeta.images}</b> Photos
                                        </span>
                                    </p>
                                )
                            },
                        },
                        {
                            Header: 'Emails',
                            disableSortBy: true,
                            actions: (row: any) => {
                                return (
                                    <p className="mb-0">
                                        <span className="text-nowrap d-inline-block">
                                            <i className="mdi mdi-email text-muted"></i>
                                            <b>{row.original.userMeta.emails}</b> Emails
                                        </span>
                                    </p>
                                )
                            },
                        },
                        {
                            Header: 'Actions',
                            accessor: 'user.id',
                            disableSortBy: false,
                            actions: (row: any) => {
                                return (
                                    <Dropdown className={'d-flex align-items-center'}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" as="a" className="card-drop">
                                            <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={'dropdown-menu dropdown-menu-right'}>
                                            <Dropdown.Item onClick={(e: any) => this.openUser(row.original.user.uuid)}>View user info</Dropdown.Item>
                                            <Dropdown.Item onClick={(e: any) => this.viewReleaseForm(row.original.user)}>View release form</Dropdown.Item>
                                            <Dropdown.Item onClick={(e: any) => this.resendEmail(row.original.user.uuid)}>Resend email</Dropdown.Item>
                                            <div className="dropdown-divider"></div>
                                            <Dropdown.Item onClick={(e: any) => this.deleteUser(row.original.user.uuid)}>Delete photo</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            },
                        },
                    ]}
                    data={this.users}
                />

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseConfirmDeleteModal} type="" title={'Delete project'} show={this.confirmDeleteShow}>
                    <div className="custom-modal-text">
                        <p>Deleting this project will delete all photos and participant information. Anything inside this project that was not yet synced will be permanently lost! Are you sure you want to delete this project?</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.confirmDelete} className="btn width-md waves-effect waves-light btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(UserList)
