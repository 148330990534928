import React from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';


@observer
export default class DropdownComponent extends React.Component<{
    title: any,
    data: { options: any[], key?: string, value?: string },
    value: any,
    displayPrepend?: any,
    displayAppend?: any,
    onChange: any,
    displayButtons?: boolean,
    disabled?: boolean
}> {

    @action
    selectNextOption = () => {
        this.props.data.options.forEach((option, index) => {
            if(typeof option === "string") {
                if (this.props.value === option) {
                    if (index === this.props.data.options.length - 1) {
                        this.props.onChange(this.props.data.options[0])
                    } else {
                        this.props.onChange(this.props.data.options[index + 1])
                    }
                }
            } else if(this.props.data.key && this.props.data.value) {
                if (this.props.value === option[this.props.data.key]) {
                    if (index === this.props.data.options.length - 1) {
                        this.props.onChange(this.props.data.options[0][this.props.data.key])
                    } else {
                        this.props.onChange(this.props.data.options[index + 1][this.props.data.key])
                    }
                }
            }
        })
    };
    @action
    selectPreviousOption = () => {
        this.props.data.options.forEach((option, index) => {
            if(typeof option === "string") {
                if (this.props.value === option) {
                    if (index === 0) {
                        this.props.onChange(this.props.data.options[this.props.data.options.length - 1])
                    } else {
                        this.props.onChange(this.props.data.options[index - 1])
                    }
                }
            } else if(this.props.data.key && this.props.data.value) {
                if (this.props.value === option[this.props.data.key]) {
                    if (index === 0) {
                        this.props.onChange(this.props.data.options[this.props.data.options.length - 1][this.props.data.key])
                    } else {
                        this.props.onChange(this.props.data.options[index - 1][this.props.data.key])
                    }
                }
            }
        })
    };

    render() {
        const options = [];
        let title = this.props.title;
        if (typeof this.props.title === 'string') {
            title = <label>{this.props.title}</label>
        }

        for (const option of this.props.data.options) {
            if(typeof option === "string") {
            options.push(<option key={option} value={option}>{this.props.displayPrepend}{option}{this.props.displayAppend}</option>);
            } else if(this.props.data.key && this.props.data.value) {
                options.push(<option key={option[this.props.data.key]} value={option[this.props.data.key]}>{this.props.displayPrepend}{option[this.props.data.value]}{this.props.displayAppend}</option>);
            }
        }

        return (
            <div>
                {title}
                <div className="input-group mb-3">
                    <select className="custom-select"
                            value={this.props.value}
                            onChange={(e) => (this.props.onChange(e.target.value))}
                            disabled={this.props.disabled}>
                        {options}
                    </select>
                    {this.props.displayButtons && <div className="input-group-append">
                        <button type="button"
                                onClick={this.selectPreviousOption}
                                className="btn width-xxs waves-effect waves-light btn-secondary no-af">-
                        </button>
                        <button type="button"
                                onClick={this.selectNextOption}
                                className="btn width-xxs waves-effect waves-light btn-secondary no-af">+
                        </button>
                    </div>}
                </div>
            </div>);
    }
}
