"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorApi = /** @class */ (function () {
    function EditorApi(axios) {
        this.axios = axios;
    }
    EditorApi.prototype.languages = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/editor/languages")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.languages_override = function (templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/editor/languages/" + templateId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.add_language = function (language) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/language/add", { language: language })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.update_translation = function (translation) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/translation/update", { translation: translation })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.add_language_override = function (languageId, templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/language/override/add", { languageId: languageId, templateId: templateId })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.update_translation_override = function (translation, templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/translation/override/update", { translation: translation, templateId: templateId })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.templates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/editor/templates")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template = function (templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/editor/template/" + templateId)];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.save_options = function (templateId, options) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId, { options: options })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.copy_template = function (templateId, name) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/copy", {
                            name: name
                        })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_files = function (templateId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get("/api/v1/editor/template/" + templateId + "/load_template_files")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_file = function (templateId, file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/load_template_file", { file: file })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_save_file = function (templateId, file, content) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/save_template_file", { file: file, content: content })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_new_file = function (templateId, folder, file, content) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/new_template_file", { file: file, content: content, folder: folder })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_delete_file = function (templateId, file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/delete_template_file", { file: file })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    EditorApi.prototype.template_add_file = function (templateId, folder, files) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, _i, files_1, file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append("folder", folder);
                        for (_i = 0, files_1 = files; _i < files_1.length; _i++) {
                            file = files_1[_i];
                            formData.append("files[]", file);
                        }
                        return [4 /*yield*/, this.axios.post("/api/v1/editor/template/" + templateId + "/add_template_file", formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    return EditorApi;
}());
exports.default = EditorApi;
