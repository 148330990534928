import React, { useEffect, useState } from "react";
import { FilterValue, Row, useAsyncDebounce } from "react-table";
import { Col, Form, InputGroup, Row as BSRow } from "react-bootstrap";
import { FilterOptions, TableOptions } from "../SelectableTable";

export default function GlobalFilter(props: { preGlobalFilteredRows: Array<Row<any>>; preFilteredRows: Array<Row<any>>; globalFilter: any; setGlobalFilter: (filterValue: FilterValue) => void; tableOptions: TableOptions; setFilter: any; filters?: { [filterName: string]: FilterOptions } }) {
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
        props.setGlobalFilter(value || undefined);
    }, 200);

    const [allFilters, setAllFilters] = useState(props.filters);

    useEffect(() => {
        // This will now use our custom filter for age
        for (const i in allFilters) {
            const filter = allFilters[i];

            props.setFilter(i, filter.valueRaw);
        }
    }, [allFilters]);

    const search = (
        <Col sm="8">
            <Form.Group as={BSRow} className="mb-3">
                <Form.Label column sm="2">
                    Search:
                </Form.Label>
                <Col sm="4">
                    <Form.Control
                        value={value || ""}
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} records...`}
                        className="form-control"
                    />
                </Col>
            </Form.Group>
        </Col>
    );

    const filters = [];
    let filter = <></>;

    if (props.filters) {
        for (const i in props.filters) {
            const f = props.filters[i];

            //implement multiple types

            filters.push(
                <>
                    <Form.Label column="sm">Filter {f.name}</Form.Label>
                    <Col md="8">
                        <InputGroup>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={allFilters && allFilters[i] && allFilters[i].valueRaw[0]}
                                onChange={(e) => {
                                    const newAllFilters = Object.assign({}, allFilters);

                                    newAllFilters[i].valueRaw[0] = e.target.value;

                                    setAllFilters(newAllFilters);
                                }}
                            />
                            <span className="input-group-btn" style={{ width: "0px" }}></span>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={allFilters && allFilters[i] && allFilters[i].valueRaw[1]}
                                onChange={(e) => {
                                    const newAllFilters = Object.assign({}, allFilters);

                                    newAllFilters[i].valueRaw[1] = e.target.value;

                                    setAllFilters(newAllFilters);
                                }}
                            />
                        </InputGroup>
                    </Col>
                </>
            );
        }

        filter = (
            <BSRow>
                <Col md="2">
                    <BSRow>{filters}</BSRow>
                </Col>
            </BSRow>
        );
    }

    return (
        <div className="row" style={{ margin: "0px" }}>
            {props.tableOptions.search && search}
            {props.tableOptions.filter && filter}
        </div>
    );
}
