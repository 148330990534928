import React from 'react'
import { observer } from 'mobx-react'
import Api from 'shared/adminApi'
import Store from '../../lib/store'
import { Link } from 'react-router-dom'
import { observable, action, makeObservable, runInAction } from 'mobx'
import { Project, ProjectStats } from 'shared/models-web'
import ProjectListEntry from '../../components/ProjectListEntry'

import Popup from '../../components/Popup'
import { withRouter } from '../../utils/react-utils'
import ImportPortraits from './components/ImportPortraits'

@observer
class ProjectsList extends React.Component<{ api: Api; store: Store; history: History }> {
    @observable
    isLoaded = false
    @observable
    filter = 'active'
    @observable
    projects: { project: Project; stats: ProjectStats }[] = []

    @observable
    showErrorModal = false
    @observable
    errorModalText: any

    @observable
    confirmDeleteShow = false
    @observable
    projectToDelete?: string

    @observable
    importUsersShow = false
    @observable
    importUsersProjectId?: string

    constructor(props: any) {
        super(props)

        makeObservable(this)

        this.loadProjects()
    }

    @action
    loadProjects = async () => {
        try {
            const projects = (await this.props.api.project_api().list_projects(this.filter)).data

            runInAction(() => {
                this.projects = projects
                this.isLoaded = true
            })
        } catch (eRaw) {
            const e = eRaw as any

            runInAction(() => {
                if (e.response && e.response.status === 403) {
                    this.props.store.isLoggedin = false
                } else if (e.response && e.project_stats.data) {
                    console.log('here', e.response.data)
                    this.showErrorModal = true
                    this.errorModalText = e.response.data.errMsg
                } else {
                    this.showErrorModal = true
                    this.errorModalText = `We got unspecified error: ${e}`
                }
            })
        }
    }

    @action
    toggleFilter = async (filter: string) => {
        this.filter = filter

        await this.loadProjects()
    }

    @action
    onCloseErrorModal = () => {
        this.showErrorModal = false
    }

    @action
    deleteProject = async (projectId: string) => {
        this.projectToDelete = projectId
        this.confirmDeleteShow = true
    }

    @action
    confirmDelete = async () => {
        this.confirmDeleteShow = false
        if (!this.projectToDelete) {
            this.showErrorModal = true
            this.errorModalText = `Unknown use of this function`
            return
        }

        await this.props.api.project_api().delete_project(this.projectToDelete)

        await this.loadProjects()
    }

    @action
    toggle_archived = async (projectId: string) => {
        await this.props.api.project_api().toggle_archived(projectId)

        await this.loadProjects()
    }

    @action
    onCloseConfirmDeleteModal = () => {
        this.confirmDeleteShow = false
    }

    @action
    importPortraits = (projectId: string) => {
        console.log(`Import portraits for project ${projectId}`)

        this.importUsersProjectId = projectId
        this.importUsersShow = true
    }

    render() {
        const projectList = []

        for (const p of this.projects) {
            projectList.push(<ProjectListEntry key={p.project.uuid} api={this.props.api} store={this.props.store} data={p} reload={() => this.loadProjects()} deleteProject={this.deleteProject} isLoaded={true} toggle_archived={this.toggle_archived} importPortraits={this.importPortraits} />)
        }

        return (
            <div className={'projects'}>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Projects</h4>
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-4">
                        <Link to="/projects/new" className="btn btn-success btn-rounded mb-3 waves-effect waves-light">
                            <i className="mdi mdi-plus"> </i>New Project
                        </Link>
                    </div>
                    <div className="col-sm-8">
                        <div className="text-sm-right">
                            <div className="btn-group mb-3">
                                <button type="button" onClick={() => this.toggleFilter('all')} className={'btn waves-effect waves-light ' + (this.filter === 'all' ? 'btn-primary' : 'btn-secondary')}>
                                    All
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('active')} className={'btn waves-effect waves-light ' + (this.filter === 'active' ? 'btn-primary' : 'btn-secondary')}>
                                    Active
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('syncing')} className={'btn waves-effect waves-light ' + (this.filter === 'syncing' ? 'btn-primary' : 'btn-secondary')}>
                                    Syncing
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('published')} className={'btn waves-effect waves-light ' + (this.filter === 'published' ? 'btn-primary' : 'btn-secondary')}>
                                    Published
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('hidden')} className={'btn waves-effect waves-light ' + (this.filter === 'hidden' ? 'btn-primary' : 'btn-secondary')}>
                                    Hidden
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('draft')} className={'btn waves-effect waves-light ' + (this.filter === 'draft' ? 'btn-primary' : 'btn-secondary')}>
                                    Draft
                                </button>
                                <button type="button" onClick={() => this.toggleFilter('archived')} className={'btn waves-effect waves-light ' + (this.filter === 'archived' ? 'btn-primary' : 'btn-secondary')}>
                                    Archived
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.projects.length > 0 && <div className="row">{projectList}</div>}

                {this.isLoaded && this.projects.length === 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="card-box project-box widget-inline">
                                <h4 className="mt-0">No projects :(</h4>
                                <div className="row mb-0">
                                    <div className="col-sm-12">
                                        <p className="mb-0">
                                            Currently there are no projects. When you're ready,
                                            <Link to={'/projects/new'} className="text-info">
                                                {' '}
                                                create a new one
                                            </Link>
                                            !
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Popup onClose={this.onCloseErrorModal} type="bg-danger" title={'Error'} show={this.showErrorModal}>
                    <div className="custom-modal-text">
                        <p>{this.errorModalText}</p>
                    </div>
                </Popup>

                <Popup onClose={this.onCloseConfirmDeleteModal} type="" title={'Delete project'} show={this.confirmDeleteShow}>
                    <div className="custom-modal-text">
                        <p>Deleting this project will delete all photos and participant information. Anything inside this project that was not yet synced will be permanently lost! Are you sure you want to delete this project?</p>

                        <div className="text-right mt-3">
                            <div className="btn-group">
                                <button type="button" onClick={this.onCloseConfirmDeleteModal} className="btn width-md waves-effect waves-light btn-secondary">
                                    Cancel
                                </button>
                                <button type="button" onClick={this.confirmDelete} className="btn width-md waves-effect waves-light btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup onClose={action(() => (this.importUsersShow = false))} type="" title={'Import portraits wizard'} show={this.importUsersShow} size="xl">
                    <div className="custom-modal-text">
                        <ImportPortraits api={this.props.api} store={this.props.store} onClose={action(() => (this.importUsersShow = false))} projectId={this.importUsersProjectId} />
                    </div>
                </Popup>
            </div>
        )
    }
}

export default withRouter(ProjectsList)
