import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Store from './lib/store'

import 'animate.css'
import './assets/scss/bootstrap.scss'
import './assets/scss/icons.scss'
import './assets/scss/app.scss'
import { toJS, action } from 'mobx'
import config from './config'
;(async () => {
    const store = new Store(config.url)

    try {
        const token = window.localStorage.getItem('token')
        await store.initSockets()

        if (token) {
            await store.api.updateToken(token)
            //don't wait for login information in sync so we can show error

            await store.checkLogin(token)
        }

        //allow access to some globals :D
        ;(window as any).io = store.io
        ;(window as any).api = store.api
        ;(window as any).store = store
        ;(window as any).toJS = toJS
        ;(window as any).action = action
    } catch (eRaw) {
        const e = eRaw as any

        console.error(e)
    }

    ReactDOM.createRoot(document.getElementById("root")!).render(
        <BrowserRouter basename={config.uri}>
            <App api={store.api} store={store} />
        </BrowserRouter>,
    );
})()
