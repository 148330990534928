import React from 'react'
import { observer } from 'mobx-react'
import { Language, Project } from 'shared/models-web'


import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'

import FileDrop from '../../../components/ui-elements/FileDrop'
//@ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react'
//@ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TemplateManifest, WebsiteColor } from 'shared/template'
import ProjectOptions from './ProjectOptions'

@observer
export default class ProjectPages extends React.Component<{
    project: Project
    languages: Language[]
    templates: TemplateManifest[]
    isLoading: boolean
    isLoaded: boolean
    isFormDirty: boolean
    isSaving: boolean
    drty: () => void
    error: (error: string) => void
    images: { id: string; url: string; filename: string }[]
    files: File[]
    removeExistingFile: (file: { id: string; url: string; filename: string }) => Promise<any>
    website_options: { colors: WebsiteColor[] }
}> {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Opening text</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className={'card-box project-box widget-inline'}>
                            <h3>Featured image</h3>
                            <FileDrop disable={this.props.isSaving} maxFiles={1} featured={true} showFiles={false} files={this.props.files} existing={this.props.images} removeExistingFile={this.props.removeExistingFile} />
                        </div>
                    </div>
                </div>

                <ProjectOptions drty={this.props.drty} error={this.props.error} isFormDirty={this.props.isFormDirty} isLoaded={this.props.isLoaded} isLoading={this.props.isLoading} isSaving={this.props.isSaving} languages={this.props.languages} templates={this.props.templates} project={this.props.project} files={this.props.files} images={this.props.images} removeExistingFile={this.props.removeExistingFile} website_options={this.props.website_options} />

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Main project text</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                link: {
                                    decorators: {
                                        openInNewTab: {
                                            mode: 'manual',
                                            label: 'Open in a new tab',
                                            attributes: {
                                                target: '_blank',
                                                rel: 'noopener noreferrer',
                                            },
                                        },
                                    },
                                },
                            }}
                            data={this.props.project.main && this.props.project.main.text.length > 0 ? this.props.project.main.text[0] : ''}
                            onReady={(editor: any) => {
                                console.log('Editor is ready to use!', editor)
                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData()
                                // console.log({ event, editor, data })

                                this.props.project.main && (this.props.project.main.text[0] = data)
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Short project description</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                link: {
                                    decorators: {
                                        openInNewTab: {
                                            mode: 'manual',
                                            label: 'Open in a new tab',
                                            attributes: {
                                                target: '_blank',
                                                rel: 'noopener noreferrer',
                                            },
                                        },
                                    },
                                },
                            }}
                            data={this.props.project.about && this.props.project.about.text.length > 0 ? this.props.project.about.text[0] : ''}
                            onReady={(editor: any) => {
                                console.log('Editor is ready to use!', editor)
                            }}
                            onChange={(event: any, editor: any) => {
                                const data = editor.getData()
                                // console.log({ event, editor, data })

                                this.props.project.about && (this.props.project.about.text[0] = data)
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
}
